import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, useMediaQuery } from "@material-ui/core";
import { DialogNotify, DialogTimeout } from "components";
import { Contents, Footer, Sidebar, TopBar } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    height: `calc(100vh - ${theme.topbar.height}px)`
  },
  main: { height: "100%" }
}));

const Main = props => {
  const { children, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });
  const [openSidebar, setOpenSidebar] = useState(isDesktop ? true : false);

  useEffect(() => {
    setOpenSidebar(isDesktop);
  }, [isDesktop]);

  return (
    <Box className={classes.root}>
      <TopBar
        open={openSidebar}
        onOpen={() => {
          setOpenSidebar(true);
        }}
        isDesktop={isDesktop}
        {...rest}
      />

      <Sidebar
        open={openSidebar}
        onClose={() => {
          setOpenSidebar(false);
        }}
        variant={isDesktop ? "persistent" : "temporary"}
        {...rest}
      />

      <main className={classes.main}>
        <Contents
          open={openSidebar}
          isDesktop={isDesktop}
          children={children}
        />
      </main>

      {false && <Footer open={openSidebar} isDesktop={isDesktop} />}
      {/* <CalleeDialog /> */}
      {/* <RequestPolling /> */}
      {false && <DialogTimeout />}
      {false && <DialogNotify />}
    </Box>
  );
};

export default Main;
