const CUSTOMER_STATUS_TYPES = {
  IN_USE: {
    value: "0",
    localeKey: "customer.status.inUse"
  },
  DISCONTINUED: {
    value: "1",
    localeKey: "customer.status.discontinued"
  },
  WITHDRAW: {
    value: "2",
    localeKey: "customer.status.withdraw"
  },
  WITH_DRAW_AFTER_ONE_MONTH: {
    value: "3",
    localeKey: "customer.status.withdrawAfterOneMonth"
  },
  ALL: {
    value: "9",
    localeKey: "customer.status.all"
  }
};

const WITHDRAW_TYPES = {
  NOW: {
    value: "0",
    localeKey: "customer.withdraw.now"
  },
  AFTER_ONE_MONTH: {
    value: "1",
    localeKey: "customer.withdraw.afterOneMonth"
  }
};

export { CUSTOMER_STATUS_TYPES, WITHDRAW_TYPES };
