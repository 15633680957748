/*eslint react-hooks/exhaustive-deps: 0*/

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "styles/locales/language";
import { DatePicker, Header } from "./component";
import { getWeek } from "./index";

const useStyle = makeStyles(
  theme => ({
    root: {
      // width: 620,
      // maxWidth: 620,
      // borderRadius: 8
      // boxSizing: "content-box"
    },
    dialogTitle: {
      padding: 0,
      // borderTopLeftRadius: 8,
      // borderTopRightRadius: 8
      overflow: "hidden",
      backgroundColor: "#0277bd"
    },
    dialogContent: {
      padding: 0
    },
    dialogActions: {
      backgroundColor: "#ffffff",
      minHeight: 60
    },
    paper: {
      borderRadius: 8,
      backgroundColor: "#0277bd",
      width: 310,
      maxWidth: 310,
      minWidth: 310
    },
    button: {
      width: 57,
      height: 36,
      borderRadius: 4,
      marginRight: 8
    },
    confirm: { color: theme.palette.primary.main },
    cancel: { color: "rgba( 255 255 255, 0.01)" },
    now: { color: theme.palette.secondary.main }
  }),
  { name: "CCFlattenDateTimePicker" }
);

const CCFlattenDatePicker = props => {
  const {
    open,
    value,
    components,
    onChange,
    onChanging,
    onClose,
    onChangeHeader,
    checkedList,
    mode,
    needWeek,
    preserveCurrentTime,
    ...others
  } = props;
  const classes = useStyle();
  const [last, setLast] = useState(value);
  const intl = useIntl();
  useEffect(() => {
    setLast(value);
  }, [value]);

  useEffect(() => {
    onChanging && onChanging(last);
  }, [last]);

  return (
    <Dialog
      open={open}
      className={clsx(classes.root)}
      PaperProps={{ className: clsx(classes.paper) }}
      {...others}
    >
      <DialogTitle
        className={clsx(classes.dialogTitle)}
        disableTypography={true}
      >
        <Header value={last} type={"date"} mode={mode} />
      </DialogTitle>
      <DialogContent className={clsx(classes.dialogContent)}>
        <Grid container>
          <Grid item>
            <DatePicker
              value={last}
              mode={mode}
              components={components}
              onChange={e => {
                setLast(e);
              }}
              onChangeHeader={onChangeHeader}
              checkedList={checkedList}
              preserveCurrentTime={preserveCurrentTime}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={clsx(classes.dialogActions)}>
        <Grid container justify={"space-between"} alignItems={"center"}>
          <Grid item>
            <Button
              className={clsx(classes.button)}
              onClick={() => {
                if (mode === "day") {
                  setLast(moment());
                  onChangeHeader && onChangeHeader(moment());
                } else {
                  setLast(getWeek(moment()));
                  onChangeHeader && onChangeHeader(moment());
                }
              }}
            >
              <Typography variant={"h5"} className={clsx(classes.now)}>
                <FormattedMessage id="ccCurrent" defaultMessage="현재" />
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={clsx(classes.button)}
              onClick={() => {
                setLast(value);
                onClose();
              }}
            >
              <Typography variant={"h5"} className={clsx(classes.cancel)}>
                <FormattedMessage id="ccCancel" defaultMessage="취소" />
              </Typography>
            </Button>
            <Button
              className={clsx(classes.button)}
              onClick={() => {
                if (needWeek) {
                  onChange({
                    ...last,
                    weekOfMonth:
                      last.begin.clone().week() -
                      last.begin.clone().startOf("month").week() +
                      1
                  });
                } else {
                  onChange(last);
                }
              }}
            >
              <Typography variant={"h5"} className={clsx(classes.confirm)}>
                {intl.formatMessage({ id: "ccCheck" })}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

CCFlattenDatePicker.propTypes = {
  /**
   * Open Dialog
   */
  open: PropTypes.bool.isRequired,
  /**
   * Picker Value, Only Moment Object
   */
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.shape({
      begin: PropTypes.instanceOf(moment),
      end: PropTypes.instanceOf(moment)
    })
  ]).isRequired,
  /**
   * Component Overlap
   */
  components: PropTypes.shape({
    DayItem: PropTypes.elementType
  }),
  /**
   * Callback when press confirm button
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Callback on changing
   */
  onChanging: PropTypes.func,
  /**
   * Callback when close dialog
   */
  onClose: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["day", "week"]),
  needWeek: PropTypes.bool,
  preserveCurrentTime: PropTypes.bool
};

CCFlattenDatePicker.defaultProps = {
  mode: "day",
  needWeek: false,
  preserveCurrentTime: false
};
export default CCFlattenDatePicker;
