import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid } from "@material-ui/core";
import { CCTable, CCTypography } from "styles/components";
import { GET_CONFIG_PRICING } from "queries/customer";

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 570,
    overflowY: "auto"
  },
  cell__price: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  cell__pros: {
    paddingLeft: 45,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center"
  },

  cell: {
    flex: 1,
    textAlignLast: "center",
    color: theme.palette.text.primary
  }
}));

const tableStyle = makeStyles(theme => ({
  root: {
    flex: 1
  },
  table__body: {
    backgroundColor: theme.palette.background.default,
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    },
    borderRight: "1px solid #b0bec5"
  },
  table__head__cell: {
    background: theme.palette.common.white
  }
}));

const ProsthesisPrice = props => {
  const { labId } = props;
  const classes = useStyles();
  const [arrayPros, setArrayPros] = useState([]);
  const heads = [
    {
      label: () => <CCTypography>Prosthesis</CCTypography>,
      key: "prosthesis",
      className: clsx({
        [classes.cell]: true
      }),

      component: ({ cellData, rowIndex }) => {
        return (
          <Box className={classes.cell__pros}>
            <CCTypography>{`${cellData}`}</CCTypography>
          </Box>
        );
      }
    },
    {
      label: () => <CCTypography>Price(VND)</CCTypography>,
      key: "price",
      className: clsx({
        [classes.cell]: true
      }),
      component: ({ cellData, rowIndex }) => {
        return (
          <Box className={classes.cell__price}>
            <CCTypography>{`${cellData}`}</CCTypography>
          </Box>
        );
      }
    }
  ];

  useQuery(GET_CONFIG_PRICING, {
    variables: {
      category: "prosthesisPriceSetting",
      labId
    },
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (!data.getConfig) return;
      const arrayTabsContent =
        data.getConfig.prosthesisPriceSetting[0]?.items[0].item.contents || [];

      const newArray = arrayTabsContent.reduce((acc, cur) => {
        acc = acc.concat([
          ...cur.tabContents.map(item => ({
            prosthesis: item.material + " " + cur.category,
            price: item.price
          }))
        ]);

        return acc;
      }, []);
      setArrayPros(newArray);
    }
  });

  const resultTableClasses = tableStyle();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={6}>
        <CCTable
          classes={resultTableClasses}
          heads={heads}
          contents={arrayPros.slice(0, arrayPros.length / 2 + 1)}
        />
      </Grid>
      <Grid item xs={6}>
        <CCTable
          classes={resultTableClasses}
          heads={heads}
          contents={arrayPros.slice(arrayPros.length / 2 + 1)}
        />
      </Grid>
    </Grid>
  );
};

export default ProsthesisPrice;
