import { BASE_REGISTRATION_STATUS } from "types/registrationManage";
import moment from "moment";

const validateSignUp = error => {
  if (error?.labId) {
    return "signUp.error.labId";
  }
  if (error?.userId) {
    return "signUp.error.userId";
  }
  if (error?.password) {
    return "signUp.error.password";
  }
  if (error?.confirmPassword) {
    return "signUp.error.confirmPassword";
  }
  if (error?.labName) {
    return "signUp.error.labName";
  }
  if (error?.tellPhoneNumber) {
    return "signUp.error.tellPhoneNumber";
  }
  if (error?.email) {
    return "signUp.error.email";
  }
  if (error?.address) {
    return "signUp.error.address";
  }
  if (error?.birthDate) {
    return "signUp.error.birthDate";
  }
  if (error?.managerName) {
    return "signUp.error.managerName";
  }
  if (error?.cellPhoneNumber) {
    return "signUp.error.cellPhoneNumber";
  }

  if (error?.brn) {
    return "signUp.error.brn";
  }

  return null;
};

const makeMenuItemList = (status, withdrawAt, onClickFunc) => {
  const { WAITING, APPROVED, REFUSED, WITHDRAW } = BASE_REGISTRATION_STATUS;

  console.log("REFUSED", REFUSED);
  let menuItemList = [];

  if (status === REFUSED) {
    menuItemList = [
      {
        value: WITHDRAW,
        color: "red",
        label: "Withdraw",
        onClick: onClickFunc
      }
    ];
  }

  if (status == WAITING) {
    menuItemList = [
      {
        value: APPROVED,
        color: "green",
        label: "Approved",
        onClick: onClickFunc
      },
      {
        value: REFUSED,
        label: "Refused",
        color: "red",
        onClick: onClickFunc
      }
    ];
  }

  if (status == APPROVED) {
    menuItemList = [
      {
        value: WITHDRAW,
        color: "red",
        label: "Withdraw",
        onClick: onClickFunc
      }
    ];
  }

  if (status == WITHDRAW && isExpiredWithdrawTime(withdrawAt)) {
    menuItemList = [
      {
        value: APPROVED,
        color: "gray",
        label: "Cancel Withdraw",
        onClick: onClickFunc
      }
    ];
  }

  return menuItemList;
};

const isExpiredWithdrawTime = withdrawAt => {
  // return true;
  const thirtyToSecond = 30 * 24 * 60 * 60;
  return moment().unix() - withdrawAt <= thirtyToSecond;
};
export { validateSignUp, makeMenuItemList, isExpiredWithdrawTime };
