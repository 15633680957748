import React, { useReducer, useState } from "react";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid } from "@material-ui/core";
import {
  CCButton,
  CCDialogContent,
  CCDialogTitle,
  CCTypography,
  CCDialogSimpleDel,
  CCIconButton,
  CCTooltip,
  CCDialogInputError,
  CCDateField,
  CCTextField
} from "styles/components";
import { AddIcon } from "styles/icons";
import { CVTable, TimerWithRefetch, DialogWarningConfirm } from "components";
import { AddNotificationModal, NotificationFilter } from "./components";
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  LIMIT_PAGINATION
} from "types/constants";
import {
  GET_NOTICES,
  CREATE_NOTICE,
  UPDATE_NOTICE,
  DELETE_NOTICE,
  CHANGE_PUBLISHED_STATE
} from "queries/notification";
import { enqueueToast } from "components/Toast";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { AnnounceOnIcon, AnnounceOffIcon } from "styles/icons";
import { Pagination } from "@material-ui/lab";

const LIMIT = LIMIT_PAGINATION;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white
  },
  timer: {
    position: "absolute",
    zIndex: 1200,
    top: 18,
    right: 32,
    display: "flex"
  },
  timer__divider: {
    height: 36,
    borderLeft: "3px solid rgba(0, 0, 0, 0.16)",
    margin: "0 16px"
  },
  paper: { height: "100%", width: "100%" },
  content: {
    height: "calc(100% -30px)",
    overflow: "hidden"
  },
  container: { height: "100%" },
  item: { width: "100%" },
  container__filter: {
    width: "100%",
    padding: "4px 12px",
    margin: "0px",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  input: { backgroundColor: theme.palette.common.white },
  title__field: { width: 395, margin: 0 },
  item__table: {
    height: "calc(100% - 62px)"
  },
  table: {
    height: "100%",
    textAlign: "center"
  },
  table__body: {
    backgroundColor: "rgba(207, 216, 220, 0.87)",
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    },
    textAlign: "center"
  },
  table__cell: {
    // padding: "6px 4px"
    // maxHeight: "20"
  },
  link: {
    display: "inline-block",
    textDecorationColor: theme.palette.common.black,

    "& p": {
      width: "500px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  toolbar__divider: {
    borderLeft: `1px solid ${theme.palette.border.main}`,
    height: 36,
    marginRight: 8
  },
  ml8: {
    marginLeft: 8
  },
  announce__on: {
    color: "rgb(1, 87, 155)",
    cursor: "pointer"
  },
  announce__off: {
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer"
  }
}));

const initializer = {
  filter: {
    // start: moment().startOf("month").unix(),
    // end: moment().endOf("month").unix(),
    limit: LIMIT,
    page: 1,
    searchText: ""
  },
  addNoticeModalVisible: false,
  deleteDialogVisible: false,
  selectedNotice: null,
  maximumPublishedWarningVisible: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "filter":
      return {
        ...state,
        filter: action.target
      };
    case "setAddNoticeModalVisible":
      return {
        ...state,
        addNoticeModalVisible: action.target
      };
    case "setDeleteDialogVisible":
      return {
        ...state,
        deleteDialogVisible: action.target
      };
    case "setSelectedNotice":
      const selectedNotice = action.target
        ? {
          ...action.target
          // date: moment.unix(action.target.date).format("YYYY/MM/DD")
        }
        : action.target;

      return {
        ...state,
        selectedNotice
      };
    case "setMaximumPublishedVisible":
      return {
        ...state,
        maximumPublishedWarningVisible: action.target
      };
    case "reset":
      return { ...initializer };
    default:
      break;
  }

  return state;
};

const NotificationManagement = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const [state, dispatchState] = useReducer(reducer, initializer);
  const [errorDialog, setErrorDialog] = useState(false);

  const {
    maximumPublishedWarningVisible,
    filter,
    addNoticeModalVisible,
    deleteDialogVisible,
    selectedNotice
  } = state;

  const { data, loading, error, refetch } = useQuery(GET_NOTICES, {
    variables: {
      input: {
        limit: LIMIT
      }
    }
  });

  const totalPages = Math.ceil(data?.notices?.total / LIMIT);

  const [createNotice] = useMutation(CREATE_NOTICE, {
    onCompleted: () => refetch()
  });

  const [updateNotice] = useMutation(UPDATE_NOTICE, {
    onCompleted: () => {
      console.log("updateNotice updateNotice", "success");
      refetch();
    }
  });

  const [deleteNotice] = useMutation(DELETE_NOTICE, {
    onCompleted: () => refetch()
  });

  const [changePublishedState] = useMutation(CHANGE_PUBLISHED_STATE, {
    onCompleted: () => refetch()
  });

  const changeAnnouncement = ({ id, isPublished }) => {
    changePublishedState({
      variables: {
        id,
        isPublished: !isPublished
      }
    }).then(rs => {
      const code = rs.data?.changePublishedState.code;

      if (code === "NOTICE02") {
        openMaximumPublishedWarning();
      }
      console.log("resolve", rs);
    });
  };

  const handleOnChangePagination = (event, value) => {
    console.log("handleOnChangePagination.value", value);

    dispatchState({
      type: "filter",
      target: { ...filter, page: value }
    });

    refetch({
      input: {
        limit: LIMIT,
        page: value
      }
    });
  };

  const heads = [
    {
      key: "date",
      width: "25%",
      label: "Date",
      className: classes.table__cell,
      component: ({ cellData }) => {
        return (
          <CCTypography variant="body1">
            {moment.unix(cellData).format(DEFAULT_DATE_FORMAT)}
          </CCTypography>
        );
      }
    },
    {
      key: "title",
      label: "Title",
      className: classes.table__cell,
      width: "15%"
    },
    {
      key: "link",
      label: "Link",
      width: "50%",
      className: classes.table__cell,
      component: props => {
        const { cellData } = props;
        return (
          <a
            href={cellData}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <CCTypography variant="body1">{cellData}</CCTypography>
          </a>
        );
      }
    },
    {
      key: "isPublished",
      label: "Publish",
      className: classes.table__cell,
      component: ({ rowData }) => {
        const { id, isPublished } = rowData;

        return (
          <CCTooltip
            title={
              <CCTypography color="inherit" variant="body1">
                {isPublished
                  ? "Published: The notice will be shown at the login page."
                  : "Unpublished: The notice will not be shown at the login page."}
              </CCTypography>
            }
          >
            {/* <CCIconButton
              color="icon"
              onClick={() => changeAnnouncement({id, isPublished})}
            > */}
            <span onClick={() => changeAnnouncement({ id, isPublished })}>
              {isPublished ? (
                <AnnounceOnIcon className={classes.announce__on} />
              ) : (
                <AnnounceOffIcon className={classes.announce__off} />
              )}
            </span>
            {/* </CCIconButton> */}
          </CCTooltip>
        );
      }
    }
  ];

  const handleOnSearch = value => {
    dispatchState({
      type: "filter",
      target: value
    });
    refetch({
      input: {
        limit: LIMIT,
        searchText: value.searchText,
        start: value.start,
        end: value.end
      }
    });
  };

  const handleOnDelete = () => {
    deleteNotice({
      variables: {
        id: selectedNotice.id
      }
    });

    enqueueToast(
      enqueueSnackbar,
      "Notice is deleted"
      // intl.formatMessage({ id: "common.ui.saved" })
    );

    dispatchState({
      type: "setDeleteDialogVisible",
      target: false
    });
    dispatchState({
      type: "setSelectedNotice",
      target: null
    });
  };

  const handleOnSubmit = async value => {
    const { id, date, link, title } = value;

    console.log("handleOnSubmit.value", value);
    console.log("date", typeof date);
    let unixDate = date;

    if (typeof date === "number") {
      unixDate = date;
    } else {
      unixDate = moment(date, "YYYY/MM/DD").unix();
    }

    console.log("unixDate", unixDate);

    if (!link || !title) {
      setErrorDialog(true);
      return;
    }

    // const isFormValid = validateForm();
    // const hasChanged = verifyChanges();

    // if (!isFormValid) return;

    if (!id) {
      createNotice({
        variables: {
          input: {
            title,
            date: unixDate,
            link
          }
        }
      });
      enqueueToast(
        enqueueSnackbar,
        "Notice is saved"
        // intl.formatMessage({ id: "common.ui.saved" })
      );
    } else {
      const updatedNoticeResult = await updateNotice({
        variables: {
          input: {
            id,
            title,
            date: unixDate,
            link
          }
        }
      });

      enqueueToast(
        enqueueSnackbar,
        "Notice is edited.​"
        // intl.formatMessage({ id: "common.ui.saved" })
      );
      dispatchState({
        type: "reset"
      });
    }
    dispatchState({ type: "setAddNoticeModalVisible", target: false });
  };

  const refetchData = () => {
    dispatchState({
      type: "reset"
    });
    refetch({
      input: {
        limit: LIMIT
      }
    });
  };

  const handleAgreeConfirmPublishedWarning = () => {
    dispatchState({
      type: "setMaximumPublishedVisible",
      target: false
    });
  };

  const openMaximumPublishedWarning = () => {
    dispatchState({
      type: "setMaximumPublishedVisible",
      target: true
    });
  };

  if (error) return;

  // if (loading) return <div>Loading...</div>;

  return (
    <>
      <Box className={classes.timer}>
        <TimerWithRefetch refetch={refetchData} />
        <div className={classes.timer__divider}></div>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <CCDialogTitle>Notice Management</CCDialogTitle>

          <CCDialogContent className={classes.content} noPadding>
            <Grid className={classes.container} container direction="column">
              <Grid className={classes.item} item>
                <Grid
                  className={classes.container__filter}
                  container
                  justify="space-between"
                >
                  <Grid item>
                    <Grid container justify="flex-end" alignItems="center">
                      <Grid item>
                        <div className={classes.toolbar__divider}></div>
                      </Grid>
                      <NotificationFilter
                        filter={filter}
                        onSearch={handleOnSearch}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <CCButton
                      variant="contained"
                      color="normal"
                      startIcon={<AddIcon />}
                      onClick={() =>
                        dispatchState({
                          type: "setAddNoticeModalVisible",
                          target: true
                        })
                      }
                    >
                      Add
                    </CCButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.item__table} item>
                <CVTable
                  heads={heads}
                  contents={data?.notices?.items}
                  className={classes.table}
                  classes={{ table__body: classes.table__body }}
                  onDelClick={e => {
                    dispatchState({
                      type: "setSelectedNotice",
                      target: e.rowData
                    });
                    dispatchState({
                      type: "setDeleteDialogVisible",
                      target: true
                    });
                  }}
                  onModClick={e => {
                    dispatchState({
                      type: "setSelectedNotice",
                      target: e.rowData
                    });
                    dispatchState({
                      type: "setAddNoticeModalVisible",
                      target: true
                    });
                  }}
                />

                <Box mt={1} />

                <Grid container item justifyContent="center" direction="column">
                  <Pagination
                    count={totalPages}
                    page={filter.page}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                    onChange={handleOnChangePagination}
                    showFirstButton
                    showLastButton
                  />
                </Grid>
              </Grid>
            </Grid>
          </CCDialogContent>
        </Box>

        {addNoticeModalVisible && (
          <AddNotificationModal
            open={addNoticeModalVisible}
            selectedNotice={selectedNotice}
            onClose={() => {
              dispatchState({
                type: "setAddNoticeModalVisible",
                target: false
              });
              dispatchState({
                type: "setSelectedNotice",
                target: null
              });
            }}
            onSave={handleOnSubmit}
          />
        )}

        {errorDialog && (
          <CCDialogInputError
            open={errorDialog}
            // title={intl.formatMessage({
            //   id: "settings.employee.invalidInput"
            // })}
            title="The input value is not valid."
            onClose={() => setErrorDialog(false)}
          />
        )}

        {deleteDialogVisible && (
          <CCDialogSimpleDel
            open={deleteDialogVisible}
            onClose={() =>
              dispatchState({
                type: "setDeleteDialogVisible",
                target: false
              })
            }
            onDisagree={() =>
              dispatchState({
                type: "setDeleteDialogVisible",
                target: false
              })
            }
            onAgree={handleOnDelete}
          />
        )}

        <DialogWarningConfirm
          open={maximumPublishedWarningVisible}
          onAgree={handleAgreeConfirmPublishedWarning}
          onClose={handleAgreeConfirmPublishedWarning}
          agreeButtonLabel={"Confirm"}
          // agreeButtonColor={"error"}
          // disagreeButtonLabel={""}
          title={"Maximum 10 notices could be published."}
        // subTitle={"Withdrawn data could not be recovered"}
        />
      </Box>
    </>
  );
};

export default NotificationManagement;
