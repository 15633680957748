import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { getWeek } from "../../../../../../index";

const size = 25;
const useDayItemStyle = makeStyles(
  theme => ({
    root: {
      position: "relative",
      width: 36,
      height: 36,
      backgroundColor: "transparent",
      borderRadius: "50%",
      userSelect: "none",
      cursor: "pointer",
      "& p": {
        color: theme.palette.common.black
      },
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        "& p": {
          color: theme.palette.common.white
        }
      }
    },
    selected: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      "& p": {
        color: theme.palette.common.white
      },
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        "& p": {
          color: theme.palette.common.white
        }
      }
    },
    checked: {
      position: "absolute",
      height: size,
      width: size,
      // backgroundColor: theme.palette.secondary.main,
      backgroundColor: "red",
      opacity: 0.2,
      borderRadius: 25
    },
    begin: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    between: {
      borderRadius: 0
    },
    end: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    },
    hidden: { display: "none" }
  }),
  { name: "CCFlattenDateTimePicker-DatePicker-Calendar-DayItem" }
);

const DayItem = props => {
  const {
    value,
    selected,
    onChange,
    checkedList = [],
    mode,
    preserveCurrentTime = false
  } = props;
  const classes = useDayItemStyle();
  // console.log(selected.begin.isSame(selected.end, "day"));
  return (
    <Grid
      item
      container
      className={clsx(classes.root, {
        [classes.selected]:
          mode === "day"
            ? value.isSame(selected, "year") &&
              value.isSame(selected, "month") &&
              value.isSame(selected, "day")
            : value.isBetween(selected.begin, selected.end, null, "[]"),
        [classes.begin]:
          mode !== "day" &&
          !selected.begin.isSame(selected.end, "day") &&
          value.isSame(selected.begin, "year") &&
          value.isSame(selected.begin, "month") &&
          value.isSame(selected.begin, "day"),
        [classes.end]:
          mode !== "day" &&
          !selected.begin.isSame(selected.end, "day") &&
          value.isSame(selected.end, "year") &&
          value.isSame(selected.end, "month") &&
          value.isSame(selected.end, "day"),
        [classes.between]:
          mode !== "day" &&
          !selected.begin.isSame(selected.end, "day") &&
          value.isBetween(selected.begin, selected.end, "day", "()")
      })}
      justify={"center"}
      alignItems={"center"}
      onClick={event => {
        if (mode === "day") {
          if (preserveCurrentTime) {
            onChange &&
              onChange(
                selected
                  .clone()
                  .dayOfYear(Number(value.format("DDD")))
                  .hours(moment().hours())
                  .minutes(moment().minutes())
                  .seconds(moment().seconds())
              );
          } else {
            onChange &&
              onChange(selected.clone().dayOfYear(Number(value.format("DDD"))));
          }
        } else {
          let _moveDay = selected.begin
            .clone()
            .dayOfYear(Number(value.format("DDD")));
          onChange && onChange(getWeek(_moveDay));
        }
      }}
    >
      <Typography variant={"body1"}>{value.format("D")}</Typography>
      <div
        className={clsx({
          [classes.checked]: true,
          [classes.hidden]: !checkedList[value.format("D")]
        })}
      />
    </Grid>
  );
};
DayItem.propTypes = {
  value: PropTypes.instanceOf(moment).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.shape({
      begin: PropTypes.instanceOf(moment),
      end: PropTypes.instanceOf(moment)
    })
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["day", "week"]),
  preserveCurrentTime: PropTypes.bool
};

export default DayItem;
