const Action = {
  GET: "get",
  PUT: "put",
  DELETE: "delete"
};

const Target = {
  PROFILE: "profile",
  STAMP: "stamp",
  PHOTO: "photo",
  LOGO: "logo",
  DAEMON: "daemon",
  LOGS: "logs"
};

const requestAction = async (action, target, id, name, expires) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const body = expires
      ? JSON.stringify({ action, target, id, name, type: null, expires })
      : JSON.stringify({ action, target, id, name });

    xhr.open("POST", process.env.REACT_APP_CF_UPLOAD_URL, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(body);

    xhr.onload = function() {
      const statusCode = JSON.parse(this.responseText).statusCode;

      if (statusCode === 200) {
        const body = JSON.parse(this.responseText).body;
        const url = JSON.parse(body).data;
        return resolve(url);
      }

      reject(this.responseText);
    };
  });
};

const getPresignedPostData = request => {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    const body = JSON.stringify({
      ...request
    });

    xhr.open("POST", process.env.REACT_APP_CF_UPLOAD_URL, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(body);
    xhr.onload = function() {
      resolve(JSON.parse(this.responseText));
    };
  });
};

const getObject = async (target, id, name, expires) =>
  requestAction(Action.GET, target, id, name, expires);

const deleteObject = async (target, id, name) =>
  requestAction(Action.DELETE, target, id, name);

const putObject = async (target, id, name, selectedFile) => {
  return new Promise(async (resolve, reject) => {
    const presignedPostData = await getPresignedPostData({
      action: Action.PUT,
      target,
      id,
      name,
      type: selectedFile.type
    });
    const url = JSON.parse(presignedPostData.body).data;

    const { file } = selectedFile.src;

    fetch(url, {
      method: "PUT",
      body: file
    })
      .then(() => {
        resolve();
      })
      .catch(err => {
        if (err) reject(err);

        resolve();
      });
  });
};

const putObjectBlob = async (target, id, name, blob, type = "image/png") => {
  return new Promise(async (resolve, reject) => {
    const presignedPostData = await getPresignedPostData({
      action: Action.PUT,
      target,
      id,
      name,
      type: type
    });
    const url = JSON.parse(presignedPostData.body).data;

    fetch(url, {
      method: "PUT",
      body: blob
    })
      .then(() => {
        resolve();
      })
      .catch(err => {
        if (err) reject(err);

        resolve();
      });
  });
};

export default {
  requestAction,
  getPresignedPostData,
  getObject,
  putObject,
  putObjectBlob,
  deleteObject,
  Target
};
