import React, {useReducer, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, Paper, Divider } from "@material-ui/core";
import { RouteContext } from "context";
import { CCDialogContent, CCDialogTitle } from "styles/components";
import { GET_SMTP_SETTING } from "queries/setting";
import { StatisticBox } from "../ApplicationManagement/components";
import { useHistory } from "react-router";
import {
  DialogWarningConfirm,
} from "components";
import {
  GET_LAB_APPLICATION_REQ_STATS
  // GET_LAB_APPLICATION_REQ_LIST,
  // UPDATE_LAB_APPLICATION_REQ
} from "queries/application";

import { GET_LAB_STATS } from "queries/customer";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100%)",
    width: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.common.white
  },
  timer: {
    position: "absolute",
    zIndex: 1200,
    top: 18,
    right: 32,
    display: "flex"
  },
  timer__divider: {
    height: 36,
    borderLeft: "3px solid rgba(0, 0, 0, 0.16)",
    margin: "0 16px"
  },
  paper: {
    height: "100%",
    cursor: "pointer"
  },
  paper_statistic_box: {
    height: "100%",
    width: 1250,
    cursor: "pointer"
  },
  content: {
    height: "calc(100%)",
    overflow: "hidden",
    backgroundColor: "#d5dde1"
  },
  container: { height: "100%" },
  padding16: {
    padding: "16px"
  },
  dividerHeight: {
    height: "1px",
    backgroundColor: '#b0bec5'
  }
}));

const initializer = {
  isOpenSetSmtpSetting: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "filter":
      return {
        ...state,
        filter: action.target
      };
    case "openSetSmtpSettingState": {
      return {
        ...state,
        isOpenSetSmtpSetting: action.target
      };
    }
    case "reset":
      return { ...initializer };
    default:
      break;
  }

  return state;
};

const DashBoard = () => {
  const classes = useStyles();
  const history = useHistory();

  const [state, dispatchState] = useReducer(reducer, initializer);
  const { isOpenSetSmtpSetting } = state;

  const { dispatchRoute } = useContext(RouteContext);

  const openSetSmtpSettingDialog = () => {
    dispatchState({
      type: "openSetSmtpSettingState",
      target: true
    });
  };

  const closeSetSmtpSettingDialog = () => {
    dispatchState({
      type: "openSetSmtpSettingState",
      target: false
    });
  };

  const { data: smtpSetting11} = useQuery(GET_SMTP_SETTING, {
    variables: {
      labId: "admin",
      isAdminConfig: true,
      category: "adminSmtpSetting"
      // labId: localStorage.getItem("labId")
    },
    fetchPolicy: "no-cache",
    onCompleted: smtp => {
      const isJustLogin = localStorage.getItem("justLoginCheck");

      // console.log("smtp", smtp);
      if(smtp && smtp.getConfig) {
        const { getConfig : { smtpSetting }} = smtp;
  
        const isValid = isSmtpSettingsFilled(smtpSetting);
        // console.log("isValid", Boolean(isValid));
        // console.log("isJustLogin", isJustLogin);
  
        // Check if not exist smtp Setting and user just login
        if(! isValid && isJustLogin === "true") {
          openSetSmtpSettingDialog();
          localStorage.setItem("justLoginCheck", false);
          window.variables = { category: "settings.ui.smtp", openSmtpSettingDialog: true };
        }
      } else {
        if(isJustLogin === "true") {
          openSetSmtpSettingDialog();
          localStorage.setItem("justLoginCheck", false);
          window.variables = { category: "settings.ui.smtp", openSmtpSettingDialog: true };
        }
      }
    },
    onError: (err) => {
      console.log("DashBoard.smtpSetting.err", err);
    }
  });

  const {
    data: dataStatistics
    // refetch: refetchStatistics
  } = useQuery(GET_LAB_APPLICATION_REQ_STATS);

  const { 
    data: userStatistics, 
    // refetch: refetchUserStatistics 
  } = useQuery(GET_LAB_STATS);

  /**
   * Check smtp setting all information was filled
   * @param {Object} smtpSetting 
   * @returns {Boolean}
   */
  const isSmtpSettingsFilled = (smtpSetting = {}) => {
    let isValid = true;
    const { password, port, sender, smtpUrl, ssl } = smtpSetting;

    console.log(password, port, sender, smtpUrl);
    if(! password ||! port || ! sender || ! smtpUrl) {
      isValid = false;
    }

    return isValid;
  }

  

  // const {
  //   data,
  //   loading,
  //   error,
  //   refetch: refetchApplicationReqList
  // } = useQuery(GET_LAB_APPLICATION_REQ_LIST, {
  //   variables: {
  //     input: {
  //       limit: LIMIT
  //     }
  //   }
  // });

  const registrationStatistics = [
    {
      title: "Total",
      color: "black",
      data:
        dataStatistics && dataStatistics.labApplicationRequestStatistics.total
    },
    {
      title: "Waiting",
      color: "blue",
      data:
        dataStatistics &&
        dataStatistics.labApplicationRequestStatistics.totalNewApplication
    },
    {
      title: "Approved",
      color: "green",
      data:
        dataStatistics &&
        dataStatistics.labApplicationRequestStatistics.totalAcceptedApplication
    },
    {
      title: "Refused",
      color: "red",
      data:
        dataStatistics &&
        dataStatistics.labApplicationRequestStatistics.totalRefusedApplication
    },
    // {
    //   title: "Termination",
    //   color: "gray",
    //   data:
    //     (dataStatistics &&
    //       dataStatistics.labApplicationRequestStatistics.totalWithDrawApplication) ||
    //     0
    // }
    {
      title: "Processing Termination",
      color: "gray",
      data:
        (dataStatistics &&
          dataStatistics.labApplicationRequestStatistics
            .totalProcessingTerminationApplication) ||
        0
    },
    {
      title: "Termination Complete",
      color: "gray",
      data:
        (dataStatistics &&
          dataStatistics.labApplicationRequestStatistics
            .totalCompleteTerminationApplication) ||
        0
    }
  ];

  // labStatistics
  //   totalApproval: 39
  // totalDiscontinued: 3
  // totalInuse: 36
  // totalWithdraw: 0
  // totalWithdrawAfterOneMonth: 0
  const userStatisticsData = [
    {
      title: "Total",
      color: "black",
      data: userStatistics && userStatistics.labStatistics.totalApproval
    },
    {
      title: "In Use",
      color: "green",
      data: userStatistics && userStatistics.labStatistics.totalInuse
    },
    {
      title: "Temporary Suspension",
      color: "red",
      data: userStatistics && userStatistics.labStatistics.totalDiscontinued,
      width: '350'
    },
    // {
    //   title: "Withdrawn",
    //   color: "gray",
    //   data:
    //     (userStatistics &&
    //       userStatistics.labStatistics.totalWithdraw +
    //         userStatistics.labStatistics.totalWithdrawAfterOneMonth) ||
    //     0
    // }
  ];

  // if (error) return;

  // if (loading) return <div></div>;

  const navigateRegistrationPage = () => {
    dispatchRoute({ path: "/registrationManagement" });
  };

  const navigateUserManagePage = () => {
    dispatchRoute({ path: "/userManagement" });
  };

  const navigateSmtpPage = () => {
    history.push("/settings");
    // dispatchRoute({ path: "/settings" });
  };

  return (
    <>
      <Box className={classes.timer}>
        {/* <TimerWithRefetch refetch={refetchData} /> */}
        <div className={classes.timer__divider}></div>
      </Box>

      <Box className={classes.root}>
        <Box className={classes.paper}>
          <CCDialogTitle>DashBoard</CCDialogTitle>

          <CCDialogContent className={classes.content}>
            <Grid
              container
              direction={"column"}
              wrap={"nowrap"}
              className={classes.container}
            >
              <Grid container spacing={3} >
                <Grid item xs={6} sm={12}>
                  <Paper
                    className={classes.paper_statistic_box}
                    onClick={navigateRegistrationPage}
                  >
                   <div className={classes.padding16} style={{display: 'flex'}}>
                      <h3 style={{width: '220px'}}> Registration Management </h3> {`>`}
                    </div>

                    <Divider inset className={classes.dividerHeight} />

                    <StatisticBox statistics={registrationStatistics} />
                  </Paper>
                </Grid>

                <Grid item xs={6} sm={12}>
                  <Paper
                    className={classes.paper_statistic_box}
                    onClick={navigateUserManagePage}
                  >
                    <div className={classes.padding16} style={{display: 'flex'}}>
                      <h3 style={{width: '220px'}}> User Management </h3> {`>`}
                    </div>

                    <Divider inset className={classes.dividerHeight} />

                    <StatisticBox statistics={userStatisticsData} />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </CCDialogContent>
        </Box>
      </Box>

      
      {isOpenSetSmtpSetting && (
        
        <DialogWarningConfirm
          open={isOpenSetSmtpSetting}
          onAgree={() => {
            dispatchState({
              type: "openSetSmtpSettingState",
              target: false
            });
            navigateSmtpPage();
            // dispatchState({
            //   type: "processingTerminatedState",
            //   target: false
            // });
          }}
          onClose={() => {
            dispatchState({
              type: "openSetSmtpSettingState",
              target: false
            });
          }}
          agreeButtonLabel={"OK"}
          // agreeButtonColor={"error"}
          // disagreeButtonLabel={"Cancel"}
          title={`Please set the SMTP information?`}
          subTitle={""}
        />
      )}
    </>
  );
};

export default DashBoard;
