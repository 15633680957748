import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, MenuItem, MenuList, Popover } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import { PasswordChangeDialog } from "components";
import { DialogWarningConfirm } from "components";
import { CCTypography } from "styles/components";
import { LockIcon } from "styles/icons";
import { AppContext, RouteContext } from "context";
import { useIntl } from "react-intl";

const useStyle = makeStyles(theme => ({
  root: {},
  menuList: { padding: 0 },
  menuItem: {
    minWidth: 200
  },
  userBackground: {
    backgroundColor: theme.palette.result
  },
  highlight: {
    color: theme.palette.secondary.main
  },
  icon: { margin: 4 }
}));

const LogoutSelect = props => {
  const { anchorEl = null, onClose, open } = props;
  const classes = useStyle();
  const [logoutAlert, setLogoutAlert] = useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const { dispatchRoute } = useContext(RouteContext);
  const { dispatchApp } = useContext(AppContext);
  const intl = useIntl();
  const managerId = localStorage.getItem('managerId');
  // const [createLog] = useMutation(CREATE_LOG);

  // const { data } = useQuery(GET_LOGIN_STAFF, {
  //   fetchPolicy: 'network-only',
  // });

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        onClose={onClose}
        open={open}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuList className={classes.menuList}>
          <MenuItem className={clsx(classes.menuItem, classes.userBackground)}>
            <Grid container justify={"center"} alignItems={"center"}>
              <Grid item>
                <CCTypography variant={"body1"} component={"span"}>
                  {"Login : "}
                </CCTypography>
              </Grid>
              <Grid item>
                <CCTypography
                  variant={"body1"}
                  component={"span"}
                  className={classes.highlight}
                >
                  {/* {data?.user?.name || ''} */}
                  &nbsp;{managerId}
                </CCTypography>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem
            className={clsx(classes.menuItem)}
            onClick={() => {
              setChangePasswordDialog(true);
            }}
          >
            <Grid container justify={"center"} alignItems={"center"}>
              <Grid item>
                <LockIcon className={classes.icon} />
              </Grid>
              <Grid item>
                <CCTypography variant={"body1"} component={"span"}>
                  {/* {"Change password"} */}
                  {intl.formatMessage({
                    id: "word.language",
                    defaultMessage: "Change Password"
                  })}
                </CCTypography>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem
            className={clsx(classes.menuItem)}
            onClick={() => {
              setLogoutAlert(true);
            }}
          >
            <Grid container justify={"center"} alignItems={"center"}>
              <Grid item>
                <InputIcon className={classes.icon} />
              </Grid>
              <Grid item>
                <CCTypography variant={"body1"} component={"span"}>
                  {"Log out"}
                </CCTypography>
              </Grid>
            </Grid>
          </MenuItem>
        </MenuList>
      </Popover>
      <DialogWarningConfirm
        open={logoutAlert}
        onAgree={() => {
          sessionStorage.setItem("token", "");
          dispatchRoute({ path: "/signin" });
          dispatchApp({ type: "permission", target: null });
          // createLog({
          //   variables: {
          //     hospitalId: localStorage.getItem('labId'),
          //     action: '4',
          //     category: '18',
          //     log: {
          //       contents: '로그아웃',
          //       category: LOG_CATEGORY.CONNECTION.value,
          //     },
          //   },
          // });
        }}
        onClose={() => setLogoutAlert(false)}
        agreeButtonLabel={"Log out"}
        agreeButtonColor={"primary"}
        disagreeButtonLabel={"Cancel"}
        title={"Are you sure you want to log out?"}
      />
      <PasswordChangeDialog
        open={changePasswordDialog}
        onClose={() => setChangePasswordDialog(false)}
      />
    </>
  );
};
export default withRouter(LogoutSelect);
