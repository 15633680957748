import React from "react";
import { Grid } from "@material-ui/core";

import AttachmentCard from "../AttachmentCard";

const Samples = props => {
  const { samples, labId } = props;
  return (
    <Grid container>
      {samples.map(sample => (
        <AttachmentCard
          key={sample.id}
          type={sample.type}
          target={sample.target}
          id={sample.id}
          labId={labId}
        />
      ))}
    </Grid>
  );
};

export default Samples;
