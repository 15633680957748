import React from "react";
import WithRouter from "components/WithRouter";
import { pages as commonRoute } from "views/Common/Route";
import { pages as normalRoute } from "views/Normal/Route";
import moment from "moment";

function App() {
  const language = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en";
  moment.locale(language)
  const pages = [...commonRoute, ...normalRoute];
  return <WithRouter pages={pages} />;
}

export default App;
