import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import {
  CCDateField,
  CCTextField,
  CCDialogInputError
} from "styles/components";
import { CVTextField, CVFlattenDateField } from "components";
import moment from "moment";

const useStyles = makeStyles(() => ({
  root: {
    width: 624
  },
  date__field: {
    width: 220
  },
  link__field: {
    "& textarea": {
      lineHeight: 1.5,
      fontSize: "0.875rem"
    }
  }
}));

const NotificationField = props => {
  const classes = useStyles();
  const { field, onHandle } = props;

  const handleField = name => e => {
    onHandle({
      ...field,
      [name]: name === "date" ? moment(e) : e.target.value
    });
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <CVFlattenDateField
        className={classes.date__field}
        value={moment(field.date, "X")}
        label={"Date"}
        onChange={handleField("date")}
        required
      />
      {/* <CCDateField
        label="Date"
        // value={field.date * 1000}
        value={moment()}
        className={classes.date__field}
        onChange={handleField("date")}
      /> */}
      <CCTextField
        variant="outlined"
        margin="dense"
        label={"Title"}
        placeholder="Title"
        value={field.title}
        onChange={handleField("title")}
        required
      />
      <CCTextField
        variant={"outlined"}
        placeholder={"Link"}
        label={"Link"}
        multiline
        rows={3}
        value={field.link}
        className={classes.link__field}
        onChange={handleField("link")}
        required
      />
    </Grid>
  );
};

export default NotificationField;
