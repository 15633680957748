

const LocalizeVariable = {
  get countryCode() {
    switch (process.env.REACT_APP_REGION) {
      case "vietnam":
        return "vi";
      case "russia":
        return "ru";
      case "egypt":
        return "eg";
      case "korea":
        return "ko";
      case "develop":
      default:
        return "vi";
    }
  },

  get countryPhoneCode() {
    switch (this.countryCode) {
      case "vi":
        return "vn";
      case "ru":
        return "ru";
      case "eg":
        return "eg";
      case "ko":
        return "kr";
      case "develop":
      default:
        return "vi";
    }
  },

};

export { LocalizeVariable };
