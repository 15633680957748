import React, { useEffect, useRef, useContext, useState } from "react";
import clsx from "clsx";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import {useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { CVPasswordField } from "components";
import { CCButton, CCTextField, CCTypography } from "styles/components";
import { ProgressIcon } from "styles/icons";
import { AppContext, RouteContext } from "context";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { DialogErrorConfirm } from "components";

import { DialogSuccessConfirm } from "components";
// import { frontErrorCode } from "../../utils/errorcode"
import { DialogWarningConfirm } from "components";
import { serverErrorCode } from "../../../utils/errorcode";

const MANAGER_LOGIN = gql`
  mutation ManagerLogin($managerId: String!, $password: String!) {
    managerLogin(managerId: $managerId, password: $password)
  }
`;

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: 320
  },
  title: {
    lineHeight: "34px",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "Bold"
  },
  group__input: {
    margin: "12px 0"
  },
  textfield: {
    width: "100%",
    margin: "12px 0",
    background: "white"
  },
  btn__login: {
    padding: "8px 16px"
  },
  text__login: {
    width: "100%",
    lineHeight: "20px",
    alignSelf: "center",
    color: "white"
  }
}));

const SignIn = () => {
  const classes = useStyles();

  const { appContext } = useContext(AppContext);
  const { dispatchRoute } = useContext(RouteContext);
  const idRef = useRef(null);
  const passwordRef = useRef(null);

  const [isOpenErrorLoginDialog, setIsOpenErrorLoginDialog] = useState(false);
  const [isOpenSuccessLoginDialog, setIsOpenSuccessLoginDialog] =
    useState(false);

  const [errLoginMessage, setErrorLoginMessage] = useState("");

  let isOpen = false;

  const [managerLogin, { data, loading, refetch }] = useMutation(
    MANAGER_LOGIN,
    {
      onCompleted: data => {
        sessionStorage.setItem("mode", "normal");
        sessionStorage.setItem("token", data.managerLogin);

        console.log("managerLogin.onCompleted", data);

        setIsOpenSuccessLoginDialog(true);
      },
      onError: err => {
        const errCode = err?.graphQLErrors[0].extensions?.code;

        if (errCode === "MANAGER02") {
          setIsOpenErrorLoginDialog(true);
          setErrorLoginMessage("This id not exist");
        }

        if (errCode === "AUTH02") {
          setIsOpenErrorLoginDialog(true);
          setErrorLoginMessage("Wrong Password");
        }

        if (errCode === "AUTH03") {
          setIsOpenErrorLoginDialog(true);
          setErrorLoginMessage("Your account has been locked since you entered an incorrect password 5 times. Please ask another administrator to reset your password");
        }
      }
    }
  );





  const normalSignIn = () => {
    const managerId = idRef.current.value;
    managerLogin({
      variables: {
        managerId,
        password: passwordRef.current.value
      }
    })
      .then(data => {
        console.log("normalSignIn data", data);
        localStorage.setItem("managerId", managerId);
        localStorage.setItem("justLoginCheck", true);
      })
      .catch(error => {
        console.log(JSON.stringify(error));
        const errorMessage =
          error.graphQLErrors && error.graphQLErrors[0]
            ? error.graphQLErrors[0].message
            : error;
        alert(errorMessage);
      });
  };

  useEffect(() => {
    sessionStorage.setItem("token", "");
  }, []);

  return (
    <Box
      className={clsx(classes.root, classes.height)}
      style={{ backgroundImage: appContext.bg }}
    >
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Typography variant={"h1"} className={classes.title}>
            Clever Lab Manager
          </Typography>
        </Grid>
        <Grid item className={classes.group__input}>
          <CCTextField
            required
            fullWidth
            autoComplete={"username"}
            inputRef={idRef}
            label={"User ID"}
            variant={"outlined"}
            className={classes.textfield}
          />

          <CVPasswordField
            fullWidth
            required
            variant={"outlined"}
            type="password"
            label={"Password"}
            autoComplete={"current-password"}
            inputRef={passwordRef}
            className={classes.textfield}
            onFocus={() => {
              document.dispatchEvent(
                new KeyboardEvent("keyDown", { code: 229 })
              );
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                normalSignIn();
              }
            }}
          />
        </Grid>

        <CCButton
          fullWidth
          variant="contained"
          color="primary"
          startIcon={<ProgressIcon width={36} height={36} />}
          className={classes.btn__login}
          onClick={normalSignIn}
        >
          <CCTypography variant="h3" className={classes.text__login}>
            Sign In
          </CCTypography>
        </CCButton>

        <DialogErrorConfirm
          open={isOpenErrorLoginDialog}
          onAgree={() => {
            setIsOpenErrorLoginDialog(false);
          }}
          agreeButtonLabel={"Confirm"}
          agreeButtonColor={"error"}
          // onClose={() => setIsOpenErrorLoginDialog(false)}
          // disagreeButtonLabel={"Cancel"}
          // secondaryAction={true}
          // subTitle={"12313123123"}
          title={errLoginMessage}
        />

        <DialogSuccessConfirm
          open={isOpenSuccessLoginDialog}
          onAgree={() => {
            setIsOpenSuccessLoginDialog(false);
            dispatchRoute({ path: "/applicationManagement" });
          }}
          agreeButtonLabel={"Confirm"}
          agreeButtonColor={"primary"}
          // onClose={() => setIsOpenErrorLoginDialog(false)}
          // disagreeButtonLabel={"Cancel"}
          // secondaryAction={true}
          // subTitle={"12313123123"}
          title={"You have successfully signed in."}
        />
      </Grid>
    </Box>
  );
};

export default SignIn;
