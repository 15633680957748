import gql from "graphql-tag";

const GET_MANAGER_LIST = gql`
  query managerList($input: ManagerListInput!) {
    managerList(input: $input) {
      items{
        id
        managerId
        name
      }
      total
    }
  }
`;

const CREATE_MANAGER = gql`
  mutation createManager($input: CreateManagerInput!) {
    createManager(input: $input) {
      id
      managerId
      name
    }
  }
`;

const UPDATE_MANAGER = gql`
  mutation updateManager($input: UpdateManagerInput!) {
    updateManager(input: $input) {
      id
      managerId
      name
    }
  }
`;

const DELETE_MANAGER = gql`
  mutation deleteManager($id: String!){
    deleteManager(id: $id) {
      id
      managerId
      name
    }
  }
`;

export {
  GET_MANAGER_LIST,
  CREATE_MANAGER,
  UPDATE_MANAGER,
  DELETE_MANAGER,
};
