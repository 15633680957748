import gql from "graphql-tag";

const GET_NOTICES = gql`
  query GetNotices($input: NoticeListInput!) {
    notices(input: $input) {
      total
      items {
        id
        title
        link
        date
        isPublished
        createdAt
      }
    }
  }
`;
const CREATE_NOTICE = gql`
  mutation CreateNotice($input: CreateNoticeInput!) {
    createNotice(input: $input) {
      id
    }
  }
`;
const UPDATE_NOTICE = gql`
  mutation UpdateNotice($input: UpdateNoticeInput!) {
    updateNotice(input: $input) {
      id
    }
  }
`;
const DELETE_NOTICE = gql`
  mutation DeleteNotice($id: ID!) {
    deleteNotice(id: $id) {
      id
    }
  }
`;

const CHANGE_PUBLISHED_STATE = gql`
  mutation changePublishedState($id: ID!, $isPublished: Boolean !) {
    changePublishedState(id: $id, isPublished: $isPublished) {
      code
      message
    }
  }
`;

export { GET_NOTICES, CREATE_NOTICE, UPDATE_NOTICE, DELETE_NOTICE, CHANGE_PUBLISHED_STATE };
