import React, { useState, useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import { CCTypography } from "styles/components";
import { ResetIcon } from "styles/icons";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "0 12px",
    backgroundColor: theme.palette.sub.main,
    borderRadius: 18,
    color: theme.palette.common.white,
    height: 30,
    width: 171
  },
  time: {
    color: theme.palette.common.white,
    lineHeight: 1,
    fontWeight: 700
  },
  reset: {
    cursor: "pointer"
  }
}));

const TimerWithRefetch = props => {
  const { refetch } = props;
  const classes = useStyles();
  const [time, setTime] = useState(moment().format("HH:mm"));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format("HH:mm"));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box className={classes.root}>
      {refetch && <ResetIcon className={classes.reset} onClick={refetch} />}
      <CCTypography variant="body1" className={classes.time}>
        {moment().format("YYYY/MM/DD")}
        {` `}
        {time}
      </CCTypography>
    </Box>
  );
};

export default TimerWithRefetch;
