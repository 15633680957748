import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import moment from "moment";
import propTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CCTextFieldQuattro from "styles/src/components/CCTextFieldQuattro";
import { DateIcon } from "styles/src/themes/common/icons";
import CCFlattenDatePicker from "./CCFlattenDatePicker";

const useStyle = makeStyles(theme => ({
  root: {},
  button__height: {
    height: 36
  },
  width__100: {
    width: "100%"
  },
  chart__field: {
    marginLeft: 0,
    marginRight: 0
  },
  marginright__16: {
    marginRight: 16
  },
  marginright__8: {
    marginRight: 8
  },
  chart__input: {
    width: 118
  },
  timespent__input: {
    width: 171 / 2
  },
  searchButton: {
    margin: 0
  },
  paddingright__0: {
    paddingRight: 0
  },
  paddingleft__0: {
    paddingLeft: 0
  },
  cellphoneField: {
    width: 194
  },
  dateButton: {
    color: theme.palette.icon
  }
}));
function pad(n, width) {
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join("0") + n;
}
const MeridiemItems = function* () {
  const meridiem = ["AM", "PM"];
  for (let i in meridiem) {
    yield (
      <MenuItem key={"chart__field__meridiem__menuitem" + i} value={i}>
        {meridiem[i]}
      </MenuItem>
    );
  }
};

const InputMenuHourItems = function* (count, value, label) {
  for (let i = 0; i < count; i++) {
    if (value.hours() >= 12) {
      if (i === 11) {
        yield (
          <MenuItem key={"MenuHourItem" + i} value={i + 1}>
            {pad(i + 1, 2) + label}
          </MenuItem>
        );
      } else {
        yield (
          <MenuItem key={"MenuHourItem" + i} value={i + 1 + 12}>
            {pad(i + 1, 2) + label}
          </MenuItem>
        );
      }
    } else {
      yield (
        <MenuItem key={"MenuHourItem" + i} value={(i + 1) % 12}>
          {pad(i + 1, 2) + label}
        </MenuItem>
      );
    }
  }
};

const InputMenuTimeItems = function* (count, step, label) {
  for (let i = 0; i < count; i++) {
    if (i % step !== 0) continue;
    yield (
      <MenuItem key={"MenuTimeItem" + i} value={i}>
        {pad(i, 2) + label}
      </MenuItem>
    );
  }
};

const CCFlattenDateTimeFieldQuattro = forwardRef((props, ref) => {
  const intl = useIntl();
  const {
    value,
    onChange,
    readOnly,
    step,
    inputRef,
    onBlur,
    required,
    error,
    name,
    dateLabel = intl.formatMessage({ id: "ccDate" }),
    hoursLabel = intl.formatMessage({ id: "ccTimeUnit" }),
    minutesLabel = intl.formatMessage({ id: "ccMinuteUnit" }),
    meridiemLabel = intl.formatMessage({ id: "ccMeridiem" }),
    hoursCountLabel = intl.formatMessage({ id: "ccTimeUnit" }),
    minutesCountLabel = intl.formatMessage({ id: "ccMinuteUnit" }),
    ...others
  } = props;
  const classes = useStyle();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [momentValue, setMomentValue] = useState(value);
  const [textValue, setTextValue] = useState(value.format("YYYY/MM/DD"));
  useEffect(() => {
    setTextValue(value.format("YYYY/MM/DD"));
  }, [value]);
  useEffect(() => {
    if (value.format("YYYY/MM/DD") !== momentValue.format("YYYY/MM/DD"))
      setMomentValue(value);
  }, [value, momentValue]);
  return (
    <>
      <CCFlattenDatePicker
        open={pickerOpen}
        value={momentValue}
        onChange={e => {
          onChange(e);
          setPickerOpen(false);
        }}
        onClose={() => {
          setPickerOpen(false);
        }}
      />
      <CCTextFieldQuattro
        {...others}
        variant={"outlined"}
        margin={"dense"}
        LeftProps={{
          label: dateLabel,
          error: Boolean(error),
          value: textValue,
          required: required,
          inputRef: ref,
          onChange: e => {
            setTextValue(e.target.value);
          },
          onClickIcon: () => setPickerOpen(true),
          onBlur: () => setTextValue(value.format("YYYY/MM/DD")),
          InputProps: {
            readOnly: true
          },
          endIcon: <DateIcon className={clsx(classes.dateButton)} />
        }}
        MidProps={{
          label: hoursLabel,
          error: error,
          InputProps: {
            className: clsx(classes.timespent__input)
          },
          required: required,
          InputLabelProps: {
            shrink: true
          },
          value: value.format("H"),
          select: true,
          onChange: e => {
            onChange(value.clone().hours(e.target.value));
          },
          children: [...InputMenuHourItems(12, value.clone(), hoursCountLabel)]
        }}
        Mid2Props={{
          label: minutesLabel,
          error: Boolean(error),
          InputProps: {
            className: clsx(classes.timespent__input)
          },
          required: required,
          InputLabelProps: {
            shrink: true
          },
          value: value.format("m"),
          select: true,
          onChange: e => {
            onChange(value.clone().minutes(e.target.value));
          },
          children: [...InputMenuTimeItems(60, step, minutesCountLabel)]
        }}
        RightProps={{
          label: meridiemLabel,
          error: Boolean(error),
          required: required,
          InputProps: {
            className: clsx(classes.timespent__input)
          },
          InputLabelProps: {
            shrink: true
          },
          value: value.hours() < 12 ? 0 : 1,
          select: true,
          onChange: e => {
            if (e.target.value === "1") {
              if (value.hours() < 12) {
                let _res = value.clone().add(12, "hours");
                onChange && onChange(_res);
              }
            } else {
              if (value.hours() >= 12) {
                let _res = value.clone().subtract(12, "hours");
                onChange && onChange(_res);
              }
            }
          },
          children: [...MeridiemItems()]
        }}
      />
    </>
  );
});

CCFlattenDateTimeFieldQuattro.propTypes = {
  value: propTypes.instanceOf(moment).isRequired,
  onChange: propTypes.func.isRequired,
  readOnly: propTypes.bool,
  step: propTypes.number
};
CCFlattenDateTimeFieldQuattro.defaultProps = {
  step: 10
};

export default CCFlattenDateTimeFieldQuattro;
