import React, { useEffect, useState } from "react";
import moment from "moment";
import { CCButton, CCDialogSimple } from "styles/components";
import { CheckIcon } from "styles/icons";
import NotificationField from "../NotificationField";

const initField = {
  // date: moment(new Date()).format("YYYY/MM/DD"),
  date: moment().unix(),
  title: "",
  link: ""
};

const AddNotificationModal = props => {
  const { open, selectedNotice, onClose, onSave } = props;
  const [field, setField] = useState(initField);

  useEffect(() => {
    if (selectedNotice) {
      setField(prevState => ({
        ...prevState,
        ...selectedNotice
      }));
    } else {
      setField({ ...initField });
    }
  }, [selectedNotice]);

  const handleCancelDialog = () => {
    onClose();
  };

  return (
    <CCDialogSimple
      open={open}
      title={"Notice"}
      contents={<NotificationField field={field} onHandle={setField} />}
      contentsPadding={false}
      maxWidth={"md"}
      endActions={
        <>
          <CCButton onClick={onClose}>Cancel</CCButton>
          <CCButton
            onClick={() => onSave(field)}
            startIcon={<CheckIcon />}
            variant="contained"
          >
            Save
          </CCButton>
        </>
      }
      onClose={handleCancelDialog}
      onCloseButton={handleCancelDialog}
    />
  );
};

export default AddNotificationModal;
