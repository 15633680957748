import React from "react";
import { DialogWarn } from "components";

const DialogPermissionWarn = props => {
  const { open, onClose } = props;

  return (
    <DialogWarn open={open} onClose={onClose} text={"접근 권한이 없습니다."} />
  );
};

export default DialogPermissionWarn;
