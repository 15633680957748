import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useReducer } from 'react';
import { CCIconButton } from 'styles/components';
import {
  HelpIcon,
  NotificationsIcon,
  RecordsIcon,
  ScannerIcon,
  ScreensaverIcon,
  SignoutIcon,
  XrayIcon,
} from 'styles/icons';
import { LogoutSelect, Notification, SlideShow, SlideShowDialog } from '..';

const useStyles = makeStyles((theme) => ({
  root: {},
  border: {
    width: 1,
    height: 32,
    margin: '0 0px',
    borderRight: `1px solid ${theme.palette.border.main}`,
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  icon__button: { margin: 0 },
}));

const initDialog = {
  notification: { state: false, value: null },
  oralCamera: { state: false },
  xray: { state: false, value: null },
  explorer: { state: false, value: null },
  slideshowdialog: { state: false, value: null },
  slideshow: { state: false, value: null },
  logout: { state: false, value: null },
};

const dialogReducer = (state, action) => {
  return { ...state, [action.type]: action.value };
};

const IconMenu = () => {
  const classes = useStyles();
  const [dialog, dispatchDialog] = useReducer(dialogReducer, initDialog);

  return (
    <div className={classes.root}>
      {/* <CCIconButton
        className={clsx(classes.icon__button)}
        variant={'text'}
        color={'default'}
        onClick={() => {
          dispatchDialog({
            type: 'explorer',
            value: { state: true, value: null },
          });
        }}
      >
        <RecordsIcon />
      </CCIconButton>

      <div className={classes.border} /> */}

      {/* <CCIconButton
        className={classes.icon__button}
        onClick={(e) => {
          dispatchDialog({
            type: 'slideshowdialog',
            value: { state: true, value: e.currentTarget },
          });
        }}
      >
        <ScreensaverIcon />
      </CCIconButton>

      <CCIconButton
        className={classes.icon__button}
        variant={'text'}
        color={'default'}
        onClick={(e) => {
          dispatchDialog({
            type: 'xray',
            value: { state: true, value: e.currentTarget },
          });
        }}
      >
        <XrayIcon />
      </CCIconButton> */}

      {/* <CCIconButton
        className={classes.icon__button}
        onClick={() => {
          const content = {};
          content["type"] = "ezdenti";
          // 환자 선택된 경우
          if (patientContext) {
            content["chartNumber"] = patientContext.chartNumber;
          }
          // 환자 미선택 된 경우
          else {
            content["chartNumber"] = "";
          }
          EzDenti(content);
        }}
      >
        <EzdentiIcon />
      </CCIconButton> */}
      {/* 
      <CCIconButton className={classes.icon__button} onClick={() => {}}>
        <ScannerIcon />
      </CCIconButton> */}
      {/* 
      <div className={classes.border} /> */}

      {/* <CCTooltip
        title={`CID 로그인 상태 - ${
          cidContext?.logInState?.status === true ? "접속중" : "접속해제"
        }, 에러발생 - ${
          Boolean(cidContext?.logInState?.error) ? "있음" : "없음"
        }`}
      >
        <CCIconButton
          className={classes.icon__button}
          onClick={() => {
            if (cidContext?.logInState?.status === true) {
              const content = {};
              content["Type"] = "logout";
              CID(content);
            } else {
              if (cidContext.id === "" || cidContext.password === "") {
                return;
              }
              const content = {};
              content["AuthKey"] = cidContext.apiKey;
              content["UserID"] = cidContext.id;
              content["UserPW"] = cidContext.password;
              content["Type"] = "login";
              CID(content);
            }
          }}
        >
          <Badge
            variant={"dot"}
            color={Boolean(cidContext?.logInState?.error) ? "error" : "primary"}
          >
            <CallIcon
              color={
                cidContext?.logInState?.status === true ? "primary" : "#ababab"
              }
            />
          </Badge>
        </CCIconButton>
      </CCTooltip> */}

      {/* <CCIconButton className={classes.icon__button} onClick={() => {}}>
        <HelpIcon />
      </CCIconButton>

      <Notification>
        <NotificationsIcon />
      </Notification> */}

      <CCIconButton
        className={classes.icon__button}
        onClick={(e) => {
          dispatchDialog({
            type: 'logout',
            value: { state: true, value: e.currentTarget },
          });
        }}
      >
        <SignoutIcon />
      </CCIconButton>

      <LogoutSelect
        anchorEl={dialog.logout.value}
        open={dialog.logout.state}
        onClose={() =>
          dispatchDialog({
            type: 'logout',
            value: { state: false, value: null },
          })
        }
      />
      {/* <SlideShowDialog
        open={dialog.slideshowdialog.state}
        anchorEl={dialog.slideshowdialog.value}
        onClick={(photos) => {
          if (photos.length > 0) {
            dispatchDialog({
              type: 'slideshow',
              value: { state: true, value: photos },
            });
          }
        }}
        onClose={() => {
          dispatchDialog({
            type: 'slideshowdialog',
            value: { state: false, value: null },
          });
        }}
      /> */}
      {/* <SlideShow
        open={dialog.slideshow.state}
        photos={dialog.slideshow.value}
        onClose={() => {
          dispatchDialog({
            type: 'slideshow',
            value: { state: false, value: null },
          });
        }}
      /> */}
      {/* <Explorer
        open={dialog.explorer.state}
        // open={true}
        onClose={e =>
          dispatchDialog({
            type: "explorer",
            value: { state: false, value: null }
          })
        }
        patient={
          patientContext?.id
            ? {
                id: patientContext?.id,
                value: { ...patientContext }
              }
            : null
        }
      /> */}
    </div>
  );
};

export default IconMenu;
