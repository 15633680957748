import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";

import { CCButton, CCTextField } from "styles/components";
import { SearchIcon } from "styles/icons";
import { DateQueryFilter } from "components";

import { makeList } from "types";
import { CUSTOMER_STATUS_TYPES } from "types/customer";

const useStyles = makeStyles(theme => ({
  input: { backgroundColor: theme.palette.common.white },
  searchtext__field: { width: 243 },
  ml8: {
    marginLeft: 8
  },
  selection: {
    width: 142,
    backgroundColor: theme.palette.common.white
  }
}));

const CustomerFilter = props => {
  const { filter, onSearch } = props;
  const classes = useStyles();
  const [value, setValue] = useState(filter);

  const customerStatusList = makeList(CUSTOMER_STATUS_TYPES);

  useEffect(() => {
    setValue(filter);
  }, [filter]);

  console.log("CustomerFilter.value", value)

  return (
    <>
      <DateQueryFilter value={value} onChange={val => setValue(val)} />

      <CCTextField
        value={value.status}
        label={"Status"}
        select
        variant="outlined"
        margin="dense"
        className={classes.selection}
        onChange={e => setValue({ ...value, status: e.target.value })}
      >
        {customerStatusList.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </CCTextField>

      <CCTextField
        className={classes.searchtext__field}
        variant="outlined"
        margin="dense"
        label="Lab, Manager, Cell No., Tel No."
        InputProps={{ classes: { root: classes.input } }}
        value={value.searchText}
        onChange={e => {
          setValue({ ...value, searchText: e.target.value });
        }}
      />

      <CCButton
        variant="contained"
        color="secondary"
        startIcon={<SearchIcon />}
        className={classes.ml8}
        onClick={() => {
          onSearch && onSearch({ ...value });
        }}
      >
        Search
      </CCButton>
    </>
  );
};

export default CustomerFilter;
