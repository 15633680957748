import React, { useReducer } from "react";
import clsx from "clsx";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/styles";
import { Box, Checkbox, Grid } from "@material-ui/core";
import { StarOutlined } from "@material-ui/icons";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogContent,
  CCDialogTitle,
  CCTypography,
  CCDialogSimple,
  CCTooltip
} from "styles/components";
import { HappyIcon, PauseIcon, BadIcon, CommitmentIcon } from "styles/icons";
import { CVTable, TimerWithRefetch } from "components";
import { WithdrawModal, CustomerFilter, LabSearchModal } from "./components";
import { StatisticBox } from "views/Normal/ApplicationManagement/components";

import { makeIndexFormattedMessageList } from "types";
import { CUSTOMER_STATUS_TYPES, WITHDRAW_TYPES } from "types/customer";
import { GENDER_TYPES } from "types/staff";
import {
  GET_LAB_STATS,
  GET_LAB_LIST,
  UPDATE_LAB_STATUS,
  TOGGLE_RECOMMENDED_LAB
} from "queries/customer";

const LIMIT = 12;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white
  },
  timer: {
    position: "absolute",
    zIndex: 1200,
    top: 18,
    right: 32,
    display: "flex"
  },
  timer__divider: {
    height: 36,
    borderLeft: "3px solid rgba(0, 0, 0, 0.16)",
    margin: "0 16px"
  },
  paper: { height: "100%", width: "100%" },
  content: { height: "calc(100% - 55px)", overflow: "hidden" },
  container: { height: "100%" },
  item: { width: "100%" },
  container__filter: {
    width: "100%",
    padding: "4px 12px",
    margin: "0px",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  input: { backgroundColor: theme.palette.common.white },
  title__field: { width: 243 },
  item__table: { height: "calc(100% - 205px)" },
  table: {
    height: "100%",
    textAlign: "center",

    "& .MuiTypography-colorInherit": {
      color: theme.palette.input.main
    }
  },
  table__body: {
    backgroundColor: "rgba(207, 216, 220, 0.87)",
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    }
  },
  table__cell: {
    padding: "6px 4px"
  },
  toolbar__divider: {
    borderLeft: `1px solid ${theme.palette.border.main}`,
    height: 36,
    marginRight: 8
  },
  ml8: {
    marginLeft: 8
  },
  container__status: {
    position: "relative",
    display: "flex",
    borderTop: `1px solid ${theme.palette.border.main}`,
    padding: "28px 12px"
  },
  lab__search: {
    position: "absolute",
    right: 16,
    bottom: 16
  },
  inuse: {
    color: theme.palette.primary.main
  },
  withdraw: {
    color: theme.palette.error.main
  },
  discontinued: {
    color: theme.palette.border
  },
  start_button: {
    color: "rgba(0, 0, 0, 0.38)",
    cursor: "pointer"
  },
  is_recommended: {
    color: "#ffab00"
  }
}));

const initializer = {
  filter: {
    // start: moment().startOf("month").unix(),
    // end: moment().endOf("month").unix(),
    searchText: "",
    status: 0
  },
  withdrawModalVisible: false,
  labSearchModalVisible: false,
  successWithdrawDialogContent: false,
  checkedList: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case "filter":
      return {
        ...state,
        filter: action.target
      };
    case "setCheckedList":
      return {
        ...state,
        checkedList: action.target
      };
    case "setWithDrawModal":
      return {
        ...state,
        withdrawModalVisible: action.target
      };
    case "setLabSearchModal":
      return {
        ...state,
        labSearchModalVisible: action.target
      };
    case "setSuccessWithdrawDialog":
      return {
        ...state,
        successWithdrawDialogContent: action.target
      };
    case "reset":
      return { ...initializer };
    default:
      break;
  }

  return state;
};

const CustomerManagement = () => {
  const classes = useStyles();
  const intl = useIntl();

  const [state, dispatchState] = useReducer(reducer, initializer);
  const {
    filter,
    withdrawModalVisible,
    checkedList,
    labSearchModalVisible,
    successWithdrawDialogContent
  } = state;

  const customerStatusIndex = makeIndexFormattedMessageList(
    CUSTOMER_STATUS_TYPES
  );

  console.log("customerStatusIndex =>>>>>>", customerStatusIndex);
  const genderIndex = makeIndexFormattedMessageList(GENDER_TYPES);

  const { data: dataStatistics, refetch: refetchStatistics } = useQuery(
    GET_LAB_STATS
  );
  const {
    data: datLabList,
    loading,
    error,
    refetch: refetchLabList
  } = useQuery(GET_LAB_LIST, {
    variables: {
      input: {
        limit: LIMIT
      }
    }
  });
  const [updateLabStatus] = useMutation(UPDATE_LAB_STATUS, {
    onCompleted: () => {
      refetchStatistics();
      refetchLabList();
    }
  });
  const [toggleIsRecommendedLab] = useMutation(TOGGLE_RECOMMENDED_LAB, {
    onCompleted: () => {
      refetchLabList();
    }
  });

  const statistics = [
    {
      title: "Total Approval",
      color: "blue",
      data: dataStatistics && dataStatistics.labStatistics.totalApproval
    },
    {
      title: "In Use",
      color: "green",
      data: dataStatistics && dataStatistics.labStatistics.totalInuse
    },
    {
      title: "Discontinued",
      color: "red",
      data: dataStatistics && dataStatistics.labStatistics.totalDiscontinued
    },
    {
      title: "With drawn after 1 month",
      color: "black",
      data: dataStatistics && dataStatistics.labStatistics.totalWithdrawAfterOneMonth
    },
    {
      title: "Withdrawn",
      color: "gray",
      data: dataStatistics && dataStatistics.labStatistics.totalWithdraw
    }
  ];

  const reformatStatus = (rowData = {}) => {
    const { status, withdrawDateTime } = rowData;

    if (status === parseInt(CUSTOMER_STATUS_TYPES.IN_USE.value)) {
      return customerStatusIndex[status]
    }

    if (status === parseInt(CUSTOMER_STATUS_TYPES.DISCONTINUED.value)) {
      return customerStatusIndex[status]
    }

    if (status === parseInt(CUSTOMER_STATUS_TYPES.WITHDRAW.value)) {
      // console.log("withdrawDateTime", moment.unix(withdrawDateTime).format("L"));
      // console.log("moment", moment.unix().format("L"));
      if (withdrawDateTime <= moment().unix()) {
        return customerStatusIndex[status]
      }

      // const defaultMessage = `Withdraw after 1m (${moment.unix(withdrawDateTime).format('YYYY.MM.DD')})`;

      // console.log("defaultMessage =>>>", defaultMessage);
      // return intl.formatMessage({
      //   id: "customer.withdraw.afterOneMonth",
      //   defaultMessage
      // })
      return `Withdraw`
    }

    if (status === parseInt(CUSTOMER_STATUS_TYPES.WITH_DRAW_AFTER_ONE_MONTH.value)) {
      // console.log("withdrawDateTime", moment.unix(withdrawDateTime).format("L"));
      // console.log("moment", moment.unix().format("L"));
      if (withdrawDateTime <= moment().unix()) {
        return customerStatusIndex[status]
      }

      return `Withdraw after 1m (${moment.unix(withdrawDateTime).format('YYYY.MM.DD')})`
    }

  }


  const heads = [
    {
      width: 100,
      className: classes.table__cell,
      component: ({ rowData }) => {
        const checked = !!checkedList[rowData.id];

        return (
          <Checkbox
            checked={checked}
            style={{ padding: 0 }}
            disableRipple
            onChange={() => {
              if (checked) {
                dispatchState({
                  type: "setCheckedList",
                  target: { ...checkedList, [rowData.id]: false }
                });
              } else {
                dispatchState({
                  type: "setCheckedList",
                  target: { ...checkedList, [rowData.id]: true }
                });
              }
            }}
          />
        );
      }
    },
    {
      key: "labName",
      label: "Lab",
      className: classes.table__cell
    },
    {
      key: "managerName",
      label: "Manager",
      className: classes.table__cell,
      component: ({ cellData }) => {
        return <CCTypography variant="body1">{cellData}</CCTypography>;
      }
    },
    { key: "labId", label: "Lab ID", className: classes.table__cell },
    {
      key: "managerId",
      label: "Manager ID",
      className: classes.table__cell,
      component: ({ rowData }) => {
        return <CCTypography variant="body1">{rowData.managerName}</CCTypography>;
      }
    },
    {
      key: "",
      label: "Manager Age/Birth Date",
      className: classes.table__cell,
      component: ({ rowData }) => {
        const age =
          moment().format("YYYY") -
          moment.unix(rowData?.birthDate).format("YYYY");
        // const gender = genderIndex[rowData?.manager?.gender];
        return (
          <CCTypography variant="body1">
            {`${age ? age : "0"}`}
            {/* <CCTypography component="span">
              {gender ? gender : "-"}
            </CCTypography> */}
            {`/${rowData?.birthDate
                ? moment.unix(rowData?.birthDate).format("YYYY/MM/DD")
                : "-"
              }`}
          </CCTypography>
        );
      }
    },
    {
      key: "labTelNumber",
      label: "Tel No.",
      className: classes.table__cell,
      component: ({ cellData }) => {
        return (
          <CCTypography variant="body1">
            {cellData ? cellData : ""}
          </CCTypography>
        );
      }
    },
    {
      key: "managerCellNumber",
      label: "Cell No.",
      className: classes.table__cell,
      component: ({ cellData }) => {
        return (
          <CCTypography variant="body1">
            {cellData ? cellData : ""}
          </CCTypography>
        );
      }
    },
    { key: "email", label: "Email", className: classes.table__cell },
    {
      key: "acceptedDate",
      label: "Accepted Date",
      className: classes.table__cell,
      component: ({ cellData }) => {
        return (
          <CCTypography variant="body1">
            {moment.unix(cellData).format("YYYY/MM/DD")}
          </CCTypography>
        );
      }
    },
    {
      key: "status",
      label: "Status",
      className: classes.table__cell,
      component: ({ cellData, rowData }) => {
        return (
          <CCTypography
            variant="body1"
            className={clsx({
              [classes.inuse]:
                CUSTOMER_STATUS_TYPES.IN_USE.value === String(cellData),
              [classes.discontinued]:
                CUSTOMER_STATUS_TYPES.DISCONTINUED.value === String(cellData),
              [classes.withdraw]:
                CUSTOMER_STATUS_TYPES.WITHDRAW.value === String(cellData)
            })}
          >
            {console.log("CustomerManagement.customerStatusIndex ->>>", rowData)}

            {reformatStatus(rowData)}
            {/* {customerStatusIndex[cellData]} */}
          </CCTypography>
        );
      }
    },

    {
      label: "Recommended",
      className: classes.table__cell,
      component: ({ rowData }) => {
        return (
          <CCTooltip
            title={
              <CCTypography color="inherit" variant="body1">
                Selecting the icon, the selected lab will be exposed at the top
                of the tab list when searched by clinics.
              </CCTypography>
            }
          >
            <StarOutlined
              className={clsx(classes.start_button, {
                [classes.is_recommended]: rowData.isRecommendedLab
              })}
              onClick={() =>
                toggleIsRecommendedLab({ variables: { labId: rowData.labId } })
              }
            />
          </CCTooltip>
        );
      }
    }
  ];

  const handleOnSearch = value => {
    console.log("handleOnSearch.value =>>>", value);
    dispatchState({
      type: "filter",
      target: value
    });
    refetchLabList({
      input: {
        limit: LIMIT,
        searchText: value.searchText,
        start: value.start,
        end: value.end,
        status: Number(value.status)
      }
    });
  };

  const filterSelectedCustomer = () => {
    return Object.keys(checkedList).filter(key => checkedList[key]);
  };

  const changeCustomerStatus = (
    status = CUSTOMER_STATUS_TYPES.IN_USE.value,
    withdrawType = WITHDRAW_TYPES.NOW.value
  ) => {
    const selectedCustomers = filterSelectedCustomer();
    if (!selectedCustomers.length) return;
    updateLabStatus({
      variables: {
        input: {
          status: Number(status),
          ids: selectedCustomers,
          withdrawDate: Number(withdrawType)
        }
      }
    });
  };

  const refetchData = () => {
    dispatchState({
      type: "reset"
    });
    refetchStatistics();
    refetchLabList({
      input: {
        limit: LIMIT
      }
    });
  };

  if (error) return;

  // if (loading) return <div>Loading...</div>;

  return (
    <>
      <Box className={classes.timer}>
        <TimerWithRefetch refetch={refetchData} />
        <div className={classes.timer__divider}></div>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <CCDialogTitle>Customer Management</CCDialogTitle>
          <CCDialogContent className={classes.content} noPadding>
            <Grid
              container
              direction={"column"}
              wrap={"nowrap"}
              className={classes.container}
            >
              <Grid item className={classes.container__status}>
                <StatisticBox statistics={statistics} />
                <CCButton
                  variant="contained"
                  color="normal"
                  startIcon={<CommitmentIcon />}
                  className={classes.lab__search}
                  onClick={() =>
                    dispatchState({
                      type: "setLabSearchModal",
                      target: true
                    })
                  }
                >
                  Clever Lab Search
                </CCButton>
              </Grid>
              <Grid item className={classes.item}>
                <Grid
                  className={classes.container__filter}
                  container
                  justify="space-between"
                >
                  <Grid item>
                    <CCTypography variant={"h4"} component={"span"}>
                      Change status to
                    </CCTypography>

                    <CCButton
                      variant="contained"
                      color="primary"
                      startIcon={<HappyIcon />}
                      className={classes.ml8}
                      onClick={() =>
                        changeCustomerStatus(
                          CUSTOMER_STATUS_TYPES.IN_USE.value
                        )
                      }
                    >
                      In Use
                    </CCButton>

                    <CCButton
                      variant="contained"
                      color="sub"
                      startIcon={<PauseIcon />}
                      className={classes.ml8}
                      onClick={() =>
                        changeCustomerStatus(
                          CUSTOMER_STATUS_TYPES.DISCONTINUED.value
                        )
                      }
                    >
                      Discontinued
                    </CCButton>

                    <CCButton
                      variant="contained"
                      color="error"
                      startIcon={<BadIcon />}
                      className={classes.ml8}
                      onClick={() => {
                        const selectedCustomers = filterSelectedCustomer();
                        if (!selectedCustomers.length) return;

                        dispatchState({
                          type: "setWithDrawModal",
                          target: true
                        });
                      }}
                    >
                      Withdraw
                    </CCButton>
                  </Grid>
                  <Grid item>
                    <Grid container justify="flex-end" alignItems="center">
                      <Grid item>
                        <div className={classes.toolbar__divider}></div>
                      </Grid>
                      <CustomerFilter
                        filter={filter}
                        onSearch={handleOnSearch}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.item__table} item>
                <CVTable
                  heads={heads}
                  contents={datLabList && datLabList.labList.items}
                  classes={{ table__body: classes.table__body }}
                  className={classes.table}
                />
              </Grid>
            </Grid>
          </CCDialogContent>
        </Box>

        {withdrawModalVisible && (
          <WithdrawModal
            open={withdrawModalVisible}
            onClose={() =>
              dispatchState({
                type: "setWithDrawModal",
                target: false
              })
            }
            onDisagree={() =>
              dispatchState({
                type: "setWithDrawModal",
                target: false
              })
            }
            onAgree={withdrawType => {
              let content = "Successfully withdrawn.";

              if (withdrawType === WITHDRAW_TYPES.AFTER_ONE_MONTH.value) {
                content = "Reserved for withdraw after 1 month";

                changeCustomerStatus(
                  CUSTOMER_STATUS_TYPES.WITH_DRAW_AFTER_ONE_MONTH.value,
                  withdrawType
                )
              }

              if (withdrawType === WITHDRAW_TYPES.NOW.value) {
                changeCustomerStatus(
                  CUSTOMER_STATUS_TYPES.WITHDRAW.value,
                  withdrawType
                );
              }

              dispatchState({
                type: "setWithDrawModal",
                target: false
              });
              dispatchState({
                type: "setSuccessWithdrawDialog",
                target: content
              });
            }}
          />
        )}

        {!!successWithdrawDialogContent && (
          <CCDialogSimple
            open={!!successWithdrawDialogContent}
            contents={
              <CCTypography variant="body1" color="error">
                {successWithdrawDialogContent}
              </CCTypography>
            }
            endActions={
              <CCButton
                variant="contained"
                color="error"
                onClick={() => {
                  dispatchState({
                    type: "setSuccessWithdrawDialog",
                    target: ""
                  });
                }}
              >
                Yes
              </CCButton>
            }
            onClose={() =>
              dispatchState({
                type: "setSuccessWithdrawDialog",
                target: ""
              })
            }
          />
        )}

        {labSearchModalVisible && (
          <LabSearchModal
            open={labSearchModalVisible}
            onClose={() =>
              dispatchState({
                type: "setLabSearchModal",
                target: false
              })
            }
          />
        )}
      </Box>
    </>
  );
};

export default CustomerManagement;
