import { useState, useEffect } from "react";
import moment from "moment";

export const formatUnixInSecToYYYYMMDD = date => {
  if (!date) return date;

  return moment(date * 1000).format("YYYY/MM/DD");
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};
