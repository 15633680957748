import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import MuiPhoneNumber from "material-ui-phone-number";
import countryData from "material-ui-phone-number/src/country_data";
import React, { useCallback } from "react";
import { LocalizeVariable } from "utils/env";
import { useIntl } from "utils/language";

const useStyles = makeStyles(
  () => ({
    root: {
      height: 36,
    },
    input__ellipsis: { textOverflow: "ellipsis", overflow: "hidden" },
    notchedOutline: {
      //  borderColor: 'rgba(0, 0, 0, 0.38) !important'
    },
    marginDense: {
      fontSize: props => `${props.labelFontSize}px !important`
    },
    input: {
      fontSize: '14px !important'
    },
    cover: {
      position: 'relative',
      display: 'inline'
    },
    disabled_click_flag: {
      position: 'absolute',
      zIndex: 1,
      top: 8,
      left: 18,
      width: 40, height: 40
    },
  }),
  { name: "PhoneNumberRegionField" }
);

const AllCountries = countryData.allCountries;
const PhoneNumberRegionField = props => {
  const intl = useIntl();
  const classes = useStyles(props);
  const {
    label = intl.formatMessage({ id: "phoneNumber" }),
    onChange,
    required,
    error,
    fullWidth,
    permission,
    value = "+",
    control,
    name,
    InputLabelProps,
    labelLegendSize,
    labelFontSize,
    errorOnChange,
    InputProps,
    classesCover,
    disabledClickFlag,
    ...others
  } = props;
  const isValid = useCallback(
    inputNumber => {
      return Boolean(inputNumber)
        ? AllCountries.some(country =>
          inputNumber.startsWith("+" + country.dialCode)
        )
        : !Boolean(required);
    },
    [required]
  );
  // const getCountry = useCallback(code => {
  //   let index = AllCountries.findIndex(country => {
  //     return country?.iso2 === code
  //   });
  //   return index >= 0 ? AllCountries[index] : null
  // }, []);
  // console.log("AllCountries", AllCountries);
  return (
    <Grid className={clsx(classes.cover, classesCover)}>
      <MuiPhoneNumber
        error={error}
        label={label}
        required={required}
        placeholder={"Input Phone Number"}
        countryCallingCodeEditable={false}
        initialValueFormat="national"
        defaultCountry={LocalizeVariable.countryPhoneCode}
        variant="outlined"
        margin="dense"
        InputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
            notchedOutline: classes.notchedOutline
          },
          ...InputProps
        }}
        value={
          isValid(value)
            ? value
            : value.includes("+")
              ? `${value ? value : ""}`
              : `+${value ? value : ""}`
        }
        onChange={e => {
          onChange && onChange({ target: { value: e } });
        }}
        countryCodeEditable={false}
        {...others}
      />
      {disabledClickFlag && <div className={classes.disabled_click_flag} />}
    </Grid>
  );
};
PhoneNumberRegionField.defaultProps = {
  labelLegendSize: 0.75,
  labelFontSize: localStorage.getItem("lang") === "ru" ? 12 : 14
};
export default PhoneNumberRegionField;
