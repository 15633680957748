import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import { CCTypography } from "styles/components";
import { MenunextIcon, CommitmentIcon } from "styles/icons";
import { getObject } from "utils/s3";
import LabDetailModal from "../LabDetailModal";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: 12,
    backgroundColor: "#eceff1",
    cursor: "pointer",
    "&:nth-child(2n)": {
      backgroundColor: theme.palette.common.white
    }
  },
  container: {
    display: "flex"
  },
  avatar: {
    width: 48,
    height: 48,
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: 8
  },
  no_avatar: {
    border: `1px solid ${theme.palette.border.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& svg": {
      color: theme.palette.border
    }
  },
  desc: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  specialties: {
    color: theme.palette.grey[500]
  },
  name: {
    fontWeight: 700
  },
  ml8: {
    marginLeft: 8
  }
}));

const LAB_ID = "ewoosoftvn";

const LabRow = props => {
  const { avatar, labName, specialties, statusMessage, labId } = props;
  const classes = useStyles();

  const [image, setImage] = useState("");
  const [labDetailModalVisible, setLabDetailModalVisible] = useState(false);

  useEffect(() => {
    let func = () => {
      getObject(avatar?.target, labId, avatar?.id).then(res => {
        setImage(res);
      });
    };
    func();
  }, [avatar]);

  return (
    <>
      <Box
        className={classes.root}
        onClick={() => setLabDetailModalVisible(true)}
      >
        <Box className={classes.container}>
          {avatar ? (
            <img src={image} alt={avatar.name} className={classes.avatar} />
          ) : (
            <Box className={clsx(classes.avatar, classes.no_avatar)}>
              <CommitmentIcon />
            </Box>
          )}

          <Box className={classes.desc}>
            <Box>
              <CCTypography
                variant="body1"
                component="span"
                className={classes.name}
              >
                {labName}
              </CCTypography>
              <CCTypography
                variant="body1"
                component="span"
                className={clsx(classes.ml8, classes.specialties)}
              >
                {specialties}
              </CCTypography>
            </Box>
            <CCTypography>{statusMessage}</CCTypography>
          </Box>
        </Box>

        <MenunextIcon />
      </Box>
      {labDetailModalVisible && (
        <LabDetailModal
          open={labDetailModalVisible}
          labId={labId}
          onClose={() => setLabDetailModalVisible(false)}
        />
      )}
    </>
  );
};

export default LabRow;
