import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { DatePicker, Header, TimePicker } from "./component";

const useStyle = makeStyles(
  theme => ({
    root: {
      // width: 620,
      // maxWidth: 620,
      // borderRadius: 8
      // boxSizing: "content-box"
    },
    dialogTitle: {
      padding: 0,
      // borderTopLeftRadius: 8,
      // borderTopRightRadius: 8
      overflow: "hidden",
      backgroundColor: "#0277bd"
    },
    dialogContent: {
      padding: 0
    },
    dialogActions: {
      backgroundColor: "#ffffff",
      minHeight: 60
    },
    paper: {
      borderRadius: 8,
      backgroundColor: "#0277bd",
      width: 620,
      maxWidth: 620,
      minWidth: 620
    },
    button: {
      width: 57,
      height: 36,
      borderRadius: 4,
      marginRight: 8
    },
    confirm: { color: theme.palette.primary.main },
    cancel: { color: "rgba( 255 255 255, 0.01)" },
    now: { color: theme.palette.secondary.main }
  }),
  { name: "CCFlattenDateTimePicker" }
);

const CCFlattenDateTimePicker = props => {
  const {
    open,
    value,
    components,
    onChange,
    onClose,
    step,
    stepperHours,
    stepperMinutes,
    disableMeridiem,
    ...others
  } = props;
  const classes = useStyle();
  const [last, setLast] = useState(value);
  useEffect(() => {
    setLast(value);
  }, [value]);
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      className={clsx(classes.root)}
      PaperProps={{ className: clsx(classes.paper) }}
      {...others}
    >
      <DialogTitle
        className={clsx(classes.dialogTitle)}
        disableTypography={true}
      >
        <Header value={last} type={"dateTime"} />
      </DialogTitle>
      <DialogContent className={clsx(classes.dialogContent)}>
        <Grid container>
          <Grid item>
            <DatePicker
              value={last}
              components={components}
              onChange={e => {
                setLast(e);
              }}
            />
          </Grid>
          <Grid item>
            <TimePicker
              value={last}
              step={step}
              onChange={e => {
                setLast(e);
              }}
              stepperHours={stepperHours}
              stepperMinutes={stepperMinutes}
              disableMeridiem={disableMeridiem}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={clsx(classes.dialogActions)}>
        <Grid container justify={"space-between"} alignItems={"center"}>
          <Grid item>
            <Button
              className={clsx(classes.button)}
              onClick={() => {
                let _minutes =
                  Math.round(parseInt(moment().minutes()) / step) * step;
                setLast(moment().minutes(_minutes));
              }}
            >
              <Typography variant={"h5"} className={clsx(classes.now)}>
                {intl.formatMessage({ id: "ccCurrent" })}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button className={clsx(classes.button)} onClick={() => onClose()}>
              <Typography variant={"h5"} className={clsx(classes.cancel)}>
                {intl.formatMessage({ id: "ccCancel" })}
              </Typography>
            </Button>
            <Button
              className={clsx(classes.button)}
              onClick={() => onChange(last)}
            >
              <Typography variant={"h5"} className={clsx(classes.confirm)}>
                {intl.formatMessage({ id: "ccCheck" })}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

CCFlattenDateTimePicker.propTypes = {
  /**
   * Open Dialog
   */
  open: PropTypes.bool.isRequired,
  /**
   * Picker Value, Only Moment Object
   */
  value: PropTypes.instanceOf(moment).isRequired,
  /**
   * Component Overlap
   */
  components: PropTypes.shape({
    DayItem: PropTypes.elementType
  }),
  /**
   * Callback when press confirm button
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Callback when close dialog
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Minute step
   */
  step: PropTypes.number,
  stepperHours: PropTypes.bool,
  stepperMinutes: PropTypes.bool,
  disableMeridiem: PropTypes.bool
};
CCFlattenDateTimePicker.defaultProps = {
  step: 10
};

export default CCFlattenDateTimePicker;
