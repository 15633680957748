import React, { useContext, useMemo } from 'react';
import { ApolloProvider as Provider } from '@apollo/react-hooks';
import { InMemoryCache } from "@apollo/client/cache";
import { ApolloClient } from "@apollo/client";
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { AppContext } from 'context/AppContext';

const ApolloProvider = (props) => {
  const { dispatchApp } = useContext(AppContext);
  const client = useMemo(() => {
    return new ApolloClient({
      connectToDevTools: true,
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            var tokenErr = false;

            graphQLErrors.forEach(({ message, locations, path, extensions }) => {
              console.warn(
                `GraphQLError: Message: ${message}, Location: ${locations}, Path: ${path}`,
              );
              if (extensions && extensions.code && extensions.code.match('AUTH01')) tokenErr = true;
            });

            // alert("expried session.");
            if (tokenErr) {
              sessionStorage.setItem('token', '');
              dispatchApp({ type: 'token', target: '' });
            }
          }
          if (networkError) {
            console.warn(`NetworkError: ${networkError}`);
          }
        }),
        new ApolloLink((operation, forward) => {
          const token = sessionStorage.getItem('token');
          const header = token ? `Bearer ${token}` : null;
          operation.setContext({
            headers: {
              authorization: header,
            },
          });

          if (operation.variables) {
            const omitTypename = (key, value) =>
              key === '__typename' ? undefined : value;
            operation.variables = JSON.parse(
              JSON.stringify(operation.variables),
              omitTypename,
            );
          }

          if (localStorage.getItem('debugApollo') === 'true') {
            console.info(operation);
            return forward(operation).map((data) => {
              console.info(data);
              return data;
            });
          } else {
            return forward(operation);
          }
        }),
        new createUploadLink({
          uri: process.env.REACT_APP_CF_GRAPHQL_URL,
          //uri: "http://localhost:8001/graphql",
          headers: {
            'keep-alive': 'true',
          },
        }),
      ]),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
        },
        watchQuery: {
          fetchPolicy: 'network-only',
        },
      },
    });
  }, [dispatchApp]);

  return <Provider client={client}>{props.children}</Provider>;
};
export default ApolloProvider;
