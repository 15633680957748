import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { DialogPermissionWarn } from "components";
import { CCTypography } from "styles/components";

const useStyles = makeStyles(theme => ({
  root: {
    // width: "fit-content",
    position: "relative",
    borderRadius: 50,
    display: "flex",
    width: "fit-content",
    height: "fit-content",
    minHeight: 24,
    color: props => {
      if (props.color === "white") return "white";
      return theme.palette.disabled;
    },
    alignItems: "center",
    padding: props => (props.margin === "normal" ? "4px 12px" : 2),
    backgroundColor: props => {
      if (props.background) return props.background;
      return theme.palette.inactive;
    },
    "&:hover $tooltip": {
      visibility: "visible"
    }
  },
  start__icon: {
    // height: 24,
    display: "flex"
    // marginRight: 4
  },
  content: {
    margin: "0 4px",
    maxWidth: 180,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  end__icon: {
    // height: 24,
    display: "flex"
    // marginLeft: 4
  },
  text: {
    color: "inherit"
  },
  tooltip: {
    position: "absolute",
    visibility: "hidden",
    top: 25,
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    borderRadius: 8,
    padding: 8,
    width: "max-content"
  }
}));

const Chips = props => {
  const {
    className,
    children,
    startIcon,
    endIcon,
    classes: userClasses = {},
    readOnly = false,
    onClick = () => {},
    tooltip,
    permission
  } = props;
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);

  const handleClick = e => {
    if (readOnly) return;
    if (true) onClick(e);
    else setOpen(true);
  };

  return (
    <>
      <div
        className={clsx(className, classes.root, userClasses.root)}
        // onClick={isPermission ? onClick : handleClick}
        onClick={handleClick}
      >
        <div className={clsx(classes.start__icon, userClasses.start__icon)}>
          {startIcon && startIcon}
        </div>
        {children && (
          <div className={clsx(classes.content, userClasses.content)}>
            <CCTypography className={classes.text}>{children}</CCTypography>
          </div>
        )}
        <div className={clsx(classes.end__icon, userClasses.end__icon)}>
          {endIcon && endIcon}
        </div>
        {tooltip && <div className={clsx(classes.tooltip)}>{tooltip}</div>}
      </div>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};
export default Chips;
