import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CCAddressSearchDialog } from "styles/src/components/CCAddressField/component";
import clsx from "clsx";
import { CVTextField } from "components";

const useStyles = makeStyles(() => ({
  left__input: { width: "630px" },
  inpuptMargin: {
    margin: "8px 0"
  }
}));

const defaultValue = {
  address: "",
  coordinate: { latitude: 0, longitude: 0 },
  placeId: "",
  detailAddress: ""
};
const CVGoogleMapAddress = props => {
  const { value, onChange, leftError, leftRequired, leftProps, rightProps } =
    props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const [location, setLocation] = useState(value || defaultValue);

  useEffect(() => {
    setLocation(value);
  }, [value]);

  return (
    <Grid container direction="column">
      <CVTextField
        label={"Address 1"}
        endIcon={<SearchIcon />}
        onClickIcon={e => setOpenDialog(true)}
        variant={"outlined"}
        margin={"dense"}
        value={location?.address}
        className={clsx(classes.left__input, classes.inpuptMargin)}
        required={leftRequired}
        error={leftError}
        InputProps={{ readOnly: true }}
        {...leftProps}
      />
      <CVTextField
        label={"Address 2"}
        variant={"outlined"}
        margin={"dense"}
        className={clsx(classes.left__input, classes.inpuptMargin)}
        value={location?.detailAddress}
        onChange={e => {
          setLocation({
            ...value,
            detailAddress: e.target.value
          });
          onChange({
            ...value,
            detailAddress: e.target.value
          });
        }}
        {...rightProps}
      />
      <CCAddressSearchDialog
        apiKey={"AIzaSyDc6dr50xs2ZDRGoM7EHhRkr5irkCMF9Ls"}
        value={location}
        open={openDialog}
        onChange={e => {
          onChange && onChange(e);
        }}
        onClose={() => {
          setOpenDialog(false);
        }}
      />
    </Grid>
  );
};

export default CVGoogleMapAddress;
