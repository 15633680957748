import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { getWeek } from "../../../../index";
// import SmalldownIcon from "styles/src/themes/common/icons/SmalldownIcon";
// import SmallupIcon from "styles/src/themes/common/icons/SmallupIcon";

const useStyle = makeStyles(
  theme => ({
    root: {},
    end__adornment: {
      flexDirection: "column",
      margin: 0,
      height: "auto",
      "&:hover": {
        cursor: "pointer"
      }
    },
    up: {
      marginBottom: "5px",
      color: theme.palette.icon
    },
    down: {
      color: theme.palette.icon
    },
    monthInput: {
      width: 140,
      height: 32
    },
    yearInput: {
      width: 80,
      height: 32
    }
  }),
  { name: "CCFlattenDateTimePicker-DatePicker-Header" }
);

function* yearSelectItem() {
  for (let x = 1900; x < 2031; x++) {
    yield (
      <MenuItem key={x} value={x}>
        {x}
      </MenuItem>
    );
  }
}

const Header = props => {
  const classes = useStyle();
  const { value, onChange, mode } = props;
  return (
    <>
      {/*<TextField*/}
      {/*  variant={"outlined"}*/}
      {/*  margin={"dense"}*/}
      {/*  value={*/}
      {/*    mode === "day" ? value.format("YYYY") : value.begin.format("YYYY")*/}
      {/*  }*/}
      {/*  InputProps={{*/}
      {/*    inputProps: {*/}
      {/*      className: clsx(classes.yearInput),*/}
      {/*      "aria-readonly": "true",*/}
      {/*      readOnly: true*/}
      {/*    },*/}
      {/*    endAdornment: (*/}
      {/*      <InputAdornment position="end" className={classes.end__adornment}>*/}
      {/*        <SmallupIcon*/}
      {/*          className={classes.up}*/}
      {/*          onClick={event => {*/}
      {/*            if (mode === "day") {*/}
      {/*              onChange && onChange(value.clone().add(1, "year"));*/}
      {/*            } else {*/}
      {/*              let _moveYear = value.begin.clone().add(1, "year");*/}
      {/*              onChange && onChange(getWeek(_moveYear));*/}
      {/*            }*/}
      {/*          }}*/}
      {/*        />*/}
      {/*        <SmalldownIcon*/}
      {/*          className={classes.down}*/}
      {/*          onClick={event => {*/}
      {/*            if (mode === "day") {*/}
      {/*              onChange && onChange(value.clone().subtract(1, "year"));*/}
      {/*            } else {*/}
      {/*              let _moveYear = value.begin.clone().subtract(1, "year");*/}
      {/*              onChange && onChange(getWeek(_moveYear));*/}
      {/*            }*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </InputAdornment>*/}
      {/*    )*/}
      {/*  }}*/}
      {/*/>*/}
      <TextField
        variant={"outlined"}
        margin={"dense"}
        value={
          mode === "day" ? value.format("YYYY") : value.begin.format("YYYY")
        }
        className={clsx(classes.yearInput)}
        select
        onChange={e => {
          console.log(value);
          if (mode === "day") {
            onChange && onChange(value.clone().year(e.target.value));
          } else {
            let _moveMonth = value.clone().year(e.target.value);
            onChange && onChange(getWeek(_moveMonth));
          }
        }}
      >
        {[...yearSelectItem()]}
      </TextField>
      <TextField
        variant={"outlined"}
        value={mode === "day" ? value.format("M") : value.begin.format("M")}
        select
        margin={"dense"}
        className={clsx(classes.monthInput)}
        onChange={event => {
          if (mode === "day") {
            onChange && onChange(value.clone().month(event.target.value - 1));
          } else {
            let _moveMonth = value.begin.clone().month(event.target.value - 1);
            onChange && onChange(getWeek(_moveMonth));
          }
        }}
      >
        {moment
          .localeData()
          .months()
          .map((item, index) => {
            return (
              <MenuItem value={index + 1} key={index + item}>
                {item}
              </MenuItem>
            );
          })}
      </TextField>
    </>
  );
};

Header.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.shape({
      begin: PropTypes.instanceOf(moment),
      end: PropTypes.instanceOf(moment)
    })
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["day", "week"])
};
export default Header;
