import React from "react";

const AnnounceOffIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0H22V22H0z" />
      <path
        fill="currentColor"
        d="M4.12 1.75l3.247 5.625h.001l4.502 7.798 2.75 4.764-1.731 1-3.227-5.588-2.792-1.675h-1v4h-2v-4h-1c-1.05 0-1.918-.82-1.994-1.851l-.006-.149v-2c0-1.05.82-1.918 1.852-1.994l.148-.006h2.36L2.39 2.75l1.732-1zm11.95 12.924c.96.72 2.21 1.66 3.2 2.4-.4.53-.8 1.07-1.2 1.6-.99-.74-2.24-1.68-3.2-2.39.4-.53.8-1.07 1.2-1.61zm-3.2-7.35c.92.82 1.5 2.02 1.5 3.35 0 1.122-.413 2.152-1.094 2.933l-.406-.7V7.323zm8 2.35v2h-4v-2h4zm-9-5v6.5L9.083 6.346l2.787-1.672zm6.2-2c.4.53.8 1.07 1.2 1.6-.848.643-1.888 1.418-2.772 2.08l-.428.32-1.2-1.6c.96-.72 2.21-1.66 3.2-2.4z"
        transform="translate(10.870259, 11.343780) rotate(-15.000000) translate(-10.870259, -11.343780)"
      />
    </g>
  </svg>
);

export default AnnounceOffIcon;
