import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import { CCTypography } from "styles/components";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    padding: "32px 16px"
  },
  width230: {
    width: '250px'
  },
  statistic__item: {
    width: "200px",
    borderLeft: "2px solid",
    padding: "14px 16px",
    "& span": {
      color: theme.palette.sub.main
    }
  },
  blue: {
    borderColor: theme.palette.secondary.main,
    "& h3": {
      color: theme.palette.secondary.main
    }
  },
  green: {
    borderColor: theme.palette.primary.main,
    "& h3": {
      color: theme.palette.primary.main
    }
  },
  red: {
    borderColor: theme.palette.error.main,
    "& h3": {
      color: theme.palette.error.main
    }
  },
  gray: {
    color: theme.palette.border,
    "& h3": {
      color: 'gray'
    }
  }
}));

const StatisticBox = props => {
  const classes = useStyles();
  const { statistics } = props;

  return (
    <Box className={classes.root}>
      {statistics.map((item, index) => (
        <Box
          key={item.title}
          className={
            clsx( 
              {[classes.width230]: index === 4 || index === 5}, 
              // {[classes.width230]: item.title === "Temporary Suspension" },
              classes.statistic__item, 
              classes[item.color], 
              classes[item.width])
          }
        >
        {console.log("item", item)}

          <CCTypography variant="body1" component="span">
            {item.title}
          </CCTypography>
          <CCTypography variant="h3">{item.data}</CCTypography>
        </Box>
      ))}
    </Box>
  );
};

export default StatisticBox;
