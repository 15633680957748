import React, { useReducer } from 'react';
import moment from 'moment';
import { basicTheme, blueTheme } from 'styles/themes';
import 'styles/src/themes/common/font-notosans.css';
import { theme as localTheme } from 'theme';

const initialState = {
  language:
    localStorage.getItem('lang') === 'undefined'
      ? localStorage.getItem('lang')
      : 'en',
  refresh: 0,
  debug: {
    log: true,
    warn: true,
    error: true,
    info: true,
  },
  theme: { ...basicTheme, ...localTheme },
  route: { path: '', props: null },
  permission: null,
  needNotify: false,
  bg: `url(bg/login${(moment().unix() % 5) + 1}.png)`,
};

const fontChange = (targetLanguage, targetTheme) => {
  const fontFamily = 'Noto Sans';

  const newTypography = {
    ...targetTheme.typography,
    fontFamily
  };

  const newTheme = {
    ...basicTheme,
    typography: newTypography,
    ...localTheme,
  };

  return newTheme;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'language':
      localStorage.setItem('lang', action.target);
      const newTheme = fontChange(action.target, state.theme);
      return { ...state, language: action.target, theme: newTheme };
    case 'refresh':
      return { ...state, refresh: state.refresh + 1 };
    case 'debug':
      return { ...state, debug: action.target };
    case 'theme':
      if (action.target === basicTheme.themeName) {
        const newTheme = fontChange(state.language, basicTheme);
        return { ...state, theme: newTheme };
      } else if (action.target === blueTheme.themeName) {
        const newTheme = fontChange(state.language, blueTheme);
        return { ...state, theme: newTheme };
      }
      return state;
    case 'token':
    case 'route':
    case 'permission':
    case 'needNotify':
      return { ...state, [action.type]: action.target };
    default:
      return state;
  }
};

const AppContext = React.createContext();
const AppContextConsumer = AppContext.Consumer;

const AppContextProvider = (props) => {
  const theme = fontChange(initialState.language, initialState.theme);
  const [appContext, dispatchApp] = useReducer(reducer, {
    ...initialState,
    theme: theme,
  });
  const value = { appContext, dispatchApp };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

export default AppContextProvider;
export { AppContext, AppContextConsumer };
