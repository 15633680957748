import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import propTypes from "prop-types";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import CCTextField from "styles/src/components/CCTextField";
import { TimeIcon } from "styles/src/themes/common/icons";
import CCFlattenTimePicker from "./CCFlattenTimePicker";

const useStyle = makeStyles(theme => ({
  root: {},
  dateButton: {
    color: theme.palette.icon
  },
  disabled: {
    color: theme.palette.disabled
  }
}));
const CCFlattenTimeField = props => {
  const intl = useIntl();
  const {
    value = moment(),
    onChange,
    label = intl.formatMessage({ id: "ccTime" }),
    disabled,
    step,
    onClickIcon,
    stepperHours,
    stepperMinutes,
    disableMeridiem,
    ...others
  } = props;
  const [pickerOpen, setPickerOpen] = useState(false);
  // const [momentValue, setMomentValue] = useState(value);
  // const [textValue, setTextValue] = useState(value.format("hh:mm A"));
  const classes = useStyle();
  // useEffect(() => {
  //   setTextValue(value.format("hh:mm A"));
  // }, [value]);
  // useEffect(() => {
  //   if (value.format("hh:mm A") !== momentValue.format("hh:mm A"))
  //     setMomentValue(value);
  // }, [value, momentValue]);
  // console.log("value", value);
  // console.log("momentValue", momentValue);
  return (
    <>
      <CCFlattenTimePicker
        step={step}
        open={pickerOpen}
        value={value}
        stepperHours={stepperHours}
        stepperMinutes={stepperMinutes}
        disableMeridiem={disableMeridiem}
        onChange={e => {
          onChange(
            value.clone().hours(e.hours()).minute(e.minute()).second(e.second())
          );
          setPickerOpen(false);
        }}
        onClose={() => {
          // if (value.format("hh:mm A") !== momentValue.format("hh:mm A"))
          // setMomentValue(value);
          setPickerOpen(false);
        }}
      />
      <CCTextField
        {...others}
        disabled={disabled}
        variant={"outlined"}
        margin={"dense"}
        label={label}
        value={moment(value.toDate()).format("LT")}
        // onChange={e => setTextValue(e.target.value)}
        InputProps={{
          ...others.InputProps,
          readOnly: true
        }}
        onClickIcon={() => {
          if (disabled) return;
          if (onClickIcon) {
            onClickIcon();
            return;
          }
          setPickerOpen(true);
        }}
        // onBlur={() => setTextValue(momentValue.format("hh:mm A"))}
        endIcon={
          <TimeIcon
            className={clsx(classes.dateButton, {
              [classes.disabled]: disabled
            })}
          />
        }
      />
    </>
  );
};
CCFlattenTimeField.propTypes = {
  value: propTypes.instanceOf(moment).isRequired,
  onChange: propTypes.func.isRequired,
  label: propTypes.string,
  stepperHours: propTypes.bool,
  stepperMinutes: propTypes.bool,
  disableMeridiem: propTypes.bool
  // readOnly: propTypes.bool
};
export default CCFlattenTimeField;
