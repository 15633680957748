import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { CCButton, CCDialogSimple, CCTypography } from 'styles/components';
import { PasswordChangeDialog } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {},
  loading: { textAlign: 'center' },
}));

const DialogPasswordExpire = (props) => {
  const { open, onClose } = props;
  const classes = useStyles();

  const [remainingDate, setRemainingDate] = useState(0);
  const [expire, setExpire] = useState(false);
  const [change, setChange] = useState(false);
  const loading = false;

  return (
    <>
      <CCDialogSimple
        open={open}
        isHeader={false}
        contents={
          loading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <CCTypography>
              {expire
                ? `비밀번호가 만료되었습니다.`
                : `비밀번호 만료일이 ${remainingDate}일 남았습니다.`}
            </CCTypography>
          )
        }
        endActions={
          !loading ? (
            <>
              <CCButton
                // className={classes.disagree}
                color='secondary'
                onClick={() => {
                  setChange(true);
                  onClose();
                }}
              >
                비밀번호 변경
              </CCButton>
              {!expire && (
                <CCButton
                  // className={classes.disagree}
                  color='secondary'
                  onClick={() => {
                    onClose();
                  }}
                >
                  확인
                </CCButton>
              )}
            </>
          ) : (
            <></>
          )
        }
      />
      <PasswordChangeDialog
        open={change}
        onClose={() => {
          setChange(false);
        }}
        forceOpen={expire}
      />
    </>
  );
};

export default DialogPasswordExpire;
