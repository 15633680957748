const ENTRY_LIMIT = {
  NAME: 50,
  MEDIUM_SIZE: 200,
  NUMBER_FIELD: 20,
  BIG_FIELD: 500,
  PRICE: 15
};
const LIMIT_PAGINATION = 12;

const DEFAULT_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

export { ENTRY_LIMIT, DEFAULT_DATE_TIME_FORMAT, DEFAULT_DATE_FORMAT, LIMIT_PAGINATION };
