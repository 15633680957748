import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { CCButton, CCTextField } from "styles/components";
import { SearchIcon } from "styles/icons";
import { MenuItem } from "@material-ui/core";
import { CCSearchTextField } from "components";
import { REGISTRATION_FILTER_STATUS } from "types/registrationManage";

import { makeList } from "types";

const useStyles = makeStyles(theme => ({
  input: { backgroundColor: theme.palette.common.white },
  searchtext__field: { width: 300, marginLeft: 32 },
  ml8: {
    marginLeft: 8
  },
  selection: {
    width: 142,
    backgroundColor: theme.palette.common.white
  }
}));

const RegistrationFilter = props => {
  const { filter, onSearch } = props;

  // console.log("RegistrationFilter.props", props)
  const classes = useStyles();
  const [value, setValue] = useState(filter);

  const inputRef = useRef();

  const customerStatusList = makeList(REGISTRATION_FILTER_STATUS);

  useEffect(() => {
    setValue(filter);
  }, [filter]);

  const handleOnSearch = () => {
    
    onSearch({ ...value, searchText: inputRef.current.value });
  };

  return (
    <>
      {/* <DateQueryFilter value={value} onChange={val => setValue(val)} /> */}

      <CCSearchTextField
        // placeholder={"Lab, Manager, Cell No., Tel No."}
        className={classes.searchtext__field}
        variant="outlined"
        label={"Lab ID, Name, Tel No."}
        margin="dense"
        inputRef={inputRef}
        // onChange={
        //   console.log('123123', inputRef.current.value)
        // //   // handleOnSearch
        // //   setValue({ ...value, searchText: inputRef?.current?.value })
        // }
        onKeyPress={e => {
          if (e.key === "Enter") {
            // handleSearchTextClick();
            // onSearch({...value, searchText: inputRef.current.value});
            handleOnSearch();
          }
        }}
        onChange={(e) => {
          // onFilterChange({ ...value, searchText: e })
          setValue(prevState => ({ ...prevState, searchText: e }));
        }}
        onClear={() => {
          inputRef.current.value = "";
          // setValue({ ...value, searchText: inputRef.current.value });
          handleOnSearch();
          // handleSearchTextClick();
        }}
      />

      {/* <CCSearchTextField
            className={classes.searchtext__field}
            variant="outlined"
            margin="dense"
            label="Lab, Manager, Cell No., Tel No."
            InputProps={{ classes: { root: classes.input } }}
            value={value.searchText}
            onIconClick={{}}
            onChange={e => {
              setValue({ ...value, searchText: e.target.value });
            }}
            onClear={() => {
              console.log("onClear");
              // inputRef.current.value = "";
              // handleSearchTextClick();
            }}
          /> */}

      <CCTextField
        value={value.status}
        label={"Registration Status"}
        select
        variant="outlined"
        margin="dense"
        className={classes.selection}
        onChange={e => setValue({ ...value, status: e.target.value })}
      >
        {customerStatusList.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </CCTextField>

      <CCButton
        variant="contained"
        color="secondary"
        startIcon={<SearchIcon />}
        className={classes.ml8}
        onClick={() => {
          onSearch && onSearch({ ...value });
        }}
      >
        Search
      </CCButton>
    </>
  );
};

export default RegistrationFilter;
