import React, { useReducer } from "react";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/styles";
import { Box, Checkbox, Grid } from "@material-ui/core";
import {
  CCButton,
  CCDialogContent,
  CCDialogTitle,
  CCTypography
} from "styles/components";
import { HappyIcon, BadIcon } from "styles/icons";
import { CVTable, TimerWithRefetch } from "components";
import { ApplicationFilter, StatisticBox } from "./components";

import { APPLICATION_STATUS_TYPES } from "types/application";
import {
  GET_LAB_APPLICATION_REQ_STATS,
  GET_LAB_APPLICATION_REQ_LIST,
  UPDATE_LAB_APPLICATION_REQ
} from "queries/application";

const LIMIT = 12;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.common.white
  },
  timer: {
    position: "absolute",
    zIndex: 1200,
    top: 18,
    right: 32,
    display: "flex"
  },
  timer__divider: {
    height: 36,
    borderLeft: "3px solid rgba(0, 0, 0, 0.16)",
    margin: "0 16px"
  },
  paper: { height: "100%", width: "100%" },
  content: { height: "calc(100% - 55px)", overflow: "hidden" },
  container: { height: "100%" },
  container__filter: {
    width: "100%",
    padding: "4px 12px",
    margin: "0px",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  item__table: {
    height: "calc(100% - 180px)"
  },
  table: {
    height: "100%",
    textAlign: "center",

    "& .MuiTypography-colorInherit": {
      color: theme.palette.input.main
    }
  },
  table__body: {
    backgroundColor: "rgba(207, 216, 220, 0.87)",
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    }
  },
  table__cell: {
    padding: "6px 4px"
  },
  toolbar__divider: {
    borderLeft: `1px solid ${theme.palette.border.main}`,
    height: 36,
    marginRight: 8
  },
  ml8: {
    marginLeft: 8
  },
  container__status: {
    display: "flex",
    borderTop: `1px solid ${theme.palette.border.main}`,
    padding: "28px 12px"
  }
}));

const initializer = {
  filter: {
    // start: moment().startOf("month").unix(),
    // end: moment().endOf("month").unix(),
    searchText: ""
  },
  checkedList: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case "filter":
      return {
        ...state,
        filter: action.target
      };
    case "setCheckedList":
      return {
        ...state,
        checkedList: action.target
      };
    case "reset":
      return { ...initializer };
    default:
      break;
  }

  return state;
};

const ApplicationManagement = () => {
  const classes = useStyles();

  const [state, dispatchState] = useReducer(reducer, initializer);
  const { filter, checkedList } = state;

  const { data: dataStatistics, refetch: refetchStatistics } = useQuery(
    GET_LAB_APPLICATION_REQ_STATS
  );
  const { data, loading, error, refetch: refetchApplicationReqList } = useQuery(
    GET_LAB_APPLICATION_REQ_LIST,
    {
      variables: {
        input: {
          limit: LIMIT
        }
      }
    }
  );
  const [updateLabApplicationRequest] = useMutation(
    UPDATE_LAB_APPLICATION_REQ,
    {
      onCompleted: () => {
        refetchStatistics();
        refetchApplicationReqList();
      }
    }
  );

  const statistics = [
    {
      title: "New Applications",
      color: "blue",
      data:
        dataStatistics &&
        dataStatistics.labApplicationRequestStatistics.totalNewApplication
    },
    {
      title: "Accepted Applications",
      color: "green",
      data:
        dataStatistics &&
        dataStatistics.labApplicationRequestStatistics.totalAcceptedApplication
    },
    {
      title: "Refused Applications",
      color: "red",
      data:
        dataStatistics &&
        dataStatistics.labApplicationRequestStatistics.totalRefusedApplication
    },
    {
      title: "Total",
      color: "gray",
      data:
        dataStatistics && dataStatistics.labApplicationRequestStatistics.total
    }
  ];

  const heads = [
    {
      width: 100,
      className: classes.table__cell,
      component: ({ rowData }) => {
        const checked = !!checkedList[rowData.id];

        return (
          <Checkbox
            checked={checked}
            style={{ padding: 0 }}
            disableRipple
            onChange={() => {
              if (checked) {
                dispatchState({
                  type: "setCheckedList",
                  target: { ...checkedList, [rowData.id]: false }
                });
              } else {
                dispatchState({
                  type: "setCheckedList",
                  target: { ...checkedList, [rowData.id]: true }
                });
              }
            }}
          />
        );
      }
    },
    {
      key: "createdAt",
      label: "Date",
      className: classes.table__cell,
      component: ({ cellData }) => {
        return (
          <CCTypography variant="body1">
            {moment.unix(cellData).format("YYYY/MM/DD")}
          </CCTypography>
        );
      }
    },
    {
      key: "labName",
      label: "Lab",
      className: classes.table__cell
    },
    {
      key: "managerName",
      label: "Manager",
      className: classes.table__cell,
      component: ({ cellData }) => {
        return <CCTypography variant="body1">{cellData}</CCTypography>;
      }
    },
    { key: "labId", label: "Lab ID", className: classes.table__cell },

    {
      key: "managerCellNumber",
      label: "Cell No.",
      className: classes.table__cell
    },
    { key: "labTelNumber", label: "Tel No.", className: classes.table__cell },
    { key: "email", label: "Email", className: classes.table__cell },
    {
      key: "address",
      label: "Address",
      className: classes.table__cell
    }
  ];

  const handleOnSearch = value => {
    console.log("handleOnSearch.value =>>>", value);
    dispatchState({
      type: "filter",
      target: value
    });
    refetchApplicationReqList({
      input: {
        limit: LIMIT,
        searchText: value.searchText,
        start: value.start,
        end: value.end
      }
    });
  };

  const changeApplicationStatus = (status = 0) => {
    const selectedApplications = Object.keys(checkedList).filter(
      key => checkedList[key]
    );
    if (!selectedApplications.length) return;

    updateLabApplicationRequest({
      variables: {
        input: {
          status: Number(status),
          ids: selectedApplications
        }
      }
    });
  };

  const refetchData = () => {
    dispatchState({
      type: "reset"
    });
    refetchStatistics();
    refetchApplicationReqList({
      input: {
        limit: LIMIT
      }
    });
  };

  if (error) return;

  if (loading) return <div></div>;

  return (
    <>
      <Box className={classes.timer}>
        <TimerWithRefetch refetch={refetchData} />
        <div className={classes.timer__divider}></div>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <CCDialogTitle>Application Management</CCDialogTitle>
          <CCDialogContent className={classes.content} noPadding>
            <Grid
              container
              direction={"column"}
              wrap={"nowrap"}
              className={classes.container}
            >
              <Grid item className={classes.container__status}>
                <StatisticBox statistics={statistics} />
              </Grid>
              <Grid item>
                <Grid
                  className={classes.container__filter}
                  container
                  justify="space-between"
                >
                  <Grid item>
                    <CCTypography variant={"h4"} component={"span"}>
                      Change status to
                    </CCTypography>

                    <CCButton
                      variant="contained"
                      color="primary"
                      startIcon={<HappyIcon />}
                      className={classes.ml8}
                      onClick={() =>
                        changeApplicationStatus(
                          APPLICATION_STATUS_TYPES.ACCEPTED.value
                        )
                      }
                    >
                      Accept
                    </CCButton>

                    <CCButton
                      variant="contained"
                      color="error"
                      startIcon={<BadIcon />}
                      className={classes.ml8}
                      onClick={() =>
                        changeApplicationStatus(
                          APPLICATION_STATUS_TYPES.REFUSED.value
                        )
                      }
                    >
                      Refuse
                    </CCButton>
                  </Grid>
                  <Grid item>
                    <Grid container justify="flex-end" alignItems="center">
                      <Grid item>
                        <div className={classes.toolbar__divider}></div>
                      </Grid>

                      <ApplicationFilter
                        filter={filter}
                        onSearch={handleOnSearch}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.item__table} item>
                <CVTable
                  heads={heads}
                  contents={
                    data.labApplicationRequestList &&
                    data.labApplicationRequestList.items
                  }
                  classes={{ table__body: classes.table__body }}
                  className={classes.table}
                />
              </Grid>
            </Grid>
          </CCDialogContent>
        </Box>
      </Box>
    </>
  );
};

export default ApplicationManagement;
