import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, Divider } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import {
  CCButton,
  CCDialogSimpleDel,
  CCDialogTitle,
  CCTextField,
  CCDialogInputError
} from "styles/components";
import AddAdminInformationModal from "./AddAdminInformationModal";
import { SearchIcon, AddIcon } from "styles/icons";
import { CVTable, CCSearchTextField } from "components";
import {
  GET_MANAGER_LIST,
  CREATE_MANAGER,
  UPDATE_MANAGER,
  DELETE_MANAGER
} from "queries/manager";
import { validPassword } from "utils/password";
import { validManageName } from "utils/validation";
import { enqueueToast } from "components/Toast";
import { useSnackbar } from "notistack";
import { circleOfConfusionPixelShader } from "@babylonjs/core/Shaders/circleOfConfusion.fragment";

const LIMIT = 12;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white
  },
  timer: {
    position: "absolute",
    zIndex: 1200,
    top: 18,
    right: 32,
    display: "flex"
  },
  timer__divider: {
    height: 36,
    borderLeft: "3px solid rgba(0, 0, 0, 0.16)",
    margin: "0 16px"
  },
  paper: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.default
  },
  content: { height: "calc(100% - 55px)", overflow: "hidden" },
  container: { height: "100%" },
  item__table: { height: "calc(100% - 205px)" },
  table: {
    height: "100%",
    textAlign: "center"
  },
  table__body: {
    // backgroundColor: "rgba(207, 216, 220, 0.87)",
    // "& > div:nth-child(old)": {
    //   background: theme.palette.common.white
    // }
    minHeight: 36
  },
  table__cell: {
    padding: "6px 4px"
  },
  toolbar__divider: {
    borderLeft: `1px solid ${theme.palette.border.main}`,
    height: 36,
    marginRight: 8
  },

  is_recommended: {
    color: "#ffab00"
  },
  divider: {
    height: 2
  },
  selection: {
    width: 350,
    backgroundColor: theme.palette.common.white
  },
  selectionText: {
    color: "red"
  },
  searchtext__field: { width: 320 },
  ml8: {
    marginLeft: 8
  },
  refresh__icon: {
    paddingTop: 4,
    position: "absolute",
    right: 30,
    cursor: "pointer"
  },
  container__filter: {
    width: "100%",
    padding: "0px 4px 12px 2px",
    margin: "0px",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  background_color_white: {
    backgroundColor: theme.palette.common.white
  }
}));

const AdminInformation = () => {
  const classes = useStyles();
  const heads = [
    {
      key: "name",
      width: "40%",
      label: "Name",
      className: classes.table__cell
    },
    {
      key: "managerId",
      width: "40%",
      label: "ID",
      className: classes.table__cell
    }
  ];

  const [managerLimit, setManagerLimit] = useState(LIMIT);
  const { data: managerList, refetch: getManagerList } = useQuery(
    GET_MANAGER_LIST,
    {
      variables: {
        input: {}
      },
      fetchPolicy: "network-only"
    }
  );

  const [keyword, setKeyword] = useState("");
  const totalPages = Math.ceil(managerList?.managerList?.total / managerLimit);
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openDeletedModal, setOpenDeletedModal] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [inputErr, setInputErr] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef();

  const [deleteManager] = useMutation(DELETE_MANAGER, {
    onCompleted: () => {
      refetchData();
    }
  });

  const [addManager] = useMutation(CREATE_MANAGER, {
    onCompleted: () => {
      refetchData();
      enqueueToast(enqueueSnackbar, "Account is successfully created.​");
    },
    onError: error => {
      setErrorDialog(true);
      const errorMessage = error.message;
      setInputErr("This ID is unavailable.");
    }
  });

  const [updateManager] = useMutation(UPDATE_MANAGER, {
    onCompleted: () => {
      refetchData();
      enqueueToast(enqueueSnackbar, "Account is successfully edited.");
    },
    onError: error => {
      setErrorDialog(true);
      const errorMessage = error.message;
      setInputErr(errorMessage);
    }
  });

  const handleOnChangePagination = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getManagerList({
      input: {
        searchText: inputRef.current.value,
        limit: managerLimit,
        page
      }
    });
  }, [page]);

  const refetchData = () => {
    getManagerList({
      input: {
        searchText: keyword,
        limit: managerLimit,
        page
      }
    })
  }

  const validation = value => {
    const { id, managerId, password, name } = value;

    if (!name || !managerId) {
      return "The input value is not valid.";
    }

    if (
      (!selectedItem && !password) ||
      (password && !validPassword(password))
    ) {
      return "Password must be over 8 characters and contain alphabet, numbers, special characters.";
    }

    if (!validManageName(managerId)) {
      return "Manager ID length must 4-20 characters, consisting lowercase letters and numbers​";
    }

    return null;
  };

  const handleOnSubmit = value => {
    // const { id, managerId, password, name } = value;
    if (validation(value)) {
      setErrorDialog(true);
      const errorMessage = validation(value);
      setInputErr(errorMessage);
    } else {
      if (selectedItem) {
        let input = { id: selectedItem.id };
        if (selectedItem.name !== value.name) {
          input.name = value.name;
        }
        if (value.password) {
          input.password = value.password;
        }
        if (input.name || input.password) {
          updateManager({
            variables: {
              input
            }
          })
        }
      } else {
        addManager({
          variables: {
            input: {
              ...value
            }
          }
        })
      }
      setSelectedItem(null);
      setOpenModal(false);
    }
  };

  const handleOnSearch = () => {
    getManagerList({
      input: {
        searchText: inputRef.current.value,
        limit: managerLimit
      }
    });
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <CCDialogTitle className={classes.background_color_white}>
            Administrator
          </CCDialogTitle>
          <Grid
            container
            className={classes.background_color_white}
            justify="space-between"
          >
            <Grid container justify="flex-start" alignItems="center" xs={9}>
              <CCSearchTextField
                // placeholder={"Search"}
                label="Search"
                className={classes.searchtext__field}
                variant="outlined"
                margin="dense"
                inputRef={inputRef}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    inputRef.current.value = e.target.value;
                    handleOnSearch()
                  }
                }}
                onChange={value => {
                  inputRef.current.value = value;
                }}
                onClear={() => {
                  inputRef.current.value = "";
                  handleOnSearch()
                }}
              />
              {/* <CCTextField
                className={classes.searchtext__field}
                variant="outlined"
                margin="dense"
                label="Search"
                InputProps={{ classes: { root: classes.input } }}
                value={keyword}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
                onKeyPress={(e) => {
                  if(e.key === 'Enter'){
                    setPage(1)
                    getManagerList({
                      input: {
                        searchText: keyword,
                        limit: managerLimit
                      }
                    });
                  }
                }}
              /> */}

              <CCButton
                variant="contained"
                color="secondary"
                startIcon={<SearchIcon />}
                className={classes.ml8}
                onClick={() => {
                  setPage(1);
                  getManagerList({
                    input: {
                      searchText: inputRef.current.value,
                      limit: managerLimit
                    }
                  });
                }}
              >
                Search
              </CCButton>

              <CCButton
                className={classes.refresh__icon}
                variant="contained"
                color="normal"
                startIcon={<AddIcon />}
                onClick={() => {
                  setSelectedItem(null);
                  setOpenModal(true);
                }}
              >
                Add
              </CCButton>
              {openModal && (
                <AddAdminInformationModal
                  open={openModal}
                  selectedItem={selectedItem}
                  onClose={() => {
                    setSelectedItem(null);
                    setOpenModal(false);
                  }}
                  onSave={handleOnSubmit}
                />
              )}
              {openDeletedModal && (
                <CCDialogSimpleDel
                  open={openDeletedModal}
                  onClose={() => {
                    setSelectedItem(null);
                    setOpenDeletedModal(false);
                  }}
                  onDisagree={() => {
                    setSelectedItem(null);
                    setOpenDeletedModal(false);
                  }}
                  onAgree={() => {
                    deleteManager({
                      variables: {
                        id: selectedItem.id
                      }
                    }).then(() => {
                      enqueueToast(
                        enqueueSnackbar,
                        "Account is successfully deleted.​"
                      );
                    });
                    setSelectedItem(null);
                    setOpenDeletedModal(false);
                  }}
                />
              )}
            </Grid>
          </Grid>

          <Divider className={classes.divider}></Divider>
          <Grid className={classes.background_color_white}>
            <Grid item>
              <CVTable
                heads={heads}
                contents={managerList?.managerList?.items}
                className={classes.table}
                onDelClick={e => {
                  setSelectedItem(e.rowData);
                  setOpenDeletedModal(true);
                }}
                onModClick={e => {
                  setSelectedItem(e.rowData);
                  setOpenModal(true);
                }}
              />
            </Grid>
            <Divider className={classes.divider}></Divider>
            <Grid container item justifyContent="center" direction="column">
              {managerList && (
                <Pagination
                  count={totalPages}
                  page={page}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  onChange={handleOnChangePagination}
                  showFirstButton
                  showLastButton
                />
              )}
            </Grid>
          </Grid>

          {errorDialog && (
            <CCDialogInputError
              open={errorDialog}
              title={inputErr}
              onClose={() => setErrorDialog(false)}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdminInformation;
