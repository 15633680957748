import React, { useState, useEffect } from "react";
import { CVTable } from "components";
import { makeStyles } from "@material-ui/styles";
import { CCButton, CCTextField } from "styles/components";
import { Grid, MenuItem, Button, Menu, TextField } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import {
  USER_STATUS_TYPES,
  DETAIL_REFERENCE_DATA,
  BASE_USER_STATUS
} from "types/userManage";
import { mergeArrayByKey } from "utils/array";
import { makeList } from "types";
import moment from "moment";
import { GET_NUMBER_USER_LAB } from "queries/customer";
import { DEFAULT_DATE_TIME_FORMAT, DEFAULT_DATE_FORMAT } from "types/constants";
import { translate } from "components";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    // display: "flex",
    // flexDirection: "row",
    // height: "100%",
    minWidth: "500"

    // overflow: "hidden",
    // backgroundColor: theme.palette.common.white
  },
  table__cell: {
    padding: "6px 4px",
    width: "500px",
    backgroundColor: "white",
    borderBottom: "0.2px solid #e7e9eb"
  },
  table__body: {
    position: "relative",
    minHeight: 40,
    overflow: "auto"
    // "& > div:nth-child(odd)": {
    //   background: theme.palette.background.default
    // },
  },
  cancel__button: {
    position: "absolute",
    top: "8px",
    right: "16px"
  },
  status__selection: {
    minWidth: "200px"
  },
  approved__field: {
    color: "#00897b"
  },
  changeStatus__btn: {
    minWidth: "185px",
    border: "0.1px ridge rgb(38, 50, 56)",
    height: "20",
    "border-radius": "4px"
    // "border-color": ,
  },
  status__text: {
    // width: "150px",
    "text-transform": "none",
    textAlign: "center"
  },
  in_use_status: {
    color: "#00897b"
  },
  temporary_status: {
    color: "#f50057"
  },
  registration__field: {
    marginTop: "15px"
  }
}));

const reformatDetailLabData = userInfo => {
  // userInfo.birthDate = moment.unix(labInformation.createdAt).format("L");
  const arr2 = [];
  userInfo.birthDate = moment
    .unix(userInfo.birthDate)
    .format(DEFAULT_DATE_FORMAT);
  for (const key in userInfo) {
    arr2.push({
      key,
      value: userInfo[key]
    });
  }
  const resultMerged = mergeArrayByKey(DETAIL_REFERENCE_DATA, arr2, "key");
  return resultMerged;
};

const UserDetail = props => {
  const { data, onUpdate } = props;

  // console.log("UserDetail.data", data);
  const currentStatus = String(data.currentStatus);
  let { status, statusUpdatedAt } = data;
  status = String(status);

  const [anchorEl, setAnchorEl] = useState(null);
  const [newStatus, setNewStatus] = useState(data.status);
  const intl = useIntl();

  // console.log("currentStatus", currentStatus, "status", status);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = e => {
    // console.log("handleClose", e.target.value)
    // if(e.target.value) {
    // onUpdate({ ...data, status: e.target.value});
    // }
    setAnchorEl(null);
  };

  const classes = useStyles();
  const { IN_USE, DISCONTINUED, WITH_DRAW } = BASE_USER_STATUS;
  let userStatusList = makeList(USER_STATUS_TYPES);
  userStatusList =
    currentStatus !== WITH_DRAW
      ? userStatusList.filter(
          o => o.value === IN_USE || o.value === DISCONTINUED
        )
      : userStatusList.filter(o => o.value === WITH_DRAW);

  const [open, setIsOpen] = useState(true);
  // const [status, setStatus] = useState();

  const { data: numberUserResponse, refetch: refetchStatistics } = useQuery(
    GET_NUMBER_USER_LAB,
    {
      variables: {
        labId: data.labId || ""
      },
      onCompleted: response => {
        // setContents(reformatDetailLabData({ ... data, numberUserAccount:  response?.getNumberUserOfLab}));
      }
    }
  );

  const contents = reformatDetailLabData({
    ...data,
    numberUserAccount: numberUserResponse?.getNumberUserOfLab
  });

  const heads = [
    {
      key: "key",
      className: classes.table__cell,

      component: ({ rowData }) => {
        return <b>{rowData.label}</b>;
      }
    },
    {
      key: "value",
      // label: "Lab Name",
      className: classes.table__cell,
      component: ({ rowData }) => {
        const { acceptedDate, numberUserAccount } = rowData;
        if (rowData.key === "registrationStatus") {
          // ({acceptedDate ? moment(acceptedDate).format(DEFAULT_DATE_TIME_FORMAT)})
          return (
            <Grid>
              <b className={classes.approved__field}>
                {rowData.value || "Approved"}
              </b>{" "}
              ({moment.unix(data.acceptedDate).format(DEFAULT_DATE_TIME_FORMAT)}
              )
            </Grid>
          );
        }

        if (rowData.key === "numberUserAccount") {
          return rowData.value || 0;
        }

        return rowData.value;
      }
    }
  ];

  const handleChangeStatus = e => {
    onUpdate({ ...data, status: e.target.value });
    setAnchorEl(null);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <CVTable
          classes={classes}
          heads={heads}
          contents={contents}
          del={({ rowIndex }) => {}}
          mod={() => {
            console.log("mod");
          }}
          add={() => {
            console.log("add");
          }}
          sorting={true}
        />
      </Grid>

      <Grid container className={classes.registration__field}>
        <Grid item xs={6}>
          <b>Usage Status</b>
        </Grid>
        <Grid item container justify="flex-start" alignItems="center" xs={6}>
          {/* Backup incase of option1 is log bug */}
          {/* <>
            <Button
              className={classes.changeStatus__btn}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <p className={classes.status__text}>
                {data.status == IN_USE ? "In use" : "discontinued"}
              </p>
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {reformatStatusList()}
            </Menu>
          </> */}
          <p
            className={
              currentStatus === IN_USE
                ? classes.in_use_status
                : classes.temporary_status
            }
          >
            {currentStatus == IN_USE
              ? translate(intl, "user.status.inUse")
              : translate(intl, "user.status.temporarySuspended")}
          </p>
          {/* <CCTextField
            value={status || null}
            label={""}
            select
            variant="outlined"
            margin="dense"
            className={classes.status__selection}
            onChange={e => {
              // setStatus(e.target.value);
              setIsOpen(false);
              onUpdate({ ...data, status: e.target.value });
            }}
          >
            {userStatusList.map(item => (
              <MenuItem
                key={item.value || null}
                disable={(item.value === currentStatus).toString()}
                value={item.value}
              >
                {item.label}
              </MenuItem>
            ))} 
          </CCTextField> */}
          &nbsp; (
          {moment.unix(statusUpdatedAt).format(DEFAULT_DATE_TIME_FORMAT)})
          &nbsp;
          <CCButton
            variant={"contained"}
            className={
              currentStatus == IN_USE
                ? classes.temporary_status
                : classes.in_use_status
            }
            size={"small"}
            color={"normal"}
            onClick={() =>
              onUpdate({
                ...data,
                status: currentStatus == IN_USE ? DISCONTINUED : IN_USE
              })
            }
            // onClick={onUpdate({ ...data, status: PROCESSING_TERMINATED })}
          >
            {currentStatus == IN_USE
              ? translate(intl, "user.status.temporarySuspended")
              : translate(intl, "user.status.inUse")}
          </CCButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserDetail;
