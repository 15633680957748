import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CCButton } from "styles/components";
import { translate } from "components";
const searchHeight = 68;
const useStyles = makeStyles(theme => ({
  root: { height: "100%" },
  search: { height: `${searchHeight}`, padding: 8 },
  search__field: { width: 200 },
  menu: {
    height: `calc(100% - ${searchHeight}px)`,
    // borderTop: '1px solid #b0bec5',
    overflow: "auto"
  },
  box1: { padding: "8px 16px" },
  box2: { paddingLeft: 16 },
  "@keyframes blink": {
    "50%": {
      opacity: 0.5,
      border: `1px solid ${theme.palette.select.main}`
    }
  },
  btn: {
    margin: "8px 4px 8px 0",
    color: theme.palette.icon
  },
  btn__focus: {
    "&:focus": {
      backgroundColor: theme.palette.focus,
      border: `1px solid ${theme.palette.focusBorder}`,
      zIndex: 1
      // border: `1px solid ${theme.palette.select.main}`,
      // animation: "$blink 1s linear infinite",
      // userSelect: "none"
    }
  }
}));

const SettingNav = props => {
  const { settingList, selected, onClick } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [selectedBtn, setSelectedBtn] = useState("");
  const btnRef = useRef({});
  // const [openAccessDialog, setOpenAccessDialog] = useState(null);

  // const options = useMemo(() => {
  //   const tmp = [];
  //   settingList.forEach((el) => {
  //     el.buttons.forEach((btn) => {
  //       tmp.push({ title: el.title, btnName: btn.name });
  //     });
  //   });
  //   return tmp;
  // }, [settingList]);

  // useEffect(() => {
  //   if (selectedBtn) btnRef.current[selectedBtn].focus();
  // }, [selectedBtn]);

  return (
    <>
      <Box className={classes.root}>
        {/* <Box className={classes.search}>
          <SearchMenu
            className={classes.search__field}
            label="설정검색"
            options={options}
            groupBy={option => option.title}
            getOptionLabel={option => option.btnName}
            getOptionSelected={(option, value) =>
              option.btnName === value.btnName
            }
            onChange={v => {
              if (v) setSelectedBtn(v.btnName);
            }}
            renderOption={option => option.btnName}
          />
        </Box> */}
        <Box className={classes.menu}>
          {settingList.map((setting, key) => {
            return (
              <Box key={key}>
                <Box className={classes.box1}>
                  <Typography variant="h5">
                    {translate( intl,setting.title )}
                  </Typography>
                </Box>
                <Box className={classes.box2}>
                  {setting.buttons.map((button, key) => {
                    return (
                      <CCButton
                        onBlur={() => {
                          setSelectedBtn("");
                        }}
                        className={clsx(
                          classes.btn,
                          selectedBtn === button.name &&
                            selected !== selectedBtn &&
                            classes.btn__focus
                        )}
                        color={selected === button.name ? "selected" : "normal"}
                        key={key}
                        variant="contained"
                        onClick={() => {
                          setSelectedBtn("");
                          if (button.name === "권한 설정") {
                            // setOpenAccessDialog(button.name);
                          } else {
                            onClick && onClick(button.name);
                          }
                        }}
                        ref={ref => {
                          btnRef.current[button.name] = ref;
                        }}
                      >
                        {translate( intl,button.name )}
                      </CCButton>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      {/* <AccessGrantedDialog
        open={Boolean(openAccessDialog)}
        onClose={() => setOpenAccessDialog(null)}
        onAccess={() => {
          onClick && onClick(openAccessDialog);
          setOpenAccessDialog(null);
        }}
      /> */}
    </>
  );
};
export default SettingNav;
