import { RouteContext } from "context";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const useRoute = () => {
  const history = useHistory();
  const { routeContext } = useContext(RouteContext);
  const [route, setRoute] = useState({ path: "", props: null });

  const changeRoute = (path, args) => {
    setRoute({ path: path, props: args });
    history.push(path);
  };

  useEffect(() => {
    if (routeContext.path) {
      setRoute(routeContext);
      history.push(routeContext.path);
    }
  }, [routeContext, history]);

  return [route || {}, changeRoute];
};

export { useRoute };
