import { AppContext } from "context";
import React, { useCallback, useContext, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const stopwords = "a an and at but by for in nor of on or so the to up yet";
const defaults = stopwords.split(" ");
const CAPITALIZE_TYPE = {
  FIRST_WORD: "FIRST_WORD",
  APA: "APA",
  APA_PRESERVE: "APA_PRESERVE",
  ALL_UPPER: "ALL_UPPER",
  ALL_LOWER: "ALL_LOWER"
};

function apa(str, options) {
  const opts = options || {};

  if (!str) return "";

  const stop = opts.stopwords || defaults;
  const keep = opts.keepSpaces;
  const force = opts.force || true;
  const splitter = /(\s+|[-‑–—/])/;

  return str
    .split(splitter)
    .map((word, index, all) => {
      if (word.match(/\s+/)) return keep ? word : " ";
      if (word.match(splitter)) return word;

      if (
        index !== 0 &&
        index !== all.length - 1 &&
        stop.includes(word.toLowerCase())
      ) {
        return word.toLowerCase();
      }

      return _capitalize(word, force);
    })
    .join("");
}

function _capitalize(str, force = true) {
  // console.log(str);
  let _str = force ? str.toLowerCase() : str;
  return _str.charAt(0).toUpperCase() + _str.slice(1);
}

const useCapitalIntl = props => {
  const intl = useIntl();
  const [language] = useLanguage();
  const formatMessage = useCallback(
    (descriptor, values, options = { capitalizeType: "FIRST_WORD" }) => {
      let res = intl.formatMessage(descriptor, values);
      if (Array.isArray(res)) {
        res = res.map(item => {
          if (typeof item !== "string") {
            return item;
          }
          switch (language) {
            case "en":
              switch (options?.capitalizeType) {
                default:
                case CAPITALIZE_TYPE.FIRST_WORD:
                  return _capitalize(item);
                case CAPITALIZE_TYPE.APA:
                  return apa(item);
                case CAPITALIZE_TYPE.APA_PRESERVE:
                  return apa(item, { force: false });
                case CAPITALIZE_TYPE.ALL_UPPER:
                  return item.toUpperCase();
                case CAPITALIZE_TYPE.ALL_LOWER:
                  return item.toLowerCase();
              }
            default:
              return item;
          }
        });
      } else {
        switch (language) {
          case "en":
            switch (options?.capitalizeType) {
              default:
              case CAPITALIZE_TYPE.FIRST_WORD:
                res = _capitalize(res);
                break;
              case CAPITALIZE_TYPE.APA:
                res = apa(res);
                break;
              case CAPITALIZE_TYPE.APA_PRESERVE:
                res = apa(res, { force: false });
                break;
              case CAPITALIZE_TYPE.ALL_UPPER:
                res = res.toUpperCase();
                break;
              case CAPITALIZE_TYPE.ALL_LOWER:
                res = res.toLowerCase();
                break;
            }
            break;
          default:
            break;
        }
      }

      return res;
    },
    [intl, language]
  );
  const res = useMemo(
    () => ({
      formatMessage
    }),
    [formatMessage]
  );
  return res;
};
const CapitalFormattedMessage = props => {
  const [language] = useLanguage();
  const { capitalizeType, ...others } = props;
  return (
    <FormattedMessage {...others}>
      {chunks => {
        return chunks.map(item => {
          if (typeof item !== "string") {
            return item;
          }
          switch (language) {
            case "en":
              switch (capitalizeType) {
                default:
                case CAPITALIZE_TYPE.FIRST_WORD:
                  return _capitalize(item);
                case CAPITALIZE_TYPE.APA:
                  return apa(item);
                case CAPITALIZE_TYPE.APA_PRESERVE:
                  return apa(item, { force: false });
                case CAPITALIZE_TYPE.ALL_UPPER:
                  return item.toUpperCase();
                case CAPITALIZE_TYPE.ALL_LOWER:
                  return item.toLowerCase();
              }
            default:
              return item;
          }
        });
      }}
    </FormattedMessage>
  );
};

const useLanguage = props => {
  const { appContext, dispatchApp } = useContext(AppContext);

  const setLanguage = language => {
    dispatchApp({ type: "language", target: language });
  };

  return [appContext.language, setLanguage];
};

export {
  useLanguage,
  useCapitalIntl as useIntl,
  CapitalFormattedMessage as FormattedMessage,
  CAPITALIZE_TYPE
};
