import gql from "graphql-tag";

const GET_LAB_STATS = gql`
  query GetLabStatistics {
    labStatistics {
      totalApproval
      totalInuse
      totalDiscontinued
      totalWithdraw
      totalWithdrawAfterOneMonth
    }
  }
`;

const GET_LAB_BASIC_INFO_LIST = gql`
  query GetLabList($input: GetLabListQueryInput!) {
    labList(input: $input) {
      total
      hasNext
      hasPrev
      items {
        id
        status
        labName
        labId
      }
    }
  }
`;

const GET_LAB_LIST = gql`
  query GetLabList($input: GetLabListQueryInput!) {
    labList(input: $input) {
      total
      hasNext
      hasPrev
      items {
        id
        status
        avatar {
          id
          name
          target
          type
        }
        isRecommendedLab
        labName
        specialties
        statusMessage
        managerName
        labId
        managerCellNumber
        labTelNumber
        email
        address
        acceptedDate
        createdAt
        updatedAt
        birthDate
        withdrawDateTime
        erc
        staffId
        statusUpdatedAt
        registrationAt
        registrationAt
        prothesisImages {
          target
          id
          name
          type
        }
        location {
          address
          placeId
          detailAddress
          coordinate {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

const GET_RECOMMENDED_LAB_LIST = gql`
  query GetRecommendedLabList($searchText: String) {
    recommendedLabList(searchText: $searchText) {
      id
      avatar {
        id
        name
        target
        type
      }
      labId
      labName
      specialties
      statusMessage
    }
  }
`;

const GET_LAB_PREVIEW = gql`
  query GetLabPreview($labId: String) {
    labPreview(labId: $labId) {
      labId
      # openDate
      labName
      managerName
      address
      specialties
      aboutUs
      availableArea
      labTelNumber
      managerCellNumber
      erc
      email
      statusMessage
      avatar {
        target
        id
        name
        type
      }
      location {
        address
        placeId
        detailAddress
        coordinate {
          latitude
          longitude
        }
      }
      workDaySetting {
        dayOfWeek
        holiday
      }
      workTimeSetting {
        begin
        end
      }
      prothesisImages {
        target
        id
        name
        type
      }
    }
  }
`;

const GET_CONFIG_PRICING = gql`
  query getConfig($labId: String, $category: String) {
    getConfig(labId: $labId, category: $category) {
      id
      category
      labId
      prosthesisPriceSetting {
        name
        id
        state
        items {
          name
          id
          partnerId
          item {
            contents {
              category
              tabContents {
                id
                material
                unit
                price
              }
            }
          }
        }
      }
    }
  }
`;

const GET_NUMBER_USER_LAB = gql`
  query getNumberOfUserOnLab($labId: String!) {
    getNumberUserOfLab(labId: $labId)
  }
`;

const UPDATE_LAB_STATUS = gql`
  mutation UpdateLabStatus($input: UpdateLabStatusInput!) {
    updateLabStatus(input: $input)
  }
`;

const TOGGLE_RECOMMENDED_LAB = gql`
  mutation toggleIsRecommendedLab($labId: String!) {
    toggleIsRecommendedLab(labId: $labId)
  }
`;

export {
  GET_LAB_STATS,
  GET_LAB_LIST,
  GET_RECOMMENDED_LAB_LIST,
  GET_LAB_PREVIEW,
  GET_CONFIG_PRICING,
  UPDATE_LAB_STATUS,
  TOGGLE_RECOMMENDED_LAB,
  GET_NUMBER_USER_LAB,
  GET_LAB_BASIC_INFO_LIST
};
