import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import moment from "moment";
import propTypes from "prop-types";
import React from "react";
import CCTextFieldTrio from "styles/src/components/CCTextFieldTrio";
import { FormattedMessage, useIntl } from "styles/locales/language";

const useStyle = makeStyles(theme => ({
  root: {},
  dateButton: {
    color: theme.palette.icon
  }
}));
function pad(n, width) {
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join("0") + n;
}
const MeridiemItems = function* () {
  const meridiem = ["AM", "PM"];
  for (let i in meridiem) {
    yield (
      <MenuItem key={"chart__field__meridiem__menuitem" + i} value={i}>
        {meridiem[i]}
      </MenuItem>
    );
  }
};

const InputMenuHourItems = function* (count, value) {
  for (let i = 0; i < count; i++) {
    if (value.hours() >= 12) {
      if (i === 11) {
        yield (
          <MenuItem key={"MenuHourItem" + i} value={i + 1}>
            {pad(i + 1, 2)}
            <FormattedMessage id={"ccTimeUnit"} />
          </MenuItem>
        );
      } else {
        yield (
          <MenuItem key={"MenuHourItem" + i} value={i + 1 + 12}>
            {pad(i + 1, 2)}
            <FormattedMessage id={"ccTimeUnit"} />
          </MenuItem>
        );
      }
    } else {
      yield (
        <MenuItem key={"MenuHourItem" + i} value={(i + 1) % 12}>
          {pad(i + 1, 2)}
          <FormattedMessage id={"ccTimeUnit"} />
        </MenuItem>
      );
    }
  }
};

const InputMenuTimeItems = function* (count) {
  for (let i = 0; i < count; i++) {
    yield (
      <MenuItem key={"MenuTimeItem" + i} value={i}>
        {pad(i, 2)}
        <FormattedMessage id={"ccTimeUnit"} />
      </MenuItem>
    );
  }
};

const CCFlattenTimeSelect = props => {
  const { value, onChange } = props;
  const classes = useStyle();
  // const [textValue, setTextValue] = useState(value.format("YYYY/MM/DD"));
  // useEffect(() => {
  //   setTextValue(value.format("YYYY/MM/DD"));
  // }, [value]);
  // useEffect(() => {
  //   let dayRegExp = /^(19|20)\d{2}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[0-1])$/;
  //   let isValid = dayRegExp.test(textValue);
  //   let _textValue = moment(textValue, "YYYY/MM/DD");
  //   if (isValid) {
  //     let _change = value
  //       .clone()
  //       .year(_textValue.year())
  //       .month(_textValue.month())
  //       .date(_textValue.date());
  //     if (value.unix() !== _change.unix()) onChange(_change);
  //   }
  // }, [value, textValue, onChange]);
  const intl = useIntl();
  return (
    <>
      <CCTextFieldTrio
        variant={"outlined"}
        margin={"dense"}
        LeftProps={{
          label: intl.formatMessage({ id: "ccTimeUnit" }),
          InputProps: {
            className: clsx(classes.timespent__input)
          },
          InputLabelProps: {
            shrink: true
          },
          value: value.format("H"),
          select: true,
          onChange: e => {
            onChange(value.clone().hours(e.target.value));
          },
          children: [...InputMenuHourItems(12, value.clone())]
        }}
        MidProps={{
          label: intl.formatMessage({ id: "ccMinuteUnit" }),
          InputProps: {
            className: clsx(classes.timespent__input)
          },
          InputLabelProps: {
            shrink: true
          },
          value: value.format("m"),
          select: true,
          onChange: e => {
            onChange(value.clone().minutes(e.target.value));
          },
          children: [...InputMenuTimeItems(60)]
        }}
        RightProps={{
          label: intl.formatMessage({ id: "ccMeridiem" }),
          InputProps: {
            className: clsx(classes.timespent__input)
          },
          InputLabelProps: {
            shrink: true
          },
          value: value.hours() < 12 ? 0 : 1,
          select: true,
          onChange: e => {
            if (e.target.value === "1") {
              if (value.hours() < 12) {
                let _res = value.clone().add(12, "hours");
                onChange && onChange(_res);
              }
            } else {
              if (value.hours() >= 12) {
                let _res = value.clone().subtract(12, "hours");
                onChange && onChange(_res);
              }
            }
          },
          children: [...MeridiemItems()]
        }}
      />
    </>
  );
};

CCFlattenTimeSelect.propTypes = {
  value: propTypes.instanceOf(moment).isRequired,
  onChange: propTypes.func.isRequired
};

export default CCFlattenTimeSelect;
