import React from "react";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { CCButton } from "styles/components";
import { CCFlattenDateField } from "styles/src/components/CCFlattenDateTimePicker";
import { useIntl } from "react-intl";

const useStyle = makeStyles(theme => ({
  root: ({readonly}) => ({
    '& .MuiOutlinedInput-root': {
       color: readonly ? "black": "#d0d0d0"
    },
  }),
  input: {
    backgroundColor: theme.palette.background.paper
  },
  timeInput: {
    width: 133, 
  },
  ml8: {
    marginLeft: 8
  },
 
}));

const QueryFilter = props => {
  const { value, onChange } = props;
  const classes = useStyle({readonly: value.start || value.end});
  const intl = useIntl();
  return (
    <Box className={classes.root}>
      <Grid container direction={"column"} justify={"center"} wrap={"nowrap"}>
        <Grid item>
          <Grid container alignItems={"center"} wrap={"nowrap"}>
            <Grid item>
              <CCFlattenDateField
                onChange={e => {
                  onChange({
                    ...value,
                    start: e.startOf("day").unix()
                  });
                }}
                label={intl.formatMessage({ id: "ccDate" })}
                value={value.start ? moment(value.start, "X") : moment().startOf("month")} 
                classes ={{root: classes.root__input}}
                className={clsx(classes.input, classes.timeInput)}
              />
            </Grid>
            <Grid item>{"~"}</Grid>
            <Grid item>
              <CCFlattenDateField
                onChange={e => {
                  onChange({
                    ...value,
                    end: e.endOf("day").unix()
                  });
                }}
                label={intl.formatMessage({ id: "ccDate" })}
                value={value.end ? moment(value.end, "X") : moment().endOf("month")}
                className={clsx(classes.input, classes.timeInput)}
              />
            </Grid>

            <Grid item className={classes.ml8}>
              <CCButton
                variant={"contained"}
                size={"small"}
                color={"normal"}
                onClick={() => {
                  onChange({
                    ...value,
                    start: moment().startOf("day").unix(),
                    end: moment().endOf("day").unix()
                  });
                }}
              >
                {"Day"}
              </CCButton>
            </Grid>
            <Grid item className={classes.ml8}>
              <CCButton
                variant={"contained"}
                size={"small"}
                color={"normal"}
                onClick={() => {
                  onChange({
                    ...value,
                    start: moment().startOf("week").unix(),
                    end: moment().endOf("week").unix()
                  });
                }}
              >
                {"Week"}
              </CCButton>
            </Grid>
            <Grid item className={classes.ml8}>
              <CCButton
                variant={"contained"}
                size={"small"}
                color={"normal"}
                onClick={() => {
                  onChange({
                    ...value,
                    start: moment().startOf("month").unix(),
                    end: moment().endOf("month").unix()
                  });
                }}
              >
                {"Month"}
              </CCButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QueryFilter;
