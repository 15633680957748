import Grid from "@material-ui/core/Grid";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { DayItem, NoopDayItem, WeekHeader } from "./component";

const DayItemSeries = function* (
  value,
  selected,
  onChange,
  components,
  checkedList,
  mode,
  preserveCurrentTime
) {
  let _value = value.clone().startOf("week");
  let _endOfWeek = value.clone().endOf("week");
  do {
    // console.log(_value.toString());
    if (_value.isSame(value, "month")) {
      if (components && components.dayItem) {
        yield (
          <Grid
            key={"Calender-dayItem-" + _value.toString()}
            container
            item
            style={{ width: 36, height: 36 }}
            justify={"center"}
            alignItems={"center"}
          >
            <components.dayItem
              value={_value.clone()}
              selected={selected}
              onChange={onChange}
            />
          </Grid>
        );
      } else {
        yield (
          <DayItem
            key={"Calender-dayItem-" + _value.toString()}
            value={_value.clone()}
            selected={selected}
            onChange={onChange}
            checkedList={checkedList}
            mode={mode}
            preserveCurrentTime={preserveCurrentTime}
          />
        );
      }
    } else {
      yield <NoopDayItem key={"Calender-dayItem-" + _value.toString()} />;
    }
    _value.add(1, "day");
  } while (_value.isSameOrBefore(_endOfWeek));
};
const WeekRow = function* (
  value,
  onChange,
  components,
  checkedList,
  mode,
  preserveCurrentTime
) {
  let _value =
    mode === "day"
      ? value.clone().startOf("month")
      : value.begin.clone().startOf("month");
  let _endOfMonth =
    mode === "day"
      ? value.clone().endOf("month")
      : value.begin.clone().endOf("month");
  do {
    // console.log(_value.toString());
    yield (
      <Grid
        key={"Calender-WeekRow-" + _value.toString()}
        item
        container
        justify={"center"}
      >
        {[
          ...DayItemSeries(
            _value,
            value,
            onChange,
            components,
            checkedList,
            mode,
            preserveCurrentTime
          )
        ]}
      </Grid>
    );
    _value.add(1, "week").startOf("week");
  } while (_value.isSameOrBefore(_endOfMonth));
};

const Calendar = props => {
  const {
    value,
    onChange,
    components,
    checkedList,
    mode,
    preserveCurrentTime
  } = props;
  return (
    <Grid container item>
      <WeekHeader />
      {[
        ...WeekRow(
          value,
          onChange,
          components,
          checkedList,
          mode,
          preserveCurrentTime
        )
      ]}
    </Grid>
  );
};
Calendar.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.shape({
      begin: PropTypes.instanceOf(moment),
      end: PropTypes.instanceOf(moment)
    })
  ]).isRequired,
  components: PropTypes.shape({
    DayItem: PropTypes.elementType
  }),
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["day", "week"]),
  preserveCurrentTime: PropTypes.bool
};
export default Calendar;
