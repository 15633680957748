import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

const useStyle = makeStyles(
  theme => ({
    root: {
      backgroundColor: "#0277bd",
      padding: "4px 0"
      // borderTopLeftRadius: 8,
      // borderTopRightRadius: 8
    },
    typo: {
      color: theme.palette.common.white
    }
  }),
  { name: "CCFlattenDateTimePicker-Header" }
);

const Header = props => {
  const { value, type, mode } = props;
  const classes = useStyle();
  return (
    <Box className={clsx(classes.root)}>
      <Grid container justify={"center"} alignItems={"center"}>
        <Grid item>
          {mode === "day" ? (
            <Typography className={clsx(classes.typo)} variant={"h4"}>
              {type === "date" && `${moment(value.toDate()).format("ddd")}, ${moment(value.toDate()).format("LL")}`}
              {type === "time" && moment(value.toDate()).format("LT")}
              {type === "dateTime" && moment(value.toDate()).format("LLLL")}
            </Typography>
          ) : (
            <Typography className={clsx(classes.typo)} variant={"h4"}>
              {type === "date" &&
                `${moment(value.begin.toDate()).format("MMMM")} ${moment(value.begin.toDate()).clone().week() -
                moment(value.begin.toDate()).clone().startOf("month").week() +
                1
                }주`}
              {type === "time" && moment(value.begin.toDate()).format("LT")}
              {type === "dateTime" &&
                moment(value.begin.toDate()).format("LLLL")}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
Header.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.shape({
      begin: PropTypes.instanceOf(moment),
      end: PropTypes.instanceOf(moment)
    })
  ]).isRequired,
  type: PropTypes.oneOf(["date", "time", "dateTime"]).isRequired,
  mode: PropTypes.oneOf(["day", "week"])
};
Header.defaultProps = {
  mode: "day"
};
export default Header;
