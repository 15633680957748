import gql from "graphql-tag";

const UPDATE_SMTP_SETTING = gql`
  mutation CreateSmtpSetting($labId: String!, $smtpSetting: SmtpSettingInput) {
    managerCreateSmtpSetting(
      input: {
        labId: $labId
        smtpSetting: $smtpSetting
        category: "adminSmtpSetting"
      }
    ) {
      code
    }
  }
`

export { UPDATE_SMTP_SETTING };

