import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { CCButton, CCDialogSimple, CCTypography } from "styles/components";
import { WarningIcon, HappyIcon, EnterIcon, RotaterightIcon } from "styles/icons";

const useStyles = makeStyles(theme => ({
  root: {},
  warning___color: { color: theme.palette.error.main },
  success__color: { color: theme.palette.success.main },
  desc___color: { color: theme.palette.sub.main },
  text: { marginTop: 8, width: 280 },
  disagree: { marginRight: 8 },
  icon: {
    color: theme.palette.success.main,
    marginRight: 8
  }
}));

const DialogSuccessConfirm = props => {
  const {
    open,
    onClose,
    title,
    subTitle,
    agreeButtonColor,
    agreeButtonLabel,
    onAgree,
    secondaryAction,
    secondaryActionLabel,
    disagreeButtonLabel,
  } = props;
  const classes = useStyles();


  return (
    <CCDialogSimple
      open={open}
      isHeader={false}
      onClose={onClose}
      onCloseButton={onClose}
      contents={
        <Box>
          <RotaterightIcon className={classes.icon} />
          <Box className={classes.text}>
            <CCTypography
              // className={classes.warning___color}
            >
              {title ? title : "The entered value is incorrect."}
            </CCTypography>
            {subTitle && (
              <CCTypography className={classes.desc___color}>
                {subTitle}
              </CCTypography>
            )}
          </Box>
        </Box>
      }
      endActions={
        <>
          {secondaryAction && (
            <CCButton
              className={classes.disagree}
              color={"secondary"}
              variant={"text"}
              onClick={() => {
                secondaryAction && secondaryAction();
              }}
            >
              {secondaryActionLabel ? secondaryActionLabel : "Confirm"}
            </CCButton>
          )}
          {/* <CCButton
            className={classes.disagree}
            color={"secondary"}
            variant={"text"}
            onClick={() => {
              onClose && onClose();
            }}
          >
            {disagreeButtonLabel ? disagreeButtonLabel : "Cancel"}
          </CCButton> */}
          <CCButton
            className={classes.disagree}
            variant={"contained"}
            color={agreeButtonColor ? agreeButtonColor : "primary"}
            onClick={() => {
              onAgree && onAgree();
            }}
          >
            {agreeButtonLabel ? agreeButtonLabel : "Confirm"}
          </CCButton>
        </>
      }
    />
  );
};
export default DialogSuccessConfirm;
