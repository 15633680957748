import React from "react";
import { translate } from "..";
import { ToastSimple } from "./components";

const enqueueToast = (enqueueSnackbar, message, links) => {
  enqueueSnackbar(message, {
    persist: false,
    autoHideDuration: 1500,
    transitionDuration: { exit: 500 },
    content: (key, message) => {
      return <ToastSimple snackKey={key} message={message} links={links} />;
    }
  });
};

const enqueueToastSave = (intl, enqueueSnackbar) => {
  enqueueToast(enqueueSnackbar, translate(intl, "common.messages.hasBeenSaved"));
};

const enqueueToastSend = (intl, enqueueSnackbar) => {
  enqueueToast(enqueueSnackbar, translate(intl, "common.messages.yourTextMessageHasBeenSent"));
};

export { enqueueToast, enqueueToastSave, enqueueToastSend };
