const mergeArrayByKey = (arr1, arr2, key = 'key') => {
  let merged = [];

  for (let i = 0; i < arr1.length; i++) {
    merged.push({
      ...arr1[i],
      ...arr2.find(itmInner => itmInner[key] === arr1[i][key])
    });
  }

  return merged;
};

export { mergeArrayByKey };
