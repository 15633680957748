import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid } from "@material-ui/core";
import moment from "moment";
import { CCTable, CCTypography } from "styles/components";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    flex: 1,
    // borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: "none"
  },

  title: {
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
    padding: "8px 16px 8px 45px",
    borderTop: "1px solid #b0bec5",
    borderBottom: "1px solid #b0bec5"
  },
  cell__surgery: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: 45,
    justifyContent: "center"
  },

  cell: {
    textAlignLast: "start",
    flex: 1,
    color: theme.palette.text.primary
  },
  table__product: {
    textAlignLast: "start",
    flex: 2,
    color: theme.palette.text.primary
  }
}));

const tableStyle = makeStyles(theme => ({
  root: {
    width: "100%",
    flex: 1
  },
  table__body: {
    backgroundColor: theme.palette.background.default,
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    }
  }
}));

export default function LabIfo({
  data: {
    labName,
    managerName,
    address,
    workTimeSetting,
    workDaySetting,
    labTelNumber,
    availableArea
  }
}) {
  const classes = useStyles();

  const heads = [
    {
      key: "titles",
      className: clsx({
        [classes.cell]: true
      }),

      component: ({ cellData, rowIndex }) => {
        return (
          <Box className={classes.cell__surgery}>
            <CCTypography>{`${cellData}`}</CCTypography>
          </Box>
        );
      }
    },
    {
      key: "value",
      className: clsx({
        [classes.table__product]: true
      }),

      component: ({ cellData, rowIndex }) => {
        return (
          <Box className={classes.cell__surgery}>
            <CCTypography>{`${cellData}`}</CCTypography>
          </Box>
        );
      }
    }
  ];
  const contents1 = [
    { titles: "Lab Name", value: labName },
    { titles: "Manager", value: managerName },
    { titles: "Address", value: address }
  ];

  const contents2 = [
    {
      titles: "Working Hours",
      value: `${moment
        .unix(workTimeSetting.begin)
        .format("LT")} - ${moment.unix(workTimeSetting.end).format("LT")}`
    },
    {
      titles: "Working Days",
      value: workDaySetting.reduce((acc, cur) => {
        if (!cur.holiday) {
          acc = acc
            ? acc +
            ", " +
            moment()
              .weekday(Number(cur.dayOfWeek) + 1)
              .format("dddd")
            : moment()
              .weekday(Number(cur.dayOfWeek) + 1)
              .format("dddd");
        }

        return acc;
      }, "")
    },
    { titles: "Tel.", value: labTelNumber },
    { titles: "Available Area", value: availableArea }
  ];

  const resultTableClasses = tableStyle();
  return (
    <Grid container direction="column">
      <CCTable
        classes={resultTableClasses}
        heads={heads}
        contents={contents1}
        labelShow={false}
      />

      <CCTypography className={classes.title}>Operation Info</CCTypography>

      <CCTable
        classes={resultTableClasses}
        heads={heads}
        contents={contents2}
        labelShow={false}
      />
    </Grid>
  );
}
