import { useQuery } from "@apollo/react-hooks";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import clsx from "clsx";
import {
  CVFlattenDateField, CVTextField, PhoneNumberFieldNoMask as PhoneNumberField, translate, PhoneNumberRegionField
} from "components";
import gql from "graphql-tag";
// import { CCButton } from "styles/components";
import moment from "moment";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import countryData from "material-ui-phone-number/src/country_data";
import { ENTRY_LIMIT } from "../../../../../types/constants";

const EmailRegExp =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*(\.[a-zA-Z]{2,})$/i;

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "inherit",
    margin: 16,
    border: "1px solid #b0bec5",
    borderRadius: 8
    // marginTop: '-2%',
  },
  line: {
    height: 1,
    width: "100%",
    background: " #b0bec5",
    margin: "16px 0"
  },
  content: {
    padding: 16,
    height: "100%"
  },
  header: {
    fontWeight: "bold",
    padding: "8px 16px",
    borderBottom: "1px solid #b0bec5",
    width: "100%"
  },
  inputWidth216: {
    width: 260
  },
  inputWidth169: {
    width: 169,
    margin: "8px 16px"
  },
  inputWidth500: {
    flex: 1,
    width: "300px",
  },
  inputWidth330: {
    width: 330
  },
  inputWidthFull: {
    width: "100%"
  },
  inputMargin: {
    margin: "8px 0"
  },
  marginRight8: {
    marginRight: "8px"
  },
  inputMarginLeft: {
    margin: "8px 0px 8px 16px"
  },
  required: {
    color: "rgba(0, 0, 0, 0.38)"
  },
  iconCheck: {
    // fontSize: 40,
    color: "#00897b",
    fontWeight: "bold"
  },
  saveButton: {
    // textAlign: "right"
    float: "right",
    marginTop: "2%"
  },
  availableId: {
    marginLeft: 4,
    color: "#00897b",
  },
  wrongId: {
    color: "#f50057"
  },
  margin__phone: {
    margin: '0 16px'
  }
}));

const ID_VALIDATION_REGEXP = /^([a-z0-9]+){4,30}$/;
const validId = id => {
  return ID_VALIDATION_REGEXP.test(id);
};

const CHECK_LAB_ID_EXIST = gql`
  query checkLabIdExist($id: String!) {
    checkLabIdExist(id: $id)
  }
`;

const ApplicationForm = props => {
  const { errors, value, onChange, onError, onSave } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [idIsAvailable, setIdIsAvailable] = useState("notChecked");

  const { refetch } = useQuery(CHECK_LAB_ID_EXIST, {
    variables: {
      id: ""
    }
  });

  return (
    <Grid container className={classes.root}>
      <Typography className={classes.header}>
        {translate(intl, "signUp.ui.stepsForm")}
      </Typography>
      <Grid container className={classes.content} direction="column">
        <Grid container direction="row" alignItems="center">
          <CVTextField
            className={clsx(
              classes.inputWidth216,
              classes.inputMargin,
              classes.marginRight8
            )}
            variant={"outlined"}
            margin={"dense"}
            // value={value?.labId}
            error={errors.labId}
            label={translate(intl, "signUp.ui.labId")}
            required
            onChange={e => {
              const valueLabId = e.target.value.trim();
              const labIdCheck = valueLabId === "" || !validId(valueLabId)
              onError("labId", labIdCheck);
              onChange("labId", valueLabId);

              refetch({
                id: e.target.value
              }).then(data => {
                if (labIdCheck) {
                  setIdIsAvailable("noStrong");
                } else {
                  onError("labId", data?.data?.checkLabIdExist);
                  onChange("labId", valueLabId);
                  setIdIsAvailable(
                    !data?.data?.checkLabIdExist ? "Right" : "Wrong"
                  );
                }
                // onChange("labId", value?.labId);
              });
            }}
            InputProps={{
              inputProps: {
                maxLength: 30
              },
              // endAdornment: (
              //   <CCButton
              //     disabled={
              //       // idIsAvailable !== "Right" ||
              //       value?.labId === "" || !validId(value?.labId)
              //     }
              //     variant={"contained"}
              //     size={"small"}
              //     onClick={() => {
              //       refetch({
              //         id: value?.labId ? value?.labId : ""
              //       }).then(data => {
              //         onError("labId", data.data.checkLabIdExist);
              //         setIdIsAvailable(
              //           !data.data.checkLabIdExist ? "Right" : "Wrong"
              //         );
              //         onChange("labId", value?.labId);
              //       });
              //     }}
              //   >
              //     {translate(intl, "signUp.ui.checkId")}
              //   </CCButton>
              // )
            }}
          />
          {idIsAvailable === "Wrong" && (
            <Typography className={classes.wrongId}>{translate(intl, "signUp.ui.idUsed")}</Typography>
          )}
          {idIsAvailable === "noStrong" && (
            <Typography className={classes.wrongId}>{translate(intl, "signUp.ui.noStrong")}</Typography>
          )}
          {idIsAvailable === "Right" && (
            <>
              <CheckCircleIcon className={classes.iconCheck} />
              <Typography className={classes.availableId}>
                {translate(intl, "signUp.ui.availableId")}
              </Typography>
            </>
          )}
        </Grid>
        <Typography className={classes.required}>
          {translate(intl, "signUp.ui.labCheckRequire")}
        </Typography>
        <div className={classes.line}></div>

        <CVTextField
          className={clsx(classes.inputWidth216, classes.inputMargin)}
          variant={"outlined"}
          margin={"dense"}
          value={value?.userId}
          label={translate(intl, "signUp.ui.userId")}
          error={errors.userId}
          required
          onChange={e => {
            const trimmedValue = e.target.value.trim();
            onError("userId", trimmedValue === "" || !validId(trimmedValue));
            onChange("userId", e.target.value);
          }}
        />
        <Typography className={classes.required}>
          {translate(intl, "signUp.ui.labCheckRequire")}
        </Typography>
        <div className={classes.line}></div>
        {/* <CVTextField
          // disabled={true}
          className={clsx(classes.inputWidth216, classes.inputMargin)}
          variant={"outlined"}
          margin={"dense"}
          value={value?.password}
          error={errors.password}
          label={translate(intl, "common.ui.password")}
          type="password"
          required
          InputProps={{
            inputProps: {
              maxLength: ENTRY_LIMIT.MEDIUM_SIZE
            }
          }}
          onChange={e => {
            onError(
              "password",
              e.target.value.trim() === "" ||
                !validPassword(e.target.value.trim())
            );
            onChange("password", e.target.value);
          }}
        />
        <CVTextField
          // disabled={true}
          className={clsx(classes.inputWidth216, classes.inputMargin)}
          variant={"outlined"}
          margin={"dense"}
          value={value?.confirmPassword}
          label={translate(intl, "signUp.ui.confirmPassword")}
          error={errors.confirmPassword}
          type="password"
          required
          InputProps={{
            inputProps: {
              maxLength: ENTRY_LIMIT.MEDIUM_SIZE
            }
          }}
          onChange={e => {
            const _t = e.target.value.trim();
            onError(
              "confirmPassword",
              _t === "" ||
                !validPassword(_t) ||
                (_t && value?.password && _t !== value?.password)
            );
            onChange("confirmPassword", e.target.value);
          }}
        /> */}

        <Typography className={classes.required}>
          {translate(intl, "signUp.ui.passwordNote")}
        </Typography>

        <div className={classes.line}></div>
        <Grid container>
          <CVTextField
            className={clsx(classes.inputWidth500, classes.inputMargin)}
            variant={"outlined"}
            margin={"dense"}
            value={value?.labName}
            label={translate(intl, "common.ui.labName")}
            required
            error={errors.labName}
            InputProps={{
              inputProps: {
                maxLength: ENTRY_LIMIT.NAME
              }
            }}
            onChange={e => {
              onError("labName", e.target.value.trim() === "");
              onChange("labName", e.target.value);
            }}
          />
          <PhoneNumberRegionField
            error={errors.tellPhoneNumber}
            className={clsx(classes.inputWidth169)}
            classesCover={classes.margin__phone}
            label={translate(intl, "common.ui.telNo")}
            margin={"dense"}
            variant={"outlined"}
            required
            value={value?.tellPhoneNumber}
            onChange={e => {
              onChange("tellPhoneNumber", e.target.value);
              const code = e.target.value?.trim().replace(/ *\([^)]*\) */g, "").replace('+', "");
              onError("tellPhoneNumber", Boolean(countryData.allCountries.find((item) => item.dialCode === code)) || Boolean(e.target.value.length < 8));
            }}
          />
          <CVTextField
            className={clsx(classes.inputWidth169, classes.inputMargin)}
            variant={"outlined"}
            margin={"dense"}
            value={value?.email}
            label={translate(intl, "common.ui.email")}
            required
            error={errors.email}
            InputProps={{
              inputProps: {
                maxLength: ENTRY_LIMIT.MEDIUM_SIZE
              }
            }}
            onChange={e => {
              onError(
                "email",
                e.target.value.trim() === "" ||
                !EmailRegExp.test(e.target.value.trim())
              );
              onChange("email", e.target.value);
            }}
          />

          <CVTextField
            className={clsx(classes.inputWidth169, classes.inputMarginLeft)}
            variant={"outlined"}
            margin={"dense"}
            value={value?.erc}
            // required
            label={translate(intl, "common.ui.erc")}
            error={errors.erc}
            InputProps={{
              inputProps: {
                maxLength: ENTRY_LIMIT.MEDIUM_SIZE
              }
            }}

            onChange={e => {
              onError("erc", e.target.value.trim() === "");
              onChange("erc", e.target.value);
            }}
          />
        </Grid>
        <Grid container>
          <CVTextField
            className={clsx(classes.inputWidth500, classes.inputMargin)}
            variant={"outlined"}
            margin={"dense"}
            value={value?.address}
            label={translate(intl, "common.ui.address")}
            required
            error={errors.address}
            InputProps={{
              inputProps: {
                maxLength: ENTRY_LIMIT.MEDIUM_SIZE
              }
            }}
            onChange={e => {
              onError("address", e.target.value.trim() === "");
              onChange("address", e.target.value);
            }}
          />
        </Grid>
        <div className={classes.line}></div>
        <Grid>
          <CVTextField
            className={clsx(classes.inputWidth169, classes.inputMargin)}
            variant={"outlined"}
            margin={"dense"}
            value={value?.name}
            label={translate(intl, "common.ui.managerName")}
            required
            error={errors.managerName}
            InputProps={{
              inputProps: {
                maxLength: ENTRY_LIMIT.NAME
              }
            }}
            onChange={e => {
              onError("managerName", e.target.value.trim() === "");
              onChange("managerName", e.target.value);
            }}
          />
          <PhoneNumberRegionField
            error={errors.cellPhoneNumber}
            className={clsx(classes.inputWidth169)}
            classesCover={classes.margin__phone}
            label={translate(intl, "common.ui.cellNo")}
            margin={"dense"}
            variant={"outlined"}
            required
            value={value?.cellPhoneNumber}
            onChange={e => {
              onChange("cellPhoneNumber", e.target.value);
              const code = e.target.value?.trim().replace(/ *\([^)]*\) */g, "").replace('+', "");
              onError("cellPhoneNumber", Boolean(countryData.allCountries.find((item) => item.dialCode === code)) || Boolean(e.target.value.length < 8));
            }}
          />
          <CVFlattenDateField
            className={clsx(classes.inputWidth169, classes.inputMargin)}
            value={value.birthDate ? moment(value.birthDate, "X") : false}
            label={translate(intl, "signUp.ui.birthday")}
            error={errors.birthDate}
            onChange={e => {
              if (e) {
                onError("birthDate", false);
              }
              onChange("birthDate", moment(e).unix());
            }}
            required
          />
        </Grid>

        {/* <Grid justify="flex-end" alignContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            className={classes.saveButton}
          >
            {translate(intl, "common.ui.save")}
          </Button>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default ApplicationForm;
