import React, { useContext } from "react";
import { IntlProvider } from "react-intl";
import { en, ko } from "locales";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import { AppContextProvider } from "context";
import { AppContext } from "context/AppContext";
import { SnackbarProvider } from "notistack";

import { useLanguage } from "utils/language";
import { RouteContextProvider } from "context";
import { ApolloProvider } from "components";

const useStyles = makeStyles(() => ({
  root: { width: "100vw" },
  containerAnchorOriginBottomCenter: {}
}));

const NeedAppContext = props => {
  const { children } = props;
  const classes = useStyles();
  const { appContext } = useContext(AppContext);
  const [language] = useLanguage();
  const messages = { ko: ko, en: en };

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <ThemeProvider theme={createMuiTheme(appContext.theme)}>
        <SnackbarProvider
          classes={{
            root: classes.root,
            containerAnchorOriginBottomCenter:
              classes.containerAnchorOriginBottomCenter,
          }}
          maxSnack={3}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          {' '}
        {children}
        </SnackbarProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

const ContextProvider = props => {
  const { children } = props;

  return (
    <AppContextProvider>
      <RouteContextProvider>
        <ApolloProvider>
          <NeedAppContext children={children} />
        </ApolloProvider>
      </RouteContextProvider>
    </AppContextProvider>
  );
};

export default ContextProvider;
