// import { useMutation, useQuery } from '@apollo/react-hooks';
import Grid from "@material-ui/core/Grid";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CVPasswordField, enqueueToast } from "components";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { CCDialogInputError, CCDialogSimple } from "styles/src/components";
import CCButton from "styles/src/components/CCButton";
import { validPassword } from "utils/password";
import { useIntl } from "react-intl";

const GET_LOGIN_STAFF = gql`
  query getLoginStaff {
    user: getLoginStaff {
      ... on RetStaff {
        id
        loginId
        name
      }
    }
  }
`;

const UPDATE_STAFF = gql`
  mutation changeStaffPassword(
    $hospitalId: String!
    $id: String!
    $newPassword: String!
    $oldPassword: String
  ) {
    changeStaffPassword(
      hospitalId: $hospitalId
      id: $id
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      ... on RetLoginStaff {
        token
      }
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangeManagerPassword($oldPassword: String, $managerId: String!, $newPassword: String!) {
    changeManagerPassword(managerId: $managerId, newPassword: $newPassword, oldPassword: $oldPassword) {
      code
      message
    }
  }
`;

const PasswordChangeDialog = props => {
  const { open, onClose = () => {}, forceOpen = false } = props;
  const currentPassword = useRef();
  const newPassword = useRef();
  const managerId = localStorage.getItem('managerId');
  const confirmPassword = useRef();
  const [inputError, setInputError] = useState(null);
  const [signInError, setSiginError] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const intl = useIntl();
  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: data => {
      console.log("changePassword", data);
    }
  });
  // const { enqueueSnackbar } = useSnackbar();

  // const { data } = useQuery(GET_LOGIN_STAFF, {
  //   fetchPolicy: 'network-only',
  // });

  // const [updateStaff] = useMutation(UPDATE_STAFF, {
  //   onCompleted: () => {
  //     enqueueToast(enqueueSnackbar, '비밀번호가 변경되었습니다.');
  //     onClose && onClose();
  //   },
  //   onError: ({ graphQLErrors }) => {
  //     graphQLErrors.forEach(({ message, locations, path, extensions }) => {
  //       console.log(extensions.code);
  //       switch (extensions.code) {
  //         case 'AUTH02':
  //           setSiginError(true);
  //           enqueueToast(enqueueSnackbar, '현재비밀번호가 잘못되었습니다.');
  //           break;
  //         case 'AUTH03':
  //         case 'AUTH04':
  //           setInputError(extensions.code);
  //           enqueueToast(
  //             enqueueSnackbar,
  //             '비밀번호 입력이 5회 연속 실패하여 계정이 잠금처리되었습니다.',
  //           );
  //           break;
  //         default:
  //           enqueueToast(enqueueSnackbar, '비밀번호 변경이 실패하였습니다.');
  //           break;
  //       }
  //     });
  //   },
  // });

  return (
    <>
      <CCDialogSimple
        title={intl.formatMessage({
          id: "word.language",
          defaultMessage: "Change Password"
        })}
        open={open}
        onClose={forceOpen ? () => {} : () => onClose && onClose(false)}
        onCloseButton={forceOpen ? null : () => onClose && onClose(false)}
        contents={
          <form>
            <input type={"hidden"} value={""} autoComplete={"username"} />
            <Grid container direction={"column"} style={{ overflow: "hidden" }}>
              <Grid item>
                <CVPasswordField
                  type={"password"}
                  label={"Current Password"}
                  inputRef={currentPassword}
                  variant={"outlined"}
                  margin={"dense"}
                  InputProps={{
                    inputProps: {
                      autoComplete: "current-password"
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <CVPasswordField
                  type={"password"}
                  label={intl.formatMessage({
                    id: "word.language",
                    defaultMessage: "New Password"
                  })}
                  // label={'New Password'}
                  inputRef={newPassword}
                  variant={"outlined"}
                  margin={"dense"}
                  InputProps={{
                    inputProps: {
                      autoComplete: "new-password"
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <CVPasswordField
                  type={"password"}
                  // label={"Verify Password"}
                  label={intl.formatMessage({
                    id: "word.language",
                    defaultMessage: "Verify Password"
                  })}
                  inputRef={confirmPassword}
                  variant={"outlined"}
                  margin={"dense"}
                  InputProps={{
                    inputProps: {
                      autoComplete: "new-password"
                    }
                  }}
                />
              </Grid>
            </Grid>
          </form>
        }
        endActions={
          <>
            {!forceOpen && (
              <CCButton variant={"text"} onClick={() => onClose()}>
                {intl.formatMessage({
                    id: "word.language",
                    defaultMessage: "Cancel"
                  })}
              </CCButton>
            )}

            <CCButton
              variant={"contained"}
              onClick={() => {
                if (
                  !Boolean(newPassword.current.value) ||
                  !Boolean(confirmPassword.current.value) ||
                  !validPassword(newPassword.current.value) ||
                  newPassword.current.value !== confirmPassword.current.value
                ) {
                  setInputError("CLM_PASSWORD01");
                  return true;
                }

                if (
                  currentPassword.current.value === newPassword.current.value
                ) {
                  setInputError("CLM_PASSWORD02");
                  return true;
                }

                changePassword({
                  variables: {
                    oldPassword: currentPassword.current.value,
                    newPassword: newPassword.current.value,
                    managerId,
                  }
                })
                  .then((res) => {
                    const { data: { changeManagerPassword: {code, message }} } = res;
                    if(code === 'PASSWORD02') {
                      setInputError('PASSWORD02');
                      return true;
                    }
                    onClose();
                  })
                  .catch(error => {
                    const { graphQLErrors } = error;
                    console.log("graphQLErrors ->>>", graphQLErrors);
                    onClose();
                    if (graphQLErrors) {
                      graphQLErrors.forEach(graphQLError => {
                        // setError(graphQLError?.message);
                      });
                    }
                  });
                // onClose();
                
                // updateStaff({
                //   variables: {
                //     hospitalId: localStorage.getItem('labId'),
                //     id: data?.user?.id,
                //     oldPassword: currentPassword.current.value,
                //     newPassword: newPassword.current.value,
                //     log: {
                //       category: LOG_CATEGORY.STAFF.value,
                //       contents: `${data.user?.name} 직원 비밀번호가 수정되었습니다.`,
                //     },
                //   },
                // });
              }}
            >
              {intl.formatMessage({
                id: "word.language",
                defaultMessage: "Save"
              })}
              {/* {"Save"} */}
              
            </CCButton>
          </>
        }
      />
      <CCDialogInputError
        title={"현재 비밀번호가 잘못되었습니다."}
        open={Boolean(signInError)}
        onClose={() => setSiginError(false)}
      />

      <CCDialogInputError
        title={
          inputError === "CLM_PASSWORD01" ? (
            intl.formatMessage({
              id: "CLM_PASSWORD01",
              defaultMessage: "Password must be over 8 characters and contain alphabet, numbers, special characters."
            })
          ) : inputError === "PASSWORD02" ? (
            intl.formatMessage({
              id: "PASSWORD02",
              defaultMessage: "Current password incorrect"
            })
          ) : inputError === "CLM_PASSWORD02" ? (intl.formatMessage({
            id: "CLM_PASSWORD02",
            defaultMessage: "Your new password cannot be the same as your old password"
          })) : ("Success")

          
          // intl.formatMessage({
          //   id: "word.language",
          //   defaultMessage: "Change Password"
          // })
          
          // inputError === "AUTH03" ? (
          //   <>
          //     비밀번호 입력이 5회 연속 실패하여 계정이 잠금처리되었습니다.{" "}
          //     <br />
          //     잠금 해제를 위해 고객센터로 문의주세요.​ <br />
          //     (고객센터 1577-1577)​
          //   </>
          // ) : inputError === "AUTH04" ? (
          //   <>
          //     비밀번호 입력이 5회 연속 실패하여 계정이 잠금처리되었습니다.{" "}
          //     <br />
          //     대표자 권한 사용자에게 환경설정&gt;직원설정 메뉴를 통해 비밀번호
          //     초기화를 요청하세요.​
          //   </>
          // ) : (
          //   "Password must be over 8 characters and contain alphabet, numbers, special characters."
          // )
        }
        open={Boolean(inputError)}
        onClose={() => setInputError(null)}
      />
    </>
  );
};

export default PasswordChangeDialog;
