const hasLowerCase = (str) => {
  return str.toUpperCase() != str;
}

const hasNumber = (str) => {
  return /\d/.test(str);
}

const validManageName = (name) => {
  const stringLength = name.length;

  if(stringLength < 4 || stringLength > 20) {
    return false;
  }

  if(! hasLowerCase(name)) {
    return false;
  }

  if(! hasNumber(name)) {
    return false;
  }

  return true;
}


export {
  validManageName
}