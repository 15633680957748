import React, { useEffect, useState } from "react";

import CVTextField from "../CVTextField";
import PropTypes from "prop-types";
// import IMaskInput from "react-imask/esm/input";
import {IMaskInput} from 'react-imask';

// import {
//   formatPhoneNumber
//   // isValidPhoneNumber
// } from "react-phone-number-input";

const phoneNumberMask = props => (
  <IMaskInput {...props} mask={[{ mask: "00000000000000000000" }]} />
);

// const isValidPhoneNumber = value => {
//   return value && value.length > 6;
// };

const PhoneNumberFieldNoMask = props => {
  const {
    permission,
    value,
    onChange,
    margin,
    variant,
    label,
    required,
    errorOnChange,
    ...others
  } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    errorOnChange(error);
  }, [errorOnChange, error]);

  // const COUNTRY_CODE = process.env.PHONE_COUNTRY_CODE
  //   ? process.env.PHONE_COUNTRY_CODE
  //   : "+84";

  useEffect(() => {
    const phoneValue = value.replace(/[^\d]/g, "");
    if (phoneValue) {
      setError(false);
    } else {
      setError(true);
    }
    required && phoneValue === "" && setError(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, required]);

  const handlePhoneField = name => e => {
    const phoneNumber = e.target.value.replace(/[^\d]/g, ""); // 숫자만 따로 추출
    // const vietnamPhoneNumber = COUNTRY_CODE.concat(phoneNumber);
    onChange({ target: { value: phoneNumber } });
    // // replace 84 -> 0 when type 84
    // const vnStandardPhoneNumber = phoneNumber.startsWith("84")
    //   ? "0" + phoneNumber.substring(2)
    //   : phoneNumber;

    // if (
    //   required &&
    //   name === "onBlur" &&
    //   (!phoneNumber || !isValidPhoneNumber(vietnamPhoneNumber))
    // ) {
    //   setError(true);
    //   return;
    // }

    // phoneNumber.length >= 9
    //   ? onChange({
    //       target: {
    //         value: isValidPhoneNumber(phoneNumber)
    //           ? formatPhoneNumber(vietnamPhoneNumber).replace(/[^\d]/g, "")
    //           : e.target.value
    //       }
    //     })
    //   : onChange({ target: { value: vnStandardPhoneNumber } });
  };

  return (
    <CVTextField
      permission={permission}
      margin={margin}
      variant={variant}
      label={label}
      onChange={handlePhoneField("onChange")}
      error={error}
      required={required}
      value={value || ""}
      onBlur={handlePhoneField("onBlur")}
      InputProps={{
        inputProps: {
          maxLength: 20
        },
        inputComponent: phoneNumberMask
      }}
      {...others}
    />
  );
};

PhoneNumberFieldNoMask.defaultProps = {
  value: "",
  margin: "dense",
  variant: "outlined",
  label: "전화번호",
  required: false,
  errorOnChange: () => {}
};

PhoneNumberFieldNoMask.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  errorOnChange: PropTypes.func
};

export default PhoneNumberFieldNoMask;
