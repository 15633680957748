const USER_STATUS_TYPES = {
  IN_USE: {
    value: "0",
    localeKey: "user.status.inUse"
  },
  DISCONTINUED: {
    value: "1",
    localeKey: "user.status.temporarySuspended"
  },
  WITHDRAW: {
    value: "2",
    localeKey: "user.status.withdraw"
  },
  WITH_DRAW_AFTER_ONE_MONTH: {
    value: "3",
    localeKey: "user.status.withdrawAfterOneMonth"
  }
  // TEMPORARY_SUSPENSION: {
  //   value: "4",
  //   localeKey: "user.status.temporarySuspended"
  // }
};

const USER_STATUS_FILTER = {
  ALL: {
    value: "9",
    localeKey: "user.status.all"
  },
  IN_USE: {
    value: "0",
    localeKey: "user.status.inUse"
  },
  DISCONTINUED: {
    value: "1",
    localeKey: "user.status.temporarySuspended"
  }
  // WITHDRAW: {
  //   value: "2",
  //   localeKey: "user.status.withdraw"
  // }
};

const BASE_USER_STATUS = {
  IN_USE: "0",
  DISCONTINUED: "1", // ~ Front: temporarySuspended
  WITH_DRAW: "2",
  WITH_DRAW_AFTER_ONE_MONTH: "3"
};

const WITHDRAW_TYPES = {
  NOW: {
    value: "0",
    localeKey: "customer.withdraw.now"
  },
  AFTER_ONE_MONTH: {
    value: "1",
    localeKey: "customer.withdraw.afterOneMonth"
  }
};

const DETAIL_REFERENCE_DATA = [
  {
    key: "labId",
    label: "Lab Id",
    order: 1
  },
  {
    key: "staffId",
    label: "User Id",
    order: 2
  },
  {
    key: "labName",
    label: "Lab Name",
    order: 3
  },
  {
    key: "labTelNumber",
    label: "Tel No.",
    order: 4
  },
  {
    key: "email",
    label: "E-mail",
    order: 5
  },
  {
    key: "address",
    label: "Address",
    order: 6
  },
  {
    key: "erc",
    label: "BRN",
    order: 7
  },
  {
    key: "managerName",
    label: "Manager",
    order: 8
  },
  {
    key: "managerCellNumber",
    label: "Cell no.",
    order: 9
  },
  {
    key: "birthDate",
    label: "Birth Date",
    order: 10
  },
  {
    key: "registrationStatus",
    label: "Registration Status",
    order: 11
  },
  {
    key: "numberUserAccount",
    label: "Number of Users Account",
    order: 12
  }

  // {
  //   key: 'status',
  //   order: 10,
  // },
];

export {
  USER_STATUS_FILTER,
  USER_STATUS_TYPES,
  WITHDRAW_TYPES,
  DETAIL_REFERENCE_DATA,
  BASE_USER_STATUS
};
