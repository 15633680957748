import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { CCTextField } from "styles/components";
import { SearchIcon } from "styles/icons";

import { useDebounce } from "utils/utils";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  }
}));

const DELAY = 500;

const SearchInput = props => {
  const { onComplete } = props;
  const classes = useStyles();

  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, DELAY);

  useEffect(() => {
    onComplete(debouncedSearchText.trim());
  }, [debouncedSearchText, onComplete]);

  return (
    <CCTextField
      className={classes.root}
      variant="outlined"
      margin="dense"
      label="Search"
      value={searchText}
      endIcon={<SearchIcon />}
      onChange={e => {
        setSearchText(e.target.value);
      }}
    />
  );
};

export default SearchInput;
