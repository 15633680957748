import { flatten } from "flat";
import { ko as koStyles } from "styles/locales";
const word = {
  language: "언어",
  en: "영어",
  ko: "한국어",
  name: "이름",
  hospital: "병원",
  id: "아이디",
  password: "비밀번호",
  login: "Clever 로그인",
  tabletLogin: "태블릿 접수 로그인",
  desk: "데스크",
  reservation: "예약",
  customerManagement: "고객관리",
  notificationManagement: "Notice",
  statistics: "경영/통계",
  chart: "차트",
  photoView: "사진보기",
  dentalLab: "기공의뢰",
  OralExamination: "구강검진",
  claim: "보험청구",
  signAndLog: "서명/로그",
  setting: "설정",
  zalo: "zalo",
  "reservation-evn": "reservation",
  total: "전체",
  download: "내려받기",
  noselection: "선택없음",
  test: "테스트",
  jobTypes: {
    dentist: "치과의사",
    dentalHygienist: "치과위생사",
    radiologist: "방사선사",
    nursingAssistant: "간호조무사",
    dentalConstruction: "치과기공사",
    coordinator: "코디네이터",
    other: "기타",
    doctor: "의사"
  },
  jobPosition: {
    medicalTeamMembers: "진료팀원",
    headOfMedicalTeam: "진료팀장",
    mount: "실장",
    headOfBusiness: "경영실장",
    payDoctor: "페이닥터",
    substituteDoctor: "대진의",
    ledger: "원장",
    president: "대표원장",
    partTimeJob: "아르바이트",
    other: "기타"
  },
  charge: {
    noChoice: "선택없음",
    doctorInCharge: "담당의사",
    staffInCharge: "담당직원"
  },
  status: {
    working: "재직중",
    leave: "휴직",
    parentalLeave: "육아휴직",
    other: "기타",
    retire: "퇴사"
  },
  permission: {
    noPermissions: "권한없음",
    representatives: "대표자",
    manager: "관리자",
    general: "일반",
    payDoctor: "페이닥터"
  },
  permissionCategory: {
    desk: "데스크",
    reservation: "예약",
    chart: "차트",
    photoView: "사진보기",
    oralExam: "구강검진",
    labOrder: "기공의뢰",
    insurance: "보험청구",
    electronicSignatureAndLog: "서명/로그(상세권한설정)",
    electronicSignature: "일괄전자서명",
    medicalRecords: "진료기록",
    diagnosticRadiationRecords: "진단용 방사선 발생장치 사용기록",
    personalInformation: "개인정보 파기 및 관리대장",
    coPaymentManagement: "본인부담금 관리대장",
    checkAccessLog: "접속로그 점검",
    log: "로그관리",
    workingLog: "작업로그",
    purchaseLog: "수납로그",
    permissionChangeLog: "권한변경로그",
    customerManagement: "고객관리",
    ManagementStatistics: "경영통계",
    cashAndBudgetManagement: "현금 예산관리",
    monthlyBudgetManagement: "예산관리(월별 순수익 관리)",
    preferences: "환경설정",
    etc: "기타(상세권한설정)",
    permissionSetting: "권한설정",
    staffSetting: "직원설정",
    accreditedCertificate: "공인인증서",
    salesManagement: "매출관리",
    dailyBudgetManagement: "현금관리(일일)",
    patientManagementStatistics: "환자관리 통계",
    hospitalSetting: "병원설정",
    workTimeSetting: "진료시간",
    jxSetting: "JX 설정",
    discountSetting: "고객 할인율",
    prescriptionSetting: "처방묶음",
    treatButton: "진료묶음",
    wireSetting: "교정와이어",
    maSetting: "전신질환",
    fileSetting: "File 설정",
    fixtureSetting: "Fixture 설정",
    ccSetting: "CC 설정",
    treatmentPlanSetting: "치료계획 설정",
    radioGraphDecodeSetting: "방사선 사진 판독문 설정",
    oeSetting: "O/E 설정",
    uninsuranceActionSetting: "비급여수가 설정",
    checkInsurance: "급여수가 조회",
    nextSetting: "Next 설정",
    patientSetting: "환자정보 설정",
    etcIncomeAndOutgoingSetting: "기타 수입 설정",
    schoolSetting: "학교설정",
    bankAccountSetting: "청구계좌(학교검진) 설정",
    oralExamPhraseSetting: "구강검진 문구설정",
    labSetting: "기공소 설정",
    crownSetting: "기공물 종류 설정",
    requestDetailSetting: "기공물 상세요청사항 설정",
    smtpSetting: "SMTP 설정",
    smsSetting: "SMS 문구 설정",
    entrustedInstitutionSetting: "수탁기관 설정",
    partnerSetting: "거래처 설정",
    ktCallManagerAPISetting: "API 설정",
    popBillSMSSetting: "SMS 설정",
    otherSetting: "기타설정"
  },
  payBank: {
    shinhanBank: "신한은행",
    kookminBank: "국민은행",
    wooriBank: "우리은행",
    hanaBank: "하나은행",
    scFirstBank: "SC제일은행",
    koreaCityBank: "한국시티은행",
    agriculturalCooperation: "농협",
    siouxCooperation: "수협",
    industrialBank: "산업은행",
    exportImportBankOfKorea: "한국수출입은행",
    corporateBank: "기업은행",
    gyeongnamBank: "경남은행",
    gwangjuBank: "광주은행",
    daeguBank: "대구은행",
    busanBank: "부산은행",
    jeonbukBank: "전북은행",
    jejuBank: "제주은행"
  },
  receiptStatus: {
    receiptSelect: "환자선택",
    receipt: "접수",
    treatmentInProgress: "진료중",
    treatmentComplete: "진료완료",
    storageComplete: "수납완료",
    receiptDelete: "환자삭제",
    checkUp: "검진"
  },
  scheduleType: {
    vacation: "오프",
    morningHalfVacation: "오전 오프",
    afternoonHalfVacation: "오후 오프",
    seminar: "세미나/학회",
    goingOut: "외출",
    visit: "방문",
    etc: "기타"
  },
  scheduleStatus: {
    implementation: "이행",
    notImplementation: "미이행",
    cancel: "취소",
    hold: "보류"
  },
  scheduleContextMenu: {
    registration: "새 일정",
    modify: "일정수정",
    implementation: "일정이행",
    notImplementation: "일정미이행",
    hold: "일정보류",
    cancel: "일정취소",
    delete: "일정삭제"
  },
  patientStatus: {
    reservation: "예약",
    implementation: "이행",
    notImplementation: "미이행",
    receipt: "접수",
    cancel: "취소",
    hold: "보류"
  },
  patientContextMenu: {
    reservation: "새 예약",
    modify: "예약수정",
    select: "환자선택",
    implementation: "예약이행",
    receipt: "바로접수",
    cancel: "예약취소",
    hold: "예약보류",
    delete: "예약삭제"
  },
  oralexamType: {
    general: "일반검진",
    allLife: "생애검진",
    infantEighteenToTwentyNine: "영유아 18-29",
    infantFortyTwoToFiftyThree: "영유아 42-53",
    infantFiftyFourToSixtyFive: "영유아 54-65",
    outOfSchool: "학교밖",
    noneSelect: "미선택",
    student: "학생"
  },
  oralexamFile: {
    generate: "생성",
    nonGenerate: "미생성"
  },
  oralexamUnit: {
    tooth: "치아",
    plane: "치면",
    double: "두개",
    multiple: "두개이상",
    many: "두개이상"
  },
  oralexamToothType: {
    caries: "우식",
    cariesDoubt: "의심",
    rehabilitate: "수복",
    sealant: "Se",
    normal: "정상"
  },
  patientType: {
    initPatient: "최초",
    newPatient: "신환",
    existingPatient: "구환"
  },
  workingForm: {
    fullTime: "상근",
    partTime: "비상근",
    substituteDoctor: "대진의",
    other: "기타"
  },
  treatmentUnit: {
    perTooth: "치아당",
    perRootCanal: "근관당",
    perHalfJaw: "1/2악당",
    perThirdJaw: "1/3악당",
    perJaw: "악당",
    wholeJaws: "전악"
  },
  docCategories: {
    general: "일반",
    certification: "제증명",
    oralExamination: "구강검진"
  },
  docTypes: {
    questionnaire: "문진표",
    consent: "동의서",
    precaution: "주의사항",
    healthInsurance: "건강보험",
    medicalBenefit: "의료급여",
    docIssue: "제증명",
    oralExamQuestionnaire: "문진표",
    oralExamResults: "결과 통보서"
  },
  docNamesQuestionnaire: {
    newPatientDetail: "신환(상세)",
    newPatientSimple: "신환(간단)",
    medicalHistory: "초진병력",
    implantPatient: "임플란트 환자",
    antiSmokingTreatment: "금연치료",
    jawJointDisease: "턱관절 질환 환자"
  },
  docNamesConsent: {
    dentalSurgery: "발치 및 치과수술",
    rootCanal: "근관치료",
    implant: "임플란트",
    sedationMethod: "진정법 시술",
    denture: "완전(부분) 틀니 진료",
    orthodontics: "교정치료",
    photographConsent: "환자동의(전/후 사진)",
    medicalRecordsAccessConsent: "진료기록 열람 및 사본발급 동의",
    medicalRecordsAccessDelegation: "진료기록 열람 및 사본발급 위임",
    extractionTakingOver: "적출물 인수",
    personalInformationCollection: "개인정보 수집 활용",
    personalInformationThirdParties: "개인정보 제 3자 제공"
  },
  docNamesPrecaution: {
    tartarRemoval: "치석제거 후",
    implant: "임플란트 수술 전/후",
    denture: "완전틀니 사용 시",
    toothExtraction: "발치 후",
    teethWhitening: "미백치료 후",
    rootCanal: "근관치료(신경치료) 후",
    orthodHeadGearFaceBow: "교정-헤드기어와 페이스보우",
    orthodFaceMaskChinCap: "교정-페이스마스크/친캡",
    orthodRubberBand: "교정-구강 내 고무줄",
    orthodFixed: "교정-고정장치",
    orthodRemovableAppliance: "교정-가철식교정장치",
    orthodTransparentAppliance: "교정-투명교정"
  },
  docNamesHealthInsurance: {
    tartarRemovalRegistrationCancel: "치석제거 등록내역 취소 신청",
    dentureRegistration: "틀니 대상자 등록 신청",
    dentureChange: "틀니 대상자 변경/해지/취소 신청",
    dentureMaintenanceCancal: "틀니 유지관리 행위 등록내역 취소 신청",
    implantRegistration: "치과임플란트 대상자 등록 신청",
    implantChange: "치과임플란트 대상자 시술중지/변경/해지/취소 신청"
  },
  docNamesMedicalBenefit: {
    tartarRemovalRegistrationCancel: "치석제거 등록내역 취소 신청",
    dentureRegistration: "틀니 대상자 등록 신청",
    dentureChange: "틀니 대상자 변경/해지/취소 신청",
    dentureMaintenanceCancal: "틀니 유지관리 행위 등록내역 취소 신청",
    implantRegistration: "치과임플란트 대상자 등록 신청",
    implantChange: "치과임플란트 대상자 시술중지/변경/해지/취소 신청"
  },
  docStatus: {
    saved: "저장",
    issued: "발급완료"
  },
  docPurposes: {
    insurance: "보험회사 제출용",
    work: "직장(회사) 제출용",
    school: "학교 제출용",
    military: "군부대 제출용",
    police: "경찰서 제출용",
    court: "법원 제출용",
    treatment: "진료의뢰용",
    etc: "기타"
  },
  treatmentPlan: {
    status: {
      idle: "저장안됨",
      temporaryStorage: "임시저장",
      confirmation: "확정",
      beScheduled: "예정",
      hold: "보류",
      cancel: "취소",
      completion: "완료",
      closing: "치료종결"
    }
  },
  photoActions: {
    tags: "태그관리",
    rename: "이름변경",
    other: "파일이동",
    delete: "삭제",
    detail: "상세보기",
    save: "원본 저장",
    saveas: "다른 이름으로 저장",
    download: "내려받기"
  },
  photoTypes: {
    all: "모두",
    panorama: "Panorama",
    pa: "PA",
    cephalo: "Cephalo",
    capture: "Capture",
    oralphoto: "구강포토",
    image: "일반 이미지",
    document: "문서",
    other: "기타"
  },
  photoFilters: {
    brightness: "밝기",
    saturation: "명도",
    contrast: "대조",
    invert: "색반전"
  },
  consultActions: {
    frompc: "PC에서 가져오기"
  },
  cavityInput: {
    mesical: "근심면",
    buccal: "순협면",
    distal: "원심면",
    lingual: "설면",
    occlusal: "교합면"
  },
  relationship: {
    myself: "본인",
    spouse: "배우자",
    child: "자녀",
    parent: "부모",
    brothers: "형제",
    grandparents: "조부모",
    grandchildren: "손주",
    relatives: "친척",
    work: "직장 동료",
    friend: "친구",
    school: "선후배",
    family: "가족",
    religion: "종교",
    other: "기타"
  },
  hospitalInfo: {
    type: {
      clinic: "의원",
      cityHospital: "병원(시,도)",
      townHospital: "병원(읍,면)",
      cityGeneralHospital: "종합병원(시,도)",
      townGeneralHospital: "종합병원(읍,면)"
    }
  },
  docNamesDocIssue: {
    diagnosis: "진단서",
    injuryDiagnosis: "상해 진단서",
    treatmentConfirmation: "치료 확인서",
    operationConfirmation: "수술 확인서",
    treatmentRequest: "진료 의뢰서",
    treatmentPlan: "치료 계획서",
    doctorNote: "소견서",
    ambulatoryConfirmation: "통원 진료 확인서",
    expenseEstimation: "향후 치료비 추정서",
    militaryDiagnosis: "병무용 진단서",
    radiationOpinion: "방사선 필름 판독 소견서",
    visitingNursingDirections: "방문 간호 지시서",
    medicalBenefitRequest: "의료 급여 의뢰서",
    treatmentResponse: "진료 의뢰 회신서",
    medicalRecord: "진료기록부"
  },
  labOrder: {
    newRequest: "새의뢰",
    modifyRequest: "수정제작",
    reRequest: "재제작",
    tempSave: "임시저장",
    requestCompleted: "의뢰완료",
    cadDesign: "CAD Design",
    confirmRequest: "컨펌 요청",
    confirmComplete: "컨펌 완료",
    working: "작업중",
    complete: "작업완료",
    delivery: "발송완료",
    non: "선택없음",
    arrive: "기공물 도착",
    nonArrive: "기공물 미도착",
    check: "확인",
    nonCheck: "미확인",
    clever: "클래버 기공소",
    nonClever: "일반 기공소",
    non1: " ",
    arrive1: "도착",
    nonArrive1: "미도착",
    detail: "상세정보"
  },
  consulting: {
    importantFactor: {
      cost: "가격",
      visitCount: "방문 횟수",
      aesthetic: "심미성",
      dentalFear: "치과공포",
      skills: "원장님 실력",
      services: "서비스( 친절응대 등 )"
    }
  },
  oralExam: {
    phraseSetting: {
      dataType: {
        list: "",
        accordingToSchool: ""
      },
      school: {
        elementary: "초등학생",
        middle: "중학생",
        high: "고등학생"
      },
      category: {
        infant: {
          default: "영유아검진",
          etc: "기타부위 검사소견",
          additional: "추가 조치사항",
          analysis: "결과 해석"
        },
        normalAndWholeLife: {
          default: "일반/생애검진",
          actions: "추가 조치사항",
          activeManagement: "결과 해석"
        },
        studentCheckup: {
          default: "학생검진",
          comprehensiveOpinion: "종합소견",
          homeAdvice: "가정 조치사항",
          developmentDirection: "구강보건 발전방향"
        },
        outOfSchool: {
          default: "학교 밖 청소년",
          actions: "소견 및 조치 사항"
        }
      }
    }
  },
  uninsurance: {
    actions: {
      unit: {
        perTooth: "치아당",
        perJaw: "악당(상,하악)",
        perHalfJaw: "1/2악당",
        perThirdJaw: "1/3악당",
        perOral: "1구강당(1일 1회)",
        standardShooting: "표준촬영(14매)"
      },
      details: {
        implant: "임플란트",
        denture: "틀니",
        childrenAndPrevention: "소아, 예방",
        correction: "교정",
        others: "기타",
        beauty: "미용(과세)",
        listedUninsurance: "등재비급여",
        preservation: "보존",
        prothesis: "보철"
      }
    }
  },
  treat: {
    action: "행위",
    material: "재료",
    uninsured: "비급여",
    medicine: "약제"
  },
  logCategory: {
    patient: "환자",
    docIssue: "docIssue",
    reservation: {
      patient: "진료예약",
      schedule: "병원일정"
    },
    receipt: "접수",
    uninsuranceSetting: "비급여수가설정",
    chart: {
      tx: "차트(TX)",
      nx: "차트(NX)",
      oe: "차트(OE)",
      pl: "차트(PL)",
      ma: "차트(MA)",
      cc: "차트(CC)",
      rx: "차트(RX)",
      di: "차트(DI)",
      xr: "차트(XR)",
      mm: "차트(MM)",
      ps: "차트(PS)"
    },
    payment: "수납",
    labOrder: "기공의뢰",
    staff: "직원설정",
    permission: "권한설정",
    connection: "접속",
    connectionLogLedger: "접속로그 점검대장",
    destructionLogLedger: "개인정보 파기대장"
  },
  oralExamState: {
    oralExaminationUnentered: "문진표 미입력",
    oralResultsUnentered: "결과서 미입력",
    completedScreening: "검진완료"
  },
  patient: {
    male: "남",
    female: "여"
  }
};

const log = {
  duplicated: `{name}는 이미 존재합니다.({name}: {id})`,
  invalidPassword: `패스워드가 틀렸습니다.`
};

const ko = flatten({
  ...koStyles,
  ...word,
  ...log
});

export default ko;
