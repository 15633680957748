import React from "react";
import { useQuery } from "@apollo/client";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid } from "@material-ui/core";
import { CCDialogSimple, CCTypography } from "styles/components";

import { GET_LAB_LIST, GET_RECOMMENDED_LAB_LIST } from "queries/customer";
import { LabRow, SearchInput } from "./components";

const useStyles = makeStyles(theme => ({
  root: { height: 800, display: "flex", flexDirection: "column" },
  search_box: {
    paddingLeft: 8,
    width: 300
  },
  search_input: {},
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "rgba(207, 216, 220, 0.87)"
  },
  item__recommended: {
    marginTop: 12,
    height: 250
  },
  item__labs: {
    height: 486
  },
  table__header: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    padding: 8,
    backgroundColor: theme.palette.common.white
  },
  table__content: {
    overflowY: "auto",
    height: "100%"
  }
}));

const LIMIT = 100000;

const LabSearchModal = props => {
  const { open, onClose } = props;
  const classes = useStyles();

  const {
    data: dataRecommendedLab,
    loading: loadingRecommendedLab,
    refetch: refetchRecommendedLab
  } = useQuery(GET_RECOMMENDED_LAB_LIST, {
    variables: {
    }
  });

  const {
    data: datLabList,
    loading,
    error,
    refetch: refetchLabList
  } = useQuery(GET_LAB_LIST, {
    variables: {
      input: {
        limit: LIMIT
      }
    }
  });

  console.log("loadingRecommendedLab", loadingRecommendedLab);

  console.log("dataRecommendedLab", dataRecommendedLab)

  const handleOnSearchComplete = searchText => {
    // refetchRecommendedLab({});
    refetchLabList({
      input: {
        limit: LIMIT,
        searchText
      }
    });
    refetchRecommendedLab(
      { searchText }
    )
  };

  if (error) return <div>Error</div>;

  return (
    <CCDialogSimple
      maxWidth="md"
      fullWidth
      open={open}
      contentsPadding={false}
      title={"Clever Lab Search"}
      onCloseButton={() => {
        onClose();
      }}
      onClose={() => {
        onClose();
      }}
      contents={
        <Grid className={classes.root} container>
          <Grid item className={classes.search_box}>
            <SearchInput onComplete={handleOnSearchComplete} />
          </Grid>

          {!loadingRecommendedLab &&
            (
              <Grid
                item
                className={clsx(classes.table, classes.item__recommended)}
              >
                <Box className={classes.table__header}>
                  <CCTypography variant="h4">Recommended</CCTypography>
                </Box>

                <Box className={classes.table__content}>
                  {dataRecommendedLab?.recommendedLabList.length ? dataRecommendedLab.recommendedLabList.map(lab => (
                    <LabRow
                      key={lab.id}
                      labId={lab.labId}
                      avatar={lab.avatar}
                      labName={lab.labName}
                      specialties={lab.specialties}
                      statusMessage={lab.statusMessage}
                    />
                  )) : ' '}
                </Box>
              </Grid>
            )}

          <Grid item className={clsx(classes.table, classes.item__labs)}>
            <Box className={classes.table__header}>
              <CCTypography variant="h4">Clever Labs</CCTypography>
            </Box>
            {!loading && (
              <Box className={classes.table__content}>
                {datLabList.labList.items.map(lab => (
                  <LabRow
                    key={lab.id}
                    labId={lab.labId}
                    avatar={lab.avatar}
                    labName={lab.labName}
                    specialties={lab.specialties}
                    statusMessage={lab.statusMessage}
                  />
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

export default LabSearchModal;
