/*eslint no-loop-func: 0*/
/*eslint no-unused-vars: 0*/

var photoDefs = [];

["sheets/photos.json", "sheets/consults.json", "sheets/libraries.json"].forEach(
  defs => {
    fetch(defs)
      .then(res => res.text())
      .then(res => {
        // photoDefs = photoDefs.concat(JSON.parse(res));
      });
  }
);

const getDefaultFolders = (category, patient = null) => {
  if (patient) {
    let res = photoDefs.filter(
      item =>
        item.type === category &&
        item.content.filetype === "folder" &&
        item.content.fileattr.includes("readonly")
    );
    res.forEach(item => {
      item["patient"] = patient;
    });
    return res;
  } else {
    return photoDefs.filter(
      item =>
        item.type === category &&
        item.content.filetype === "folder" &&
        item.content.fileattr.includes("readonly")
    );
  }
};

const getDefaultPhotos = category => {
  return photoDefs.filter(item => item.type === category);
};

const getPhotoById = (photos, id) => {
  if (id) {
    return photos.find(photo => photo.content.filenode === id);
  }

  return null;
};

const getPhotoByName = (photos, name) => {
  if (name) {
    return photos.find(photo => photo.content.filename === name);
  }

  return null;
};

const hasPhotoByFolderId = (photos, id) => {
  if (id) {
    return photos.some(photo => photo.content.folder === id);
  }

  return false;
};

const hasPhotoAttr = (photo, attr) => {
  if (
    photo &&
    photo.content &&
    photo.content.fileattr &&
    photo.content.fileattr.includes(attr)
  )
    return true;

  return false;
};

const getFolderId = target => {
  if (target) {
    return target.content.filenode;
  }

  return "root";
};

const getFolderName = target => {
  if (target) {
    return target.content.filename;
  }

  return "/";
};

const getFolderPath = (photos, target) => {
  if (target) {
    var path = [];

    for (
      var parent = photos.find(
        tt => tt.content.filenode === target.content.filenode
      );
      parent && parent.content.filename !== "/";
      parent = photos.find(tt => tt.content.filenode === parent.content.folder)
    ) {
      path.unshift(parent.content.filename);
    }

    return path.length > 0 ? path.join("/") : "/";
  }

  return "/";
};

const getFolderPathList = (photos, target) => {
  if (target) {
    var path = [];

    for (
      var parent = photos.find(
        tt => tt.content.filenode === target.content.filenode
      );
      parent && parent.content.filename !== "/";
      parent = photos.find(tt => tt.content.filenode === parent.content.folder)
    ) {
      path.unshift(parent.content.filename);
    }

    return path;
  }

  return [];
};

const getFolderPathItemList = (photos, target) => {
  if (target) {
    var path = [];

    for (
      var parent = photos.find(
        tt => tt.content.filenode === target.content.filenode
      );
      parent && parent.content.filename !== "/";
      parent = photos.find(tt => tt.content.filenode === parent.content.folder)
    ) {
      path.unshift(parent);
    }

    return path;
  }

  return [];
};

const getFolderByPath = (photos, path) => {
  var files = ["/", ...path.split("/").slice(1)];
  var folder = null;

  for (var index = 0; index < files.length; index++) {
    folder = photos.find(tt => {
      if (
        folder &&
        folder.content.filenode === tt.content.folder &&
        tt.content.filename === files[index]
      ) {
        return true;
      } else if (
        !folder &&
        !tt.content.folder &&
        tt.content.filename === files[index]
      ) {
        return true;
      }

      return false;
    });
  }

  return folder;
};

const canMoveToFolder = (photos, sourceId, targetId) => {
  if (sourceId === targetId) {
    return false;
  }

  const source = photos.find(tt => tt.content.filenode === sourceId);

  if (source && source.content.folder === targetId) {
    return false;
  }

  for (
    var target = photos.find(tt => tt.content.filenode === targetId);
    target && target.content.filename !== "/";
    target = photos.find(tt => tt.content.filenode === target.content.folder)
  ) {
    if (sourceId === target.content.filenode) {
      return false;
    }
  }

  return true;
};

export {
  getDefaultFolders,
  getDefaultPhotos,
  getPhotoById,
  getPhotoByName,
  hasPhotoByFolderId,
  hasPhotoAttr,
  getFolderId,
  getFolderName,
  getFolderPath,
  getFolderPathList,
  getFolderByPath,
  canMoveToFolder,
  getFolderPathItemList
};
