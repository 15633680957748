import React, { useState, useEffect } from "react";
import { CVTable, DialogWarningConfirm } from "components";
import { makeStyles } from "@material-ui/styles";
import { CCButton, CCTextField } from "styles/components";
import { Grid, MenuItem } from "@material-ui/core";
import { isExpiredWithdrawTime } from "../../helper";
import {
  REGISTRATION_STATUS,
  DETAIL_REFERENCE_DATA
} from "types/registrationManage";
import { mergeArrayByKey } from "utils/array";
import { makeList } from "types";
import moment from "moment";
import { BASE_REGISTRATION_STATUS } from "types/registrationManage";

import { DEFAULT_DATE_TIME_FORMAT } from "types/constants";
const useStyles = makeStyles(theme => ({
  root: {
    // display: "flex",
    // flexDirection: "row",
    // height: "100%",
    minWidth: "500"

    // overflow: "hidden",
    // backgroundColor: theme.palette.common.white
  },
  table__cell: {
    padding: "6px 4px",
    width: "500px",
    backgroundColor: "white",
    borderBottom: "0.2px solid #e7e9eb"
  },
  status__selection: {
    minWidth: "150px"
  }
}));

// address: "aasdfsdfwed"
// createdAt: 1628754488
// email: "vatech@vatech.com"
// id: "6001dfb2-b032-4266-824f-8fe2b7434b9f"
// labId: "vatech13"
// labName: "Vateccc"
// labTelNumber: "0101500050"
// managerCellNumber: "01050505151"
// managerName: "sososo"
// status: 0
// acceptedAt: Number,
// refusedAt: Number,

const reformatDetailLabData = labInformation => {
  const arr2 = [];

  for (const key in labInformation) {
    arr2.push({
      key,
      value: labInformation[key]
    });
  }
  const resultMerged = mergeArrayByKey(DETAIL_REFERENCE_DATA, arr2, "key");
  return resultMerged;
};

const makeListUpdateBaseCurrentStatus = ({
  registrationStatusList = [],
  currentStatus = "1",
  withdrawAt
}) => {
  const {
    WAITING,
    APPROVED,
    REFUSED,
    WITHDRAW,
    CANCEL_WITHDRAW,
    PROCESSING_TERMINATED,
    COMPLETE_TERMINATED,
    CANCEL_TERMINATED
  } = BASE_REGISTRATION_STATUS;

  let statusList = [];
  if (currentStatus === WAITING) {
    statusList = registrationStatusList.filter(o =>
      [WAITING, APPROVED, REFUSED].includes(o.key)
    );
  }

  if (currentStatus === REFUSED) {
    statusList = registrationStatusList.filter(o => [REFUSED].includes(o.key));
  }

  if (currentStatus === APPROVED) {
    statusList = registrationStatusList.filter(o => [APPROVED].includes(o.key));
  }

  if (currentStatus === PROCESSING_TERMINATED) {
    statusList = registrationStatusList.filter(o =>
      [CANCEL_TERMINATED, PROCESSING_TERMINATED, COMPLETE_TERMINATED].includes(
        o.key
      )
    );
  }

  if (currentStatus === COMPLETE_TERMINATED) {
    statusList = registrationStatusList.filter(o =>
      [COMPLETE_TERMINATED].includes(o.key)
    );
  }

  if (currentStatus === WITHDRAW) {
    if (isExpiredWithdrawTime(withdrawAt)) {
      statusList = registrationStatusList.filter(o =>
        [WITHDRAW].includes(o.key)
      );
    } else {
      statusList = registrationStatusList.filter(o =>
        [WITHDRAW].includes(o.key)
      );
    }
  }

  return statusList;
};

// const calculateDateBaseStatus = (initStatus, labInformation) => {

//   const { status, acceptedAt, refusedAt, createdAt } = labInformation;

//   console.log("calculateDateBaseStatus.status", initStatus);
//   if(initStatus == APPROVED) {
//     return moment.unix(acceptedAt).format(DEFAULT_DATE_TIME_FORMAT)
//   }

//   if(initStatus == REFUSED) {
//     return moment.unix(refusedAt).format(DEFAULT_DATE_TIME_FORMAT);
//   }

//   return moment.unix(createdAt).format(createdAt)
// }

const RegistrationDetail = props => {
  const { data, onUpdate, onProcessingTerminate } = props;

  const { statusUpdatedAt, currentStatus, withdrawAt } = data;

  const contents = reformatDetailLabData(data);

  const classes = useStyles();
  // let registrationStatusList = makeList(REGISTRATION_STATUS);

  let registrationStatusList = makeListUpdateBaseCurrentStatus({
    registrationStatusList: makeList(REGISTRATION_STATUS),
    currentStatus: String(currentStatus),
    withdrawAt
  });

  const {
    WAITING,
    APPROVED,
    PROCESSING_TERMINATED,
    COMPLETE_TERMINATED,
    REFUSED
  } = BASE_REGISTRATION_STATUS;

  const [status, setStatus] = useState(data.status);

  console.log("data.status", data.status)

  const heads = [
    {
      key: "key",
      className: classes.table__cell,
      component: ({ rowData }) => {
        return <b>{rowData.label}</b>;
      }
    },
    {
      key: "value",
      // label: "Lab Name",
      className: classes.table__cell,
      component: ({ rowData }) => {
        const { key, value } = rowData;
        if (key === "birthDate") {
          return moment.unix(value).format("L");
        }
        return value;
      }
    }
  ];

  /**
   * Handle build status to display
   * @returns { String }
   */
  const makeDisplayStatus = () => {
    const status = data.status;
    const currentStatus = data.currentStatus;
    /**
     * Do not want current status hide when change to PROCESSING_TERMINATED status when current status is approve
     */

    if(currentStatus === APPROVED && status === PROCESSING_TERMINATED) {
      return APPROVED;
    }

    // if(currentStatus === PROCESSING_TERMINATED && status === PROCESSING_TERMINATED) {
    //   return PROCESSING_TERMINATED;
    // }

    return status;
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <CVTable
            classes={classes}
            heads={heads}
            contents={contents}
            del={({ rowIndex }) => {}}
            mod={() => {
              console.log("mod");
            }}
            add={() => {
              console.log("add");
            }}
            sorting={true}
          />
        </Grid>

        {/* <Paper className={classes.paper}>xs=12</Paper> */}

        <Grid container item justify="flex-start" alignItems="center">
          <Grid item xs={6}>
            <b>Registration Status</b>
          </Grid>
          <Grid item container justify="flex-start" alignItems="center" xs={6}>
            <CCTextField
              value={ makeDisplayStatus() }
              label={""}
              disabled={[APPROVED, REFUSED, COMPLETE_TERMINATED].includes(
                String(currentStatus)
              )}
              select
              variant="outlined"
              margin="dense"
              className={classes.status__selection}
              onChange={
                e => {
                  // console.log(
                  //   "RegistrationDetail.target.value",
                  //   e.target.value
                  // );

                  // console.log("status", status)
                  setStatus(e.target.value);
                  onUpdate({ ...data, status: e.target.value });
                }
                // setValue({ ...value, status: e.target.value })
              }
            >
              {registrationStatusList.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </CCTextField>



            ({moment.unix(statusUpdatedAt).format(DEFAULT_DATE_TIME_FORMAT)})
            &nbsp;
            {currentStatus == APPROVED && (
              <CCButton
                variant={"contained"}
                size={"small"}
                color={"normal"}
                onClick={() => {
                  onUpdate({ ...data, status: PROCESSING_TERMINATED })
                }
                  
                }
                // onClick={onUpdate({ ...data, status: PROCESSING_TERMINATED })}
              >
                Processing Terminated
              </CCButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RegistrationDetail;
