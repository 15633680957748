import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import { CCDialogSimple, CCTypography } from "styles/components";
import { CommitmentIcon } from "styles/icons";
import { ProsthesisPrice, Samples, LabInfo } from "./components";
import { GET_LAB_PREVIEW } from "queries/customer";
import { getObject } from "utils/s3";

const useStyles = makeStyles(theme => ({
  root: { height: "calc(100% - 200px)" },
  heading__icon: {
    color: theme.palette.disabled,
    alignItems: "center"
  },
  container: {
    width: "100%",
    height: "calc(100vh - 180px)",
    overflow: "hidden"
  },
  table__container: {
    display: "flex",
    width: "100%",
    padding: "0px 16px"
  },
  panel: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "100%",
    height: "474px",
    color: theme.palette.border,
    overflow: "hidden"
  },
  panel__heading: {
    padding: "7px 0px 7px 16px"
  },
  panel__content: {
    height: "100%",
    // height: 'calc(100% - 32px)',
    background: "#cfd8dcde",
    paddingTop: 16,
    paddingBottom: 16,
    width: "100%"
  },
  secondarytext: {
    color: "#b0bec5",
    fontSize: 14
  },
  cell__text__disabled: { color: theme.palette.disabled },
  cell__selected__row: {
    color: theme.palette.common.white
  },
  cell__select: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: "8px"
  },
  text__direct: {
    width: "100%",
    textAlign: "center"
  },
  cell__input: {
    width: 98,
    backgroundColor: theme.palette.common.white,
    textAlignLast: "left"
  },
  icon: {
    color: theme.palette.sub.main,
    padding: "4px",
    margin: "0px"
  },
  empty__body: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.border
  },
  header: {
    width: "100%",
    borderBottom: "1px solid #b0bec5",
    padding: "8px 16px 16px 16px"
  },
  marginbottom8: { marginBottom: 8 },

  positionrelative: {
    position: "relative"
  },
  line: {
    background: "#b0bec5",
    width: " 100%",
    height: 2,
    position: "absolute",
    bottom: 0,
    zIndex: 1
  },
  paddingmiddle: {
    padding: "0 16px "
  },
  boldtitle: {
    fontWeight: "bold",
    padding: "16px 16px 0px 16px"
  },
  avatar: {
    width: 48,
    height: 48,
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: 8
  },
  no_avatar: {
    border: `1px solid ${theme.palette.border.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& svg": {
      color: theme.palette.border
    }
  },
  fullWidth: {
    width: "100%"
  }
}));

const useTabsStyles = makeStyles(theme => ({
  root: {
    minHeight: 24,
    position: "relative",
    backgroundColor: theme.palette.common.white
  },
  scrollButtons: { width: 30 },
  indicator: { color: "#01579b", zIndex: 2 }
}));

const useTabStyles = makeStyles(theme => ({
  root: {
    fontWeight: "bold",
    textTransform: "none"
  }
}));

const LabDetailModal = props => {
  const { open, onClose, labId } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [image, setImage] = useState("");

  const tabsClasses = useTabsStyles();
  const tabClasses = useTabStyles();

  const { data, loading } = useQuery(GET_LAB_PREVIEW, {
    variables: {
      labId
    }
  });

  console.log("data", data);

  const [samples, setSamples] = useState([]);

  console.log("LabDetailModal.sample", samples);

  // console.log("LabDetailModal");

  useEffect(() => {
    if (!loading && data) {
      let func = () => {
        getObject(
          data?.labPreview?.avatar?.target,
          labId,
          data?.labPreview?.avatar?.id
        ).then(res => {
          setImage(res);
        });
      };
      func();
      setSamples(data?.labPreview?.prothesisImages);
    }
  }, [data, props]);

  return (
    <CCDialogSimple
      maxWidth="md"
      fullWidth
      className={classes.root}
      open={open}
      contentsPadding={false}
      title={"Clever Labs"}
      onCloseButton={onClose}
      onClose={onClose}
      contents={
        <Grid className={classes.container} container>
          <Grid item className={classes.fullWidth}>
            <Grid container className={classes.header}>
              {image ? (
                <img
                  src={image}
                  alt={`Avatar-${labId}`}
                  className={classes.avatar}
                />
              ) : (
                <Box className={clsx(classes.avatar, classes.no_avatar)}>
                  <CommitmentIcon />
                </Box>
              )}

              <Grid item>
                <Grid container direction="column">
                  <CCTypography variant="h4" className={classes.marginbottom8}>
                    {data ? data?.labPreview?.labName + " " : " "}
                    <span className={classes.secondarytext}>
                      {data?.labPreview?.specialties}
                    </span>
                  </CCTypography>

                  <CCTypography>{data?.labPreview?.statusMessage}</CCTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.panel__content}>
            <CCTypography
              className={clsx(classes.secondarytext, classes.paddingmiddle)}
            >
              About Us
            </CCTypography>
            <CCTypography className={classes.paddingmiddle}>
              {data?.labPreview?.aboutUs}
            </CCTypography>

            <Grid container direction="column">
              <Box className={classes.positionrelative}>
                <Tabs
                  classes={tabsClasses}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="secondary"
                  value={value}
                  onChange={(e, v) => {
                    setValue(v);
                  }}
                >
                  <Tab classes={tabClasses} label={"Prosthesis"} />

                  <Tab classes={tabClasses} label={"Samples"} />
                  <Tab classes={tabClasses} label={"Lab Info"} />
                </Tabs>
                <div className={classes.line} />
              </Box>

              {!loading &&
                (value === 0 ? (
                  <ProsthesisPrice labId={labId} />
                ) : value === 1 ? (
                  <Samples samples={samples} />
                ) : (
                  <LabInfo data={data?.labPreview} />
                ))}
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

export default LabDetailModal;
