import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useRoute } from "../useRoute";

const Routes = props => {
  const { pages } = props;
  const [route, changeRoute] = useRoute();

  return (
    <Switch>
      {pages.map((page, index) => {
        return (
          <Route
            key={index}
            path={page.path}
            render={props => {
              const Layout = page.layout;
              const Component = page.component;
              const token = sessionStorage.getItem("token");

              if (page.path !== "/signIn") {
                if (!token) {
                  changeRoute("/signIn");
                  return null;
                }
              } else {
                if (token) {
                  changeRoute("/dashboard");
                  return null;
                }
              }

              return (
                <Layout pages={pages} {...props}>
                  <Component {...props} {...route.props} />
                </Layout>
              );
            }}
          />
        );
      })}
      <Redirect from="/" to="/signIn" />
    </Switch>
  );
};

export default Routes;
