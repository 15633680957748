import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { CCIconButton, CCTypography } from "styles/src/components";
import {
    MovedownIcon,
    MoveupIcon
} from "styles/src/themes/common/icons";

const useStyle = makeStyles(
  () => ({
    root: props => {
      if (props.useStepper) {
        return { minWidth: 310, marginTop: 55, marginBottom: 32 };
      } else {
        return { minWidth: 310, marginTop: 115, marginBottom: 32 };
      }
    },
    Input: {
      minWidth: 111,
      height: 71,
      lineHeight: "initial",
      paddingRight: 0
    },
    input: { fontSize: 48, fontWeight: 500 },
    timeText: {
      minWidth: 111,
      height: 71,
      lineHeight: "initial",
      paddingRight: 0,
      fontSize: 48,
      fontWeight: 500
    },
    "@keyframes blink": {
      "50%": { opacity: 0 }
    },
    colon: {
      fontSize: 48,
      color: "#ababab",
      animation: "$blink 2s linear infinite",
      userSelect: "none"
    },
    upDownButton: {
      margin: "0 !important",
      paddingTop: "0 !important",
      paddingBottom: "0 !important"
    },
    textCenter: {
      textAlign: "center"
    }
  }),
  { name: "CCFlattenDateTimePicker-TimePicker-TimeInput" }
);
const useTimeInputStyles = makeStyles(theme => ({
  notchedOutline: {
    border: "none"
  }
}));
const useSelectStyles = makeStyles(theme => ({
  select: {
    "&:focus": {
      backgroundColor: "transparent"
    }
  }
}));

function pad(n, width) {
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join("0") + n;
}

const InputMenuHourItems = function* (count, value, disableMeridiem = true) {
  for (let i = 0; i < count; i++) {
    if (disableMeridiem) {
      yield (
        <MenuItem key={"MenuHourItem" + i} value={i}>
          {pad(i, 2)}
        </MenuItem>
      );
    } else {
      if (value.hours() >= 12) {
        if (i === 11) {
          yield (
            <MenuItem key={"MenuHourItem" + i} value={i + 1}>
              {pad(i + 1, 2)}
            </MenuItem>
          );
        } else {
          yield (
            <MenuItem key={"MenuHourItem" + i} value={i + 1 + 12}>
              {pad(i + 1, 2)}
            </MenuItem>
          );
        }
      } else {
        yield (
          <MenuItem key={"MenuHourItem" + i} value={(i + 1) % 12}>
            {pad(i + 1, 2)}
          </MenuItem>
        );
      }
    }
  }
};

const InputMenuTimeItems = function* (count, step) {
  for (let i = 0; i < count; i++) {
    if (i % step !== 0) continue;
    yield (
      <MenuItem key={"MenuTimeItem" + i} value={i}>
        {pad(i, 2)}
      </MenuItem>
    );
  }
};
const TimeInput = props => {
  const {
    value,
    onChange,
    step,
    stepperHours,
    stepperMinutes,
    disableMeridiem
  } = props;
  const classes = useStyle({
    useStepper: Boolean(Boolean(stepperHours) || Boolean(stepperMinutes))
  });
  const timeInputClasses = useTimeInputStyles();
  const selectClasses = useSelectStyles();
  // console.log(value.hours());
  return (
    <Grid
      className={clsx(classes.root)}
      item
      container
      wrap={"nowrap"}
      justify={"center"}
      alignItems={"center"}
    >
      <Grid item>
        {stepperHours && (
          <Grid
            container
            direction={"column"}
            justify={"center"}
            alignItems={"center"}
          >
            <CCIconButton className={classes.upDownButton}>
              <MoveupIcon
                onClick={() => {
                  onChange(
                    value.clone().hours(
                      value
                        .clone()
                        .add(1, "hours")
                        .hours()
                    )
                  );
                }}
              />
            </CCIconButton>
            <CCTypography
              className={clsx(classes.timeText, classes.textCenter)}
            >
              {disableMeridiem ? value.format("HH") : value.format("hh")}
            </CCTypography>
            <CCIconButton className={classes.upDownButton}>
              <MovedownIcon
                onClick={() => {
                  onChange(
                    value.clone().hours(
                      value
                        .clone()
                        .subtract(1, "hours")
                        .hours()
                    )
                  );
                }}
              />
            </CCIconButton>
          </Grid>
        )}
        {!Boolean(stepperHours) && (
          <TextField
            InputProps={{
              className: clsx(classes.Input),
              inputProps: {
                className: clsx(classes.input)
              },
              classes: timeInputClasses
            }}
            SelectProps={{
              classes: selectClasses
            }}
            value={value.hours()}
            variant={"outlined"}
            select={true}
            onChange={event => {
              onChange(value.clone().hours(event.target.value));
            }}
          >
            {[
              ...InputMenuHourItems(
                disableMeridiem ? 24 : 12,
                value,
                Boolean(disableMeridiem)
              )
            ]}
          </TextField>
        )}
      </Grid>
      <Grid item>
        <Grid container alignItems={"center"}>
          <Typography
            className={clsx(classes.colon)}
            variant={"h2"}
            component={"span"}
          >
            {":"}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        {stepperMinutes && (
          <Grid
            container
            direction={"column"}
            justify={"center"}
            alignItems={"center"}
          >
            <Grid item>
              <Grid container>
                <CCIconButton className={classes.upDownButton}>
                  <MoveupIcon
                    onClick={() => {
                      onChange(
                        value.clone().minutes(
                          value
                            .clone()
                            .add(10, "minutes")
                            .minutes()
                        )
                      );
                    }}
                  />
                </CCIconButton>
                <CCIconButton className={classes.upDownButton}>
                  <MoveupIcon
                    onClick={() => {
                      onChange(
                        value.clone().minutes(
                          value
                            .clone()
                            .add(1, "minutes")
                            .minutes()
                        )
                      );
                    }}
                  />
                </CCIconButton>
              </Grid>
            </Grid>
            <CCTypography
              className={clsx(classes.timeText, classes.textCenter)}
            >
              {value.format("mm")}
            </CCTypography>
            <Grid item>
              <Grid container>
                <CCIconButton className={classes.upDownButton}>
                  <MovedownIcon
                    onClick={() => {
                      onChange(
                        value.clone().minutes(
                          value
                            .clone()
                            .subtract(10, "minutes")
                            .minutes()
                        )
                      );
                    }}
                  />
                </CCIconButton>
                <CCIconButton className={classes.upDownButton}>
                  <MovedownIcon
                    onClick={() => {
                      onChange(
                        value.clone().minutes(
                          value
                            .clone()
                            .subtract(1, "minutes")
                            .minutes()
                        )
                      );
                    }}
                  />
                </CCIconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!Boolean(stepperMinutes) && (
          <TextField
            InputProps={{
              className: clsx(classes.Input),
              inputProps: {
                className: clsx(classes.input)
              },
              classes: timeInputClasses
            }}
            SelectProps={{
              classes: selectClasses
            }}
            defaultValue={0}
            value={value.minutes()}
            variant={"outlined"}
            select={true}
            onChange={event => {
              if (event.target.value !== value.minutes())
                onChange(value.clone().minutes(event.target.value));
            }}
          >
            {[...InputMenuTimeItems(60, step)]}
          </TextField>
        )}
      </Grid>
    </Grid>
  );
};
TimeInput.propTypes = {
  value: PropTypes.instanceOf(moment).isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  stepperHours: PropTypes.bool,
  stepperMinutes: PropTypes.bool,
  disableMeridiem: PropTypes.bool
};
TimeInput.defaultProps = {
  step: 10,
  stepperHours: false,
  stepperMinutes: false,
  disableMeridiem: true
};
export default TimeInput;
