import React, { forwardRef, useState } from "react";
import { CCTextField } from "styles/components";
import { InvisibleIcon, VisibleIcon } from "styles/icons";

const CVPasswordField = forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <CCTextField
      {...props}
      ref={ref}
      type={showPassword ? "text" : "password"}
      endIcon={showPassword ? <InvisibleIcon /> : <VisibleIcon />}
      onClickIcon={() => {
        setShowPassword(!showPassword);
      }}
    />
  );
});

export default CVPasswordField;
