import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { CCButton, CCDialogSimple, CCTypography } from "styles/components";
import { WITHDRAW_TYPES } from "types/customer";
import { makeIndexFormattedMessageList } from "types";

const useStyles = makeStyles(theme => ({
  title: { width: 316 },
  icon: {
    color: theme.palette.error.main
  },
  text1: {
    color: theme.palette.error.main
  },
  text2: {
    color: theme.palette.sub.main
  },
  radio__group: {
    flexDirection: "row"
  },
  mr8: {
    marginRight: 8
  }
}));

const CCDialogSimpleDel = props => {
  const { onAgree, onDisagree, onClose, ...others } = props;
  const classes = useStyles();
  const [selected, setSelected] = useState(WITHDRAW_TYPES.NOW.value);
  const withdrawIndex = makeIndexFormattedMessageList(WITHDRAW_TYPES);

  return (
    <CCDialogSimple
      classes={classes}
      title={<WarningIcon className={clsx(classes.icon, classes.mr8)} />}
      contents={
        <>
          <CCTypography className={classes.text1}>
            Are you sure to withdrawn this lab?
          </CCTypography>
          <CCTypography className={classes.text2}>
            * Withdrawn data could not be recovered
          </CCTypography>
          <CCTypography className={classes.text2}>
            If you select "Withdrawn after one month" the account becomes
            automatically withdrawn after one month.
          </CCTypography>
          <RadioGroup
            value={selected}
            className={classes.radio__group}
            onChange={e => setSelected(e.target.value)}
          >
            <FormControlLabel
              value={WITHDRAW_TYPES.AFTER_ONE_MONTH.value}
              control={<Radio />}
              label={withdrawIndex[WITHDRAW_TYPES.AFTER_ONE_MONTH.value]}
            />
            <FormControlLabel
              value={WITHDRAW_TYPES.NOW.value}
              control={<Radio />}
              label={withdrawIndex[WITHDRAW_TYPES.NOW.value]}
            />
          </RadioGroup>
        </>
      }
      endActions={
        <>
          <CCButton
            color="sub"
            className={classes.mr8}
            onClick={() => {
              onDisagree && onDisagree();
            }}
          >
            No
          </CCButton>
          <CCButton
            variant="contained"
            color="error"
            onClick={() => {
              onAgree && onAgree(selected);
            }}
          >
            Yes
          </CCButton>
        </>
      }
      // onCloseButton={onClose}
      onClose={onClose}
      {...others}
    />
  );
};

CCDialogSimpleDel.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAgree: PropTypes.func,
  onDisagree: PropTypes.func
};

export default CCDialogSimpleDel;
