import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Calendar, Header } from "./component";

const useStyle = makeStyles(
  theme => ({
    root: {
      width: 310,
      boxSizing: "border-box",
      paddingTop: 8,
      backgroundColor: theme.palette.common.white,
      height: 316
    }
  }),
  { name: "CCFlattenDateTimePicker-DatePicker" }
);
const DatePicker = props => {
  const {
    value,
    onChange,
    components,
    checkedList,
    onChangeHeader = () => {},
    mode,
    preserveCurrentTime
  } = props;
  const classes = useStyle();
  return (
    <Grid className={classes.root} container direction={"column"}>
      <Grid item container justify={"center"} alignItems={"center"}>
        <Header
          value={value}
          onChange={e => {
            onChange(e);
            onChangeHeader(e);
          }}
          mode={mode}
        />
      </Grid>
      <Grid item container justify={"center"} alignItems={"center"}>
        <Calendar
          value={value}
          onChange={onChange}
          components={components}
          checkedList={checkedList}
          mode={mode}
          preserveCurrentTime={preserveCurrentTime}
        />
      </Grid>
    </Grid>
  );
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.shape({
      begin: PropTypes.instanceOf(moment),
      end: PropTypes.instanceOf(moment)
    })
  ]).isRequired,
  components: PropTypes.shape({
    DayItem: PropTypes.elementType
  }),
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["day", "week"]),
  preserveCurrentTime: PropTypes.bool
};
DatePicker.defaultProps = {
  mode: "day"
};
export default DatePicker;
