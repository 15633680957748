import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { PermissionBackdrop, useReadPermission } from "components";
import { AppContext } from "context";
import React, { useContext, useState, useEffect } from "react";
import { CCPaper } from "styles/components";
import {
  // AdvancedSetting,
  // ClinicInfoSetting,
  // CrownSetting,
  // EmployeeInfoSetting,
  // LabInformation,
  // LanguageSetting,
  // OrderDetailsSetting,
  // PartnerSetting,
  // PermissionSetting,
  SettingNav,
  SMTPSetting,
  AdminInformation
} from "./components";

const width = 295;
const padding = 12;
const useStyles = makeStyles(() => ({
  root: { height: "100%" },
  container: { flexWrap: "nowrap", height: "100%" },
  item: {
    padding: padding,
    boxSizing: "border-box",
    height: "100%"
  },
  item__left: {
    width: width,
    minWidth: width
  },
  border: { margin: "12px 0", borderRight: "1px solid #b0bec5" },
  item__right: {
    width: `calc(100% - ${width + 1}px)`,
    minWidth: 1300
  },
  paper: { height: "100%" },
  paper__right: { height: "100%", position: "relative" }
}));

const settingList = [
  {
    title: "settings.ui.general",
    buttons: [
      {
        name: "settings.ui.administer",
        key: "administer",
        component: <AdminInformation />,
        permission: "labGeneralSetting"
      },
      {
        name: "settings.ui.smtp",
        key: "smtp",
        component: <SMTPSetting />,
        
        permission: "smtpGeneralSetting"
      },

      // {
      //   name: "common.ui.employeeInfo",
      //   key: "employeeInfo",
      //   component: <EmployeeInfoSetting />,
      //   permission: "employeeInfoGeneralSetting"
      // }
    ]
  },
  // {
  //   title: "common.ui.partners",
  //   buttons: [
  //     {
  //       name: "common.ui.clinics",
  //       key: "clinics",
  //       component: <ClinicInfoSetting />,
  //       permission: "clinicsPartnersSetting"
  //     },
  //     {
  //       name: "common.ui.otherPartners",
  //       key: "otherPartners",
  //       component: <PartnerSetting />,
  //       permission: "otherPartnersPartnersSetting"
  //     },
  //     {
  //       name: "common.ui.productPrice",
  //       key: "otherPartners",
  //       component: <CrownSetting />,
  //       permission: "productPricesPartnersSetting"
  //     }
  //   ]
  // },
  // {
  //   title: "common.ui.others",
  //   buttons: [
  //     {
  //       name: "common.ui.authorization",
  //       key: "permissionSetting",
  //       component: <PermissionSetting />,
  //       permission: "authorizationOtherSetting"
  //     },
  //     {
  //       name: "common.ui.orderDetails",
  //       key: "orderDetailsSetting",
  //       component: <OrderDetailsSetting />,
  //       permission: "orderDetailsOtherSetting"
  //     },
  //     {
  //       name: "common.ui.language",
  //       key: "languageSetting",
  //       component: <LanguageSetting />,
  //       permission: "languagesOtherSetting"
  //     },
  //     {
  //       name: "common.ui.advanced",
  //       key: "advancedSetting",
  //       component: <AdvancedSetting />,
  //       permission: "advancedOtherSetting"
  //     }
  //   ]
  // }
];

const SettingComponent = props => {
  const { permission, component } = props;

  const readPermission = useReadPermission(permission);
  const { appContext } = useContext(AppContext);

  return (
    <>
      {/* {appContext.ready &&
        (!readPermission ? (
          <PermissionBackdrop permission={permission} />
        ) : ( */}
        
          <Box height={1}>{component}</Box>
        {/* ))} */}
    </>
  );
};

const Settings = props => {
  const { init } = props;

  console.log("Settings", {props})

  const classes = useStyles();
  const [selected, setSelected] = useState(init || "settings.ui.administer");


  useEffect(() => {
    const { category, openSmtpSettingDialog } = window?.variables || {}; // fix destructuring of undefined

    if(category === "settings.ui.smtp" && openSmtpSettingDialog) {
      setSelected("settings.ui.smtp");
      window.variables = {};
    }
    console.log("Settings.init", window.variables);    
  }, []);


  return (
    <Box className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item className={clsx(classes.item, classes.item__left)}>
          <CCPaper className={classes.paper}>
            <SettingNav
              settingList={settingList}
              selected={selected}
              onClick={value => {
                setSelected(value);
              }}
            />
          </CCPaper>
        </Grid>
        <Grid item className={classes.border} />
        <Grid item className={clsx(classes.item, classes.item__right)}>
          <Box className={clsx(classes.paper__right)}>
            {settingList.map((setting, key) => {
              const index = setting.buttons.findIndex(button => {
                console.log("button", button, "selected", selected)
                if (button.name === selected) return true;
                return false;
              });

              return (
                index !== -1 && (
                  <SettingComponent
                    key={key}
                    permission={setting.buttons[index]?.permission}
                    component={setting.buttons[index].component}
                  />
                )
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
