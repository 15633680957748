import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { CCTextField } from "styles/components";
import { CVPasswordField } from "components";


const useStyles = makeStyles(() => ({
  root: {
    width: 300
  },
  date__field: {
    width: 220
  },
  link__field: {
    "& textarea": {
      lineHeight: 1.5,
      fontSize: "0.875rem"
    }
  }
}));

const AdminInformationField = props => {
  const classes = useStyles();
  const { field, onHandle, isNew } = props;
  const currentPassword = useRef()
  console.log("AdminInformationField.field", field)
  const handleField = name => e => {
    onHandle({
      ...field,
      [name]: e.target.value
    });
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <CCTextField
        variant="outlined"
        margin="dense"
        label={"ID"}
        placeholder="ID"
        value={field.managerId}
        onChange={handleField("managerId")}
        disabled={!isNew}
        required
      />
      <CVPasswordField
        variant="outlined"
        margin="dense"
        label={"Password"}
        placeholder="Password"
        value={field.password}
        type={"password"}
        onChange={handleField("password")}
        required
      />
      <CCTextField
        variant="outlined"
        margin="dense"
        label={"Name"}
        placeholder="Name"
        value={field.name}
        onChange={handleField("name")}
        required
      />
    </Grid>
  );
};

export default AdminInformationField;
