import React, { useEffect, useState } from "react";
import { CCButton, CCDialogSimple } from "styles/components";
import { CheckIcon } from "styles/icons";
import AdminInformationField from "./AdminInformationField";

const initField = {
  managerId: "",
  password: "",
  name: ""
};

const AddAdminInformationModal = props => {
  const { open, selectedItem, onClose, onSave } = props;
  const [field, setField] = useState(initField);

  useEffect(() => {
    if (selectedItem) {
      setField(prevState => ({
        ...prevState,
        ...selectedItem
      }));
    } else {
      setField({ ...initField });
    }
  }, [selectedItem]);

  const handleCancelDialog = () => {
    setField({});
    onClose();
  };

  return (
    <CCDialogSimple
      open={open}
      title={"Adminitrator"}
      contents={<AdminInformationField field={field} onHandle={setField} isNew={!selectedItem}/>}
      contentsPadding={false}
      maxWidth={"md"}
      endActions={
        <>
          <CCButton onClick={handleCancelDialog}>Cancel</CCButton>
          <CCButton
            onClick={() => onSave(field)}
            startIcon={<CheckIcon />}
            variant="contained"
          >
            Save
          </CCButton>
        </>
      }
      onClose={handleCancelDialog}
      onCloseButton={handleCancelDialog}
    />
  );
};

export default AddAdminInformationModal;
