import React, { useState } from 'react';
import { Badge, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CCIconButton, CCTypography } from 'styles/components';
import { MenunextIcon, RemoveIcon } from 'styles/icons';

const useStyles = makeStyles(() => ({
  root: {},
  contents: { backgroundColor: 'black' },
  head: {
    padding: '12px 12px 12px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  body: { height: 400, overflow: 'auto', padding: '8px 12px' },
  row: {
    padding: '4px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  head__text: {
    color: 'white',
  },
  body__text: {
    color: 'white',
    textDecoration: 'underline',
  },
  btn: {
    color: 'white',
    margin: '0 0 0 8px',
  },
  icon__button: { margin: 0 },
}));

const useBadgeStyles = makeStyles((theme) => ({
  root: {},
  colorPrimary: { backgroundColor: theme.palette.error.main },
}));

const usePopoverStyles = makeStyles(() => ({
  root: {
    opacity: 0.8,
  },
  paper: {
    width: 500,
    borderRadius: 8,
  },
}));

const Notification = (props) => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);
  const Popoverclasses = usePopoverStyles();
  const badgeClasses = useBadgeStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  // const { data, refetch } = useQuery(SEARCH_NOTIFICATION, {
  //   variables: {
  //     hospitalId: localStorage.getItem('labId'),
  //   },
  //   onCompleted: (data) => {},
  // });

  // const [removeNotification] = useMutation(REMOVE_NOTIFICATION, {
  //   onCompleted: () => {
  //     refetch();
  //   },
  // });

  // useEffect(() => {
  //   if (data && data.searchNotification) {
  //     setNotifications(data.searchNotification || []);
  //   } else {
  //     setNotifications([]);
  //   }
  // }, [data]);

  return (
    <>
      <CCIconButton
        className={classes.icon__button}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Badge
          classes={badgeClasses}
          badgeContent={notifications.length}
          color='primary'
        >
          {props.children}
        </Badge>
      </CCIconButton>
      <Popover
        classes={Popoverclasses}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.contents}>
          <div className={classes.head}>
            <CCTypography className={classes.head__text} variant='h5'>
              {`알림(${notifications.length})`}
            </CCTypography>
            <CCIconButton
              size='small'
              className={classes.btn}
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              <RemoveIcon />
            </CCIconButton>
          </div>
          <div className={classes.body}>
            {notifications.map((el, index) => (
              <div key={index} className={classes.row}>
                <CCTypography
                  className={classes.body__text}
                  variant='body2'
                  onClick={() => {
                    const notification = notifications[index];
                    // removeNotification({
                    //   variables: {
                    //     hospitalId: notification.hospitalId,
                    //     id: notification.id,
                    //   },
                    // });
                  }}
                >
                  {el.title}
                </CCTypography>
                <div>
                  {/* <CCIconButton size="small" className={classes.btn}>
                    <SubtractIcon />
                  </CCIconButton> */}
                  <CCIconButton size='small' className={classes.btn}>
                    <MenunextIcon />
                  </CCIconButton>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default Notification;
