import { Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {},
  ma: { marginTop: 8 }
}));
const usePopoverStyles = makeStyles(theme => ({
  paper: { borderRadius: 20, padding: 12, width: 320 }
}));

const DialogMiddleSizeInfo = props => {
  const { open, anchorEl, onClose } = props;
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();

  return (
    <Popover
      classes={popoverClasses}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    ></Popover>
  );
};

export default DialogMiddleSizeInfo;
