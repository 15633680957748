import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { CCButton, CCTextField } from "styles/components";
import { SearchIcon } from "styles/icons";
import { DateQueryFilter } from "components";

const useStyles = makeStyles(theme => ({
  input: { backgroundColor: theme.palette.common.white },
  searchtext__field: { width: 243, marginLeft: 32 },
  ml8: {
  marginLeft: 8
  }
}));

const ApplicationFilter = props => {
  const { filter, onSearch } = props;
  const classes = useStyles();
  const [value, setValue] = useState(filter);

  useEffect(() => {
    setValue(filter);
  }, [filter]);

  return (
    <>
      <DateQueryFilter value={value} onChange={val => setValue(val)} />

      <CCTextField
        className={classes.searchtext__field}
        variant="outlined"
        margin="dense"
        label="Lab, Manager, Cell No., Tel No."
        InputProps={{ classes: { root: classes.input } }}
        value={value.searchText}
        onChange={e => {
          setValue({ ...value, searchText: e.target.value });
        }}
      />

      <CCButton
        variant="contained"
        color="secondary"
        startIcon={<SearchIcon />}
        className={classes.ml8}
        onClick={() => {
          onSearch && onSearch({ ...value });
        }}
      >
        Search
      </CCButton>
    </>
  );
};

export default ApplicationFilter;
