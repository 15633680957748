import React, { forwardRef, useState } from 'react';
import { CCButton } from 'styles/components';
import { DialogPermissionWarn } from 'components';

const CVButton = forwardRef((props, ref) => {
  const { children, permission, onClick, ...others } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <CCButton onClick={true ? onClick : handleClick} ref={ref} {...others}>
        {children}
      </CCButton>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
});

export default CVButton;
