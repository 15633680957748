import React from "react";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import packageJson from "../../../../../package.json";

const useStyles = makeStyles(theme => ({
  root: {
    bottom: 0,
    position: "fixed",
    zIndex: 100,
    background: "gray",
    opacity: 0.5,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: 12
  },
  footerShift: {
    marginLeft: theme.sidebar.width,
    width: `calc(100% - ${theme.sidebar.width}px)`
  }
}));

const Footer = props => {
  const { open, isDesktop } = props;
  const classes = useStyles();
  const currentVersion = packageJson.version;

  return (
    <Box
      className={clsx({
        [classes.root]: true,
        [classes.footerShift]: open && isDesktop
      })}
    >
      {`Version: ${currentVersion} (${process.env.REACT_APP_DEPLOY_DATE} ${process.env.REACT_APP_COMMIT})`}
    </Box>
  );
};

export default Footer;
