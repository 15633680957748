import React from "react";

const AnnounceOnIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M0 0H24V24H0z"
        transform="translate(-356.000000, -210.000000) translate(356.000000, 210.000000)"
      />
    </g>
    <path
      fill="currentColor"
      d="M17.897 10.697v2h4v-2h-4zm-2 6.61c.96.71 2.21 1.65 3.2 2.39.4-.53.8-1.07 1.2-1.6-.99-.74-2.24-1.68-3.2-2.4-.4.54-.8 1.08-1.2 1.61zm4.4-12.01c-.4-.53-.8-1.07-1.2-1.6-.99.74-2.24 1.68-3.2 2.4.4.53.8 1.07 1.2 1.6.96-.72 2.21-1.65 3.2-2.4zm-16.4 3.4c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h1v4h2v-4h1l5 3v-12l-5 3h-4zm11.5 3c0-1.33-.58-2.53-1.5-3.35v6.69c.92-.81 1.5-2.01 1.5-3.34z"
      transform="translate(-356.000000, -210.000000) translate(356.000000, 210.000000) translate(11.896939, 11.696938) rotate(-15.000000) translate(-11.896939, -11.696938)"
    />
  </svg>
);

export default AnnounceOnIcon;
