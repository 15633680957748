import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Chips } from 'components';
import React, { useState } from 'react';
import { CCIconButton } from 'styles/components';
import { MenucloseIcon, MenunextIcon } from 'styles/icons';
import { DialogSmallSizeInfo } from '..';

const useStyles = makeStyles((theme) => ({
  root: {},
  chips: { backgroundColor: theme.palette.sub.main },
  icon__button: {
    borderRadius: 20,
    margin: 2,
    background: 'white',
    '&:hover': {
      background: 'white',
    },
  },
  icon__open: { color: theme.palette.sub.main },
  icon__close: { color: theme.palette.select.main },
  text: {
    paddingLeft: 4,
    color: 'white',
  },
}));

const SmallSizeInfo = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className={classes.root}>
      <Chips
        background={open ? theme.palette.select.main : theme.palette.sub.main}
        className={classes.chip}
        endIcon={
          open ? (
            <CCIconButton
              className={clsx(classes.icon__button, classes.icon__close)}
              size='small'
              onClick={() => {
                setOpen(false);
                setAnchorEl(null);
              }}
            >
              <MenucloseIcon />
            </CCIconButton>
          ) : (
            <CCIconButton
              className={clsx(classes.icon__button, classes.icon__open)}
              size='small'
              onClick={(e) => {
                setOpen(true);
                setAnchorEl(e.currentTarget);
              }}
            >
              <MenunextIcon className={classes.icon__open} />
            </CCIconButton>
          )
        }
      />
      <DialogSmallSizeInfo
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        anchorEl={anchorEl}
      />
    </div>
  );
};

export default SmallSizeInfo;
