import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { getObject, Target } from "utils/s3";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles(() => ({
  removeicon: {
    cursor: "pointer",
    background: "#b0bec5",
    width: 20,
    height: 20,
    position: "absolute",
    top: 5,
    right: 5,
    borderRadius: "50%",
    zIndex: 1
  }
}));

const LAB_ID = "ewoosoftvn";

const AttachmentCard = props => {
  const { type, target, id, handleRemoveImage, labId } = props;
  const [image, setImage] = useState("");
  const classes = useStyles();

  useEffect(() => {
    let func = () => {
      switch (type) {
        case "paper":
        case "image":
          getObject(target, labId, id).then(res => {
            setImage(res);
          });
          break;
        case "stamp":
          getObject(Target.STAMP, labId, id).then(res => {
            setImage(res);
          });
          break;
        case "logo":
          getObject(Target.LOGO, labId, id).then(res => {
            setImage(res);
          });
          break;
        case "video":
          setImage("/contents/video.svg");
          break;
        case "consult":
          setImage("/contents/slide.svg");
          break;
        case "folder":
          setImage("/contents/folder.svg");
          break;
        default:
          getObject(target, labId, id).then(res => {
            setImage(res);
          });
      }
    };
    func();
  }, [id, target, type]);

  return (
    <Grid
      item
      style={{
        borderRadius: 10,
        position: "relative",
        height: "100px",
        width: "134px",
        background: "white",
        border: "1px solid #b0bec5",
        margin: 8,
        backgroundImage: `url(${image})`,
        backgroundPosition: "center center",
        backgroundOrigin: "border-box",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
      }}
    >
      {handleRemoveImage ? (
        <RemoveIcon
          className={classes.removeicon}
          onClick={() => handleRemoveImage(id)}
        />
      ) : (
        ""
      )}
    </Grid>
  );
};

export default AttachmentCard;
