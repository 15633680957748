import React from "react";
import { Assignment } from "@material-ui/icons";
import { Minimal as MiniLayout } from "layouts";
import SignIn from "./SignIn";

const pages = [
  {
    path: "/signIn",
    title: "signIn",
    icon: <Assignment />,
    component: SignIn,
    layout: MiniLayout
  }
].map(el => {
  el.mode = "common";
  return el;
});
export { pages };
