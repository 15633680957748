import React from "react";
import { useQuery } from "@apollo/client";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid } from "@material-ui/core";
import { CCDialogSimple, CCTypography } from "styles/components";
import { useIntl } from "react-intl";
import { GET_LAB_LIST, GET_RECOMMENDED_LAB_LIST } from "queries/customer";
import { LabRow, SearchInput } from "./components";

const useStyles = makeStyles(theme => ({
  root: { height: 800, display: "flex", flexDirection: "column" },
  search_box: {
    paddingLeft: 8,
    width: 300
  },
  search_input: {},
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#cfd8dc"
  },
  item__recommended: {
    marginTop: 12,
    height: 250
  },
  item__labs: {
    height: 486
  },
  table__header: {
    borderTop: `1px solid ${theme.palette.border.main.main}`,
    borderBottom: `1px solid ${theme.palette.border.main.main}`,
    padding: 8,
    backgroundColor: theme.palette.common.white
  },
  table__content: {
    // overflowY: "auto",
    position: "relative",
    height: "100%"
  },
  table__contents__blank__item: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  }
}));

const LIMIT = 100000;

const LabSearchModal = props => {
  const { open, onClose } = props;
  const classes = useStyles();
  const intl = useIntl();

  const {
    data: dataRecommendedLab,
    loading: loadingRecommendedLab,
    refetch: refetchRecommendedLab
  } = useQuery(GET_RECOMMENDED_LAB_LIST);

  const {
    data: datLabList,
    loading,
    error,
    refetch: refetchLabList
  } = useQuery(GET_LAB_LIST, {
    variables: {
      input: {
        limit: LIMIT
      }
    }
  });

  const handleOnSearchComplete = searchText => {
    // refetchRecommendedLab({});
    refetchLabList({
      input: {
        limit: LIMIT,
        searchText
      }
    });
    refetchRecommendedLab(
      { searchText }
    )
  };

  const FAKE_ARRAY_NUMBER = [1, 2, 3]; // just hard code for display

  if (error) return <div>Error</div>;

  return (
    <CCDialogSimple
      maxWidth="md"
      fullWidth
      open={open}
      contentsPadding={false}
      title={"Clever Lab Search"}
      onCloseButton={() => {
        onClose();
      }}
      onClose={() => {
        onClose();
      }}
      contents={
        <Grid className={classes.root} container>
          <Grid item className={classes.search_box}>
            <SearchInput onComplete={handleOnSearchComplete} />
          </Grid>

          {!loadingRecommendedLab &&
            dataRecommendedLab.recommendedLabList.length ? (
            <Grid
              item
              className={clsx(classes.table, classes.item__recommended)}
            >
              <Box className={classes.table__header}>
                <CCTypography variant="h4">Recommended</CCTypography>
              </Box>

              <Box className={classes.table__content}>
                {dataRecommendedLab.recommendedLabList.length
                  ? dataRecommendedLab.recommendedLabList.map(lab => (
                    <LabRow
                      key={lab.id}
                      labId={lab.labId}
                      avatar={lab.avatar}
                      labName={lab.labName}
                      specialties={lab.specialties}
                      statusMessage={lab.statusMessage}
                    />
                  ))
                  : " "}
              </Box>
            </Grid>
          ) : (
            /***
             * When search recommendedLabList is empty add for display on Ui
             */
            <Grid
              item
              className={clsx(classes.table, classes.item__recommended)}
            >
              <Box className={classes.table__header}>
                <CCTypography variant="h4">Recommended</CCTypography>
              </Box>

              <Box className={classes.table__content}>
                <Grid item className={classes.table__contents__blank__item}>
                  <CCTypography variant="body2">
                    {intl.formatMessage({ id: "ccHasNoData" })}
                  </CCTypography>
                </Grid>
                {/* {FAKE_ARRAY_NUMBER.map(numberIndex => (
                      <LabRow
                        key={numberIndex}
                        labId={null}
                      />
                    ))
                } */}
              </Box>
            </Grid>
          )}


          <Grid item className={clsx(classes.table, classes.item__labs)}>
            <Box className={classes.table__header}>
              <CCTypography variant="h4">Clever Labs</CCTypography>
            </Box>
            {!loading && (
              <Box className={classes.table__content}>
                {datLabList.labList.items
                  // .filter(lab => lab.isRecommendedLab === false)
                  .map(lab => (
                    <LabRow
                      key={lab.id}
                      labId={lab.labId}
                      avatar={lab.avatar}
                      labName={lab.labName}
                      specialties={lab.specialties}
                      statusMessage={lab.statusMessage}
                    />
                  ))}
              </Box>
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

export default LabSearchModal;
