import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { CCIconButton } from 'styles/components';
import { DenturesIcon, ImplantIcon } from 'styles/icons';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon__button: { margin: 0 },
  icon__button__selected: {
    '&:disabled': { color: theme.palette.select.main },
  },
}));

const Info = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CCIconButton
        disabled
        className={clsx({
          [classes.icon__button]: true,
        })}
      >
        <DenturesIcon />
      </CCIconButton>
      <CCIconButton
        disabled
        className={clsx({
          [classes.icon__button]: true,
        })}
      >
        <ImplantIcon />
      </CCIconButton>
    </Box>
  );
};

export default Info;
