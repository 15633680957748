import { DialogPermissionWarn } from 'components';
import React, { useState } from 'react';
import CCFlattenDateField from '../CCFlattenDateTimePicker/CCFlattenDateField';

const CVFlattenDateField = (props) => {
  const {
    children,
    permission,
    onChange,
    onKeyDown,
    onKeyPress,
    ...others
  } = props;
  const [open, setOpen] = useState(false);

  const isPermission = true;

  const handleChange = () => {
    setOpen(true);
  };

  return (
    <>
      <CCFlattenDateField
        {...others}
        onChange={isPermission ? onChange : handleChange}
        onKeyDown={isPermission ? onKeyDown : handleChange}
        onKeyPress={isPermission ? onKeyPress : handleChange}
        onClickIconPermission={isPermission ? null : handleChange}
      >
        {children}
      </CCFlattenDateField>
      <DialogPermissionWarn
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default CVFlattenDateField;
