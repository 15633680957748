import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { CVButton, DialogWarn } from "components";
import { CCDialogSimple, CCTypography } from "styles/components";
import { AppContext, RouteContext } from "context";

const useStyles = makeStyles(theme => ({
  root: {},
  warning___color: { color: theme.palette.error.main },

  text: { margin: "0 16px", width: 280 },
  disagree: { marginRight: 8 }
}));

const maxCnt = 60;

const DialogTimeout = () => {
  const classes = useStyles();
  const { dispatchRoute } = useContext(RouteContext);
  const { dispatchApp } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [warn, setWarn] = useState(false);
  const [cnt, setCnt] = useState(maxCnt);
  const limitTime = useRef(null);
  const id = useRef(null);

  const setTimer = useCallback(() => {
    if (limitTime.current === null) return;
    if (id.current) clearTimeout(id.current);

    id.current = setTimeout(() => {
      id.current = null;
      setOpen(true);
      setCnt(maxCnt);
    }, [limitTime.current]);
  }, []);

  useEffect(() => {
    const onClick = () => {
      setTimer();
    };

    window.addEventListener("click", onClick, true);
    window.addEventListener("keydown", onClick, true);

    return () => {
      window.removeEventListener("click", onClick, true);
      window.removeEventListener("keydown", onClick, true);

      clearTimeout(id.current);
      clearTimeout(cntTimerId.current);
    };
  }, [setTimer]);

  const cntTimerId = useRef(null);

  useEffect(() => {
    const countDown = () => {
      if (cntTimerId.current) {
        clearTimeout(cntTimerId.current);
      }
      cntTimerId.current = setTimeout(() => {
        cntTimerId.current = null;
        if (cnt) {
          setCnt(cnt - 1);
        } else {
          dispatchRoute({ path: "/timeout" });
          dispatchApp({ type: "permission", target: null });
        }
      }, [1000]);
    };

    if (open) countDown();
  }, [open, cnt, dispatchRoute, dispatchApp]);

  return (
    <>
      <CCDialogSimple
        open={open}
        contents={
          <Box>
            <Box className={classes.text}>
              <CCTypography className={classes.warning___color}>
                {`${cnt}`}
              </CCTypography>
            </Box>
          </Box>
        }
        endActions={
          <>
            <CVButton
              className={classes.disagree}
              color="sub"
              onClick={() => {
                setOpen(false);
                setTimer();
              }}
            >
              연결유지
            </CVButton>
            <CVButton
              variant="contained"
              color="error"
              onClick={() => {
                dispatchRoute({ path: "/signin" });
                dispatchApp({ type: "permission", target: null });
              }}
            >
              로그아웃
            </CVButton>
          </>
        }
      />
      <DialogWarn
        open={warn}
        text={"장시간 사용하지 않아 로그아웃되었습니다."}
        onClose={() => {
          dispatchRoute({ path: "/signin" });
          dispatchApp({ type: "permission", target: null });
          setWarn(false);
        }}
      />
    </>
  );
};

export default DialogTimeout;
